import _isEmpty from 'lodash/isEmpty';
import { getBrach } from '../../../services/auth';
import formatInvoice from '../sectionsMethodsPayment/InvoicePayment/formatter';
import formatMultiPayments from '../sectionsMethodsPayment/MultiPayments/formatter';

const getPayments = (values) => {
  return !values.diffMethods
    ? [
        {
          type: 'amount',
          amount: values.amount,
        },
      ]
    : [
        {
          type: 'amountCard_0',
          amount: values.amountCard_0 ?? 0,
        },
        {
          type: 'amountCard_1',
          amount: values.amountCard_1 ?? 0,
        },
        {
          type: 'amountCard_2',
          amount: values.amountCard_2 ?? 0,
        },
        {
          type: 'amountTransfer',
          amount: values.amountTransfer ?? 0,
        },
        {
          type: 'amountDeposit',
          amount: values.amountDeposit ?? 0,
        },
        {
          type: 'amountCash',
          amount: values.amountCash ?? 0,
        },
      ];
};

const getAdjustment = (contractsPaid, payments) => {
  return contractsPaid.map((c) => {
    const contractAdjusted = {
      contractNumber: c.contractNumber,
    };
    const paymentByContract = [];
    for (let index in payments) {
      const checks = { type: payments[index].type, amountPaid: '' };
      if (payments[index].amount == 0) continue;

      const remain = payments[index].amount - c.payment;
      const fullPaid = remain > 0;

      checks.amountPaid = fullPaid ? c.payment : payments[index].amount;
      c.payment = fullPaid ? 0 : c.payment - payments[index].amount;
      payments[index].amount = fullPaid ? remain : 0;

      paymentByContract.push(checks);
      if (fullPaid) break;
    }
    return {
      ...contractAdjusted,
      paymentByContract,
    };
  });
};

const getPaymentRequest = (values, adjustment) => {
  return values.contractsDetails
    .filter((cd) => cd.contractNumber == adjustment.contractNumber)
    .map((cd) => {
      const request = formatMultiPayments(values).map((r) => ({
        ...r,
        totalAmount: r.amount,
        paymentType: 'PAYMENT',
        contractNumber: cd.contractNumber,
        isTheLastPayment: false,
      }));

      return {
        contract: cd.contractUuid,
        customerUuid: values.customerUuid,
        branch: getBrach(),
        movementType: values.movementType,
        amount: Number(cd.payment),
        concept: 'PAGO A MULTIPLES CONTRATOS - CONTRATO:' + cd.contractNumber,
        remarks: values.observations,
        reason: 'MULTI CONTRACT PAYMENT AUTOMATIC MOVEMENT',
        applicantUser: '',
        error: '',
        methodPayment: request,
        validCodeRequest: {
          username: '',
          code: '',
        },
      };
    });
};
const formatter = (values) => {
  const multiContractPayments = formatMultiPayments(values).map((r) => ({
    ...r,
    totalAmount: values.amount,
    paymentType: 'PAYMENT',
  }));

  const contractsPaid = values.contractsDetails
    .filter((cd) => cd.payment != 0)
    .map((cd) => ({ contractNumber: cd.contractNumber, payment: cd.payment }));

  const payments = getPayments(values);

  contractsPaid.sort((a, b) => b.payment - a.payment);
  payments.sort((a, b) => b.amount - a.amount);

  const adjustment = getAdjustment(contractsPaid, payments);
  const adjustmentIndex = adjustment.length - 1;

  let multiContractRequest = [];
  let index = 0;

  for (let a of adjustment) {
    for (let p of a.paymentByContract) {
      values[p.type] = p.amountPaid;
    }

    const newRequest = getPaymentRequest(values, a);

    if (adjustmentIndex == index) {
      const requestIndex = newRequest.length - 1;
      const methodPaymentIndex =
        newRequest[requestIndex].methodPayment.length - 1;
      newRequest[requestIndex].methodPayment[
        methodPaymentIndex
      ].isTheLastPayment = true;
    }
    multiContractRequest.push(...newRequest);

    for (let p of a.paymentByContract) {
      values[p.type] = 0;
    }
    index++;
  }

  return {
    customerUuid: values.customerUuid,
    ...(values.wantInvoice ? formatInvoice(values) : {}),
    multiContractRequest,
    multiContractPayments,
  };
};

export default formatter;
