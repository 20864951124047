import { transformIdOfList } from 'utils/transformHelpers';
import types from './action-types';
import {
  ISO_DATE_FORMAT,
  MX_DATE_FORMAT,
} from '@casanova/casanova-common/lib/utils/constants/index';
import moment from 'moment';

const initialState = {
  responseDialog: {
    open: false,
    success: false,
    errorCode: '',
    errorMessage: '',
  },
  list: {
    params: {
      page: 0,
      size: 10,
    },
    searchPattern: '',
    filters: [],
    totalPages: 1,
    totalElements: 10,
    results: [],
  },
  invoiceDetails: {
    success: false,
    error: false,
    errorMessage: '',
    hasNotFound: false,
    data: {},
  },
  listOfCRP: {
    params: {
      page: 0,
      size: 10,
    },
    totalPages: 1,
    totalElements: 10,
    results: [],
  },
  saveCRP: {
    success: false,
    error: false,
    errorMessage: '',
    data: {},
  },
};

export function invoicesReducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.CLOSE_RESPONSE_DIALOG:
      return {
        ...state,
        responseDialog: initialState.responseDialog,
      };
    case types.SET_PARAMS:
      return {
        ...state,
        list: {
          ...state.list,
          params: {
            ...state.list.params,
            ...payload,
            page: 0,
          },
        },
      };
    case types.RESET_PARAMS:
      return {
        ...state,
        list: {
          ...state.list,
          params: initialState.list.params,
        },
      };
    case types.SET_LIST_PAGE:
      return {
        ...state,
        list: {
          ...state.list,
          params: {
            ...state.list.params,
            page: payload,
          },
        },
      };
    case types.GET_LIST_FAILED:
    case types.GET_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          results: [],
        },
      };
    case types.GET_LIST_SUCCEED:
      return {
        ...state,
        list: {
          ...state.list,
          results: transformIdOfList(payload.content, 'uuid').map((item) => ({
            ...item,
            dateTime: moment(item.dateTime, ISO_DATE_FORMAT).format(
              MX_DATE_FORMAT
            ),
          })),

          totalPages: payload.totalPages,
          totalElements: payload.totalElements,
        },
      };
    case types.GET_INVOICE_DETAILS:
    case types.CLEAN_CONTRACT_DETAILS:
      return {
        ...state,
        invoiceDetails: initialState.invoiceDetails,
        responseDialog: initialState.responseDialog,
      };
    case types.GET_INVOICE_DETAILS_SUCCEED:
      return {
        ...state,
        invoiceDetails: {
          success: true,
          error: false,
          errorMessage: '',
          hasNotFound: false,
          data: payload,
        },
      };
    case types.GET_INVOICE_DETAILS_FAILED:
      return {
        ...state,
        invoiceDetails: {
          success: false,
          error: true,
          errorMessage: payload.error,
          hasNotFound: payload.hasNotFound,
          data: {},
        },
      };
    case types.GET_LIST_OF_CRPS_SUCCEED:
    case types.SET_LIST_OF_CRPS:
      return {
        ...state,
        listOfCRP: {
          ...state.listOfCRP,
          results: transformIdOfList(payload.content),
          totalPages: payload.totalPages,
          totalElements: payload.totalElements,
        },
      };
    case types.GET_LIST_OF_CRPS_FAILED:
    case types.GET_LIST_OF_CRPS:
      return {
        ...state,
        list: {
          ...state.list,
          results: [],
        },
      };
    case types.SET_CRPS_PARAMS:
      return {
        ...state,
        listOfCRP: {
          ...state.listOfCRP,
          params: {
            ...state.listOfCRP.params,
            ...payload,
          },
        },
      };
    case types.SET_CRPS_LIST_PAGE:
      return {
        ...state,
        listOfCRP: {
          ...state.listOfCRP,
          params: {
            ...state.listOfCRP.params,
            page: payload,
          },
        },
      };
    case types.SAVE_CRP:
      return {
        ...state,
        saveCRP: initialState.saveCRP,
        responseDialog: initialState.responseDialog,
      };
    case types.SAVE_CRP_SUCCEED:
      return {
        ...state,
        saveCRP: {
          success: true,
          error: false,
          errorMessage: '',
          data: payload,
        },
        responseDialog: {
          open: true,
          success: true,
          errorCode: '',
          errorMessage: '',
        },
      };
    case types.SAVE_CRP_FAILED:
      return {
        ...state,
        saveCRP: {
          success: false,
          error: true,
          errorMessage: payload.error,
          data: {},
        },
        responseDialog: {
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.RE_INVOICE:
      return {
        ...state,
        reInvoice: {
          success: false,
          error: false,
          errorMessage: '',
          data: {},
        },
        responseDialog: {
          open: false,
          success: false,
          errorCode: '',
          message: '',
        },
      };
    case types.RE_INVOICE_SUCCEED:
      return {
        ...state,
        reInvoice: {
          success: true,
          error: false,
          errorMessage: '',
          data: payload,
        },
        responseDialog: {
          open: false,
          success: false,
          errorCode: '',
          message: '',
        },
      };
    case types.RE_INVOICE_FAILED:
      return {
        ...state,
        reInvoice: {
          success: false,
          error: true,
          errorMessage: '',
          data: {},
        },
        responseDialog: {
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.RESET:
      return initialState;
    default:
      return state;
  }
}
