import formatMultiPayments from '../sectionsMethodsPayment/MultiPayments/formatter';
import formatInvoice from '../sectionsMethodsPayment/InvoicePayment/formatter';

const formatter = (values) => {
  const request = formatMultiPayments(values).map((r) => ({
    ...r,
    paymentType: 'PAYMENT',
    contractNumber: values.contractNumber,
  }));

  return {
    contract: values.contractUuid,
    customerUuid: values.customerUuid,
    branch: values.branch,
    movementType: values.movementType,
    amount: Number(values.amount),
    concept: values.concept,
    remarks: values.observations,
    reason: values.reason,
    applicantUser: values.requester,
    error: values.error,
    methodPayment: request,
    ...(values.wantInvoice ? formatInvoice(values) : {}),
    validCodeRequest: {
      username: '',
      code: '',
    },
  };
};

export default formatter;
