import { savePayment } from 'store/payments/actions';
import i18n from '@i18n';
import schema from './schema';
import formatter from './formatter';
import transformer from './transformer';
import {
  multiPaymentsInitialTouched,
  multiPaymentsInitialValues,
} from '../sectionsMethodsPayment/MultiPayments/config';
import { getBrach } from '../../../services/auth';

export const initialTouched = {
  contractNumber: false,
  contractUuid: false,
  customerUuid: false,
  branch: false,
  requester: false,
  requester_search: false,
  concept: false,
  reason: false,
  observations: false,
  error: false,
  ...multiPaymentsInitialTouched,
};

export const initialValues = {
  contractNumber: '',
  contractUuid: '',
  customerUuid: '',
  branch: getBrach(),
  requester: '',
  requester_search: '',
  concept: '',
  reason: '',
  observations: '',
  error: '',
  ...multiPaymentsInitialValues,
};

export const config = (mode) => ({
  schema,
  initialValues,
  initialTouched,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  alwaysSetTemporalValues: true,
  redirectInSuccess: false,
  formatter,
  transformer,
  submitForm: (request) => savePayment(request),
  closeResponseDialog: () => console.log('Hola dialogs'),
  btnSubmitLabel: i18n('COMMONS__SAVE'),
  dialogs: {
    confirmation: {
      title: i18n(
        `PAYMENTS_AND_CHARGES__PAYMENT_DETAIL__SAVE_${mode}__CONFIRMATION_TITLE`
      ),
      message: i18n(
        `PAYMENTS_AND_CHARGES__PAYMENT_DETAIL__SAVE_${mode}__CONFIRMATION_MESSAGE`
      ),
      actionLabel: i18n('COMMONS__YES_CONTINUE__TEXT'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
    },
    response: {
      successTitle: i18n(
        `PAYMENTS_AND_CHARGES__PAYMENT_DETAIL__SAVE_${mode}__RESPONSE_TITLE`
      ),
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      successMessage: i18n(
        `PAYMENTS_AND_CHARGES__PAYMENT_DETAIL__SAVE_${mode}__RESPONSE_MESSAGE`
      ),
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      successLabel: 'Ir a Detalles',
      errorLabel: i18n('COMMONS__TRY_AGAIN'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
      successPath: './',
    },
  },
});
