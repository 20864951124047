import React, { useState, Fragment } from 'react';
import i18n from '@i18n';
import {
  alphaMaskWithSpacesAndAccent,
  numericMask,
  cardValidityMask,
  cardNumberValidations,
} from 'utils/masks';
import {
  Button,
  Icons,
  MaskedInput as Input,
  NumberInput,
} from '@casanova/casanova-common';
import { Section, Card, ConnectedSelect } from 'components';
import { useFormikContext } from 'formik';
import useBank from '../hooks/useBank';

const buttonGuardarBancoStyle = {
  backgroundColor: '#450E99',
  color: 'white',
  padding: '15px 32px',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '16px',
  margin: '4px 2px',
  marginTop: '10px',
  cursor: 'pointer',
  border: 'none',
  borderRadius: '4px',
};

const Holdback = () => {
  const { values, setFieldValue } = useFormikContext();
  const { handleSaveNewBank } = useBank();

  const [otroMonto, setOtroMonto] = useState(false);
  const [otroBanco, setOtroBanco] = useState(false);

  const handleChange = (e) => {
    if (e.target.value === 'otro') {
      setOtroMonto(true);
      return;
    }
    setOtroMonto(false);
  };

  const handleChangeBanco = (e) => {
    setFieldValue('holdbackBank', e.target.value);
    if (e.target.value === 'c1f56866-0904-499b-a3fe-1b70a3e207b3') {
      setOtroBanco(true);
      return;
    }
    setOtroBanco(false);
  };

  const valuesName = {
    bank: 'holdbackBank',
    otherBank: 'holdbackOtherBankName',
  };

  const handleNewBank = () => {
    const passed = handleSaveNewBank(values.holdbackOtherBankName, valuesName);
    setOtroBanco(passed);
  };

  return (
    <Section title="RETENCIÓN">
      <div className="common-muted-text font-weight-normal">
        {i18n('RESERVATIONS__HOLDBACK__DESCRIPTION')}
      </div>
      <div className="payment-page__main__icons mt-4">
        <Card noStatus padding="py-0 px-3 mr-3 mb-1" noTitle>
          <Icons.American width="3.125rem" height="3.125rem" />
        </Card>
        <Card noStatus padding="py-0 px-3 mr-3 mb-1" noTitle>
          <Icons.Mastercard width="3.125rem" height="3.125rem" />
        </Card>
        <Card noStatus padding="py-0 px-3 mb-1" noTitle>
          <Icons.Visa width="3.125rem" height="3.125rem" />
        </Card>
      </div>
      <div className="row">
        <div className="col-6">
          <ConnectedSelect
            label="Retencion*"
            name="holdbackRetention"
            isArrayList
            arrayList={[
              { value: '0.00', label: '0' },
              { value: '3000', label: '3,000' },
              { value: '5000', label: '5,000' },
              { value: '10000', label: '10,000' },
              { value: 'otro', label: 'Otro' },
            ]}
            onChange={handleChange}
          />
        </div>
        <div className="col-6">
          {otroMonto && (
            <NumberInput
              decimals={2}
              max={9_999_999}
              label="Otro monto*"
              placeholder="Otro monto*"
              name="holdbackOtherRetention"
              disabled={!otroMonto}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Input
            mask={alphaMaskWithSpacesAndAccent(40)}
            maskPlaceholder=""
            label="Titular de la tarjeta*"
            placeholder="Titular de la tarjeta*"
            name="holdbackCardHolder"
          />
        </div>
        <div className="col-6">
          <ConnectedSelect
            label="Banco*"
            name="holdbackBank"
            options="commons.catalogsBanks"
            onChange={handleChangeBanco}
          />
        </div>
        {otroBanco && (
          <Fragment>
            <div className="col-md-6 col-sm-12">
              <Input
                mask={alphaMaskWithSpacesAndAccent(40)}
                maskPlaceholder=""
                label="Otro Banco*"
                placeholder="Ingresa nuevo banco*"
                name="holdbackOtherBankName"
                disabled={!otroBanco}
              />
            </div>
            <div className="col-md-6 col-sm-12 d-flex align-items-center">
              <Button style={buttonGuardarBancoStyle} onClick={handleNewBank}>
                Guardar Banco
              </Button>
            </div>
          </Fragment>
        )}
      </div>
      <div className="row">
        <div className="col-6">
          <Input
            label="No. de tarjeta*"
            placeholder="No. de tarjeta*"
            name="holdbackCard"
            mask={cardNumberValidations}
            maskPlaceholder="#### #### #### ####"
          />
        </div>
        <div className="col-4">
          <Input
            mask={cardValidityMask}
            maskPlaceholder=""
            label="Vigencia*"
            placeholder="Vigencia*"
            name="holdbackValidity"
          />
        </div>
        <div className="col-2">
          <Input
            mask={numericMask(4)}
            maskPlaceholder=""
            label="CVC"
            placeholder="CVC"
            name="holdbackCode"
          />
        </div>
      </div>
    </Section>
  );
};

export default Holdback;
