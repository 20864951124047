import * as yup from 'yup';
import i18n from '@i18n';
import { REQUIRED_MESSAGE } from '@casanova/casanova-common/lib/utils/constants/index.js';

export const schema = yup.object().shape({
  incident: yup.string().required(REQUIRED_MESSAGE),
  // periodicity: yup.string().required(REQUIRED_MESSAGE),
  finalKm: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .min(1, i18n('ERROR__COMMONS__NUMBER_AMOUNT__INVALID_MIN', [1])),
  changeDate: yup.string().required(REQUIRED_MESSAGE),
  reason: yup.string().required(REQUIRED_MESSAGE),
});
