import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import {
  Section,
  ConnectedSelect,
  MaskedInput as Input,
  DatePickerV2,
  NumberInput,
} from '@casanova/casanova-common';
import { alphaMaskWithSpaces } from '@casanova/casanova-common/lib/utils/masks';
import { getNextYear } from '@casanova/casanova-common/lib/utils/date';
import i18n from '@i18n';
import { ContractBigModal } from '@contracts/sections';
import { isSafari } from 'utils/browser';
import { config } from './config';

const ChangeOfVehicleForm = ({
  disabledInput,
  initialValues,
  contractDetails,
  vehicle,
}) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (contractDetails.success) {
      if (vehicle.vehicleUuid) {
        setFieldValue('currentVehicle', vehicle.vehicleUuid);
      }

      setFieldValue('uuid', contractDetails.data.uuid);
    }
  }, [contractDetails, vehicle, setFieldValue]);

  return (
    <Section title={i18n('CONTRACTS__CHANGE_OF_VEHICLE__TITLE')} upperCase>
      <div className="row">
        <div className="col-md-4">
          <ConnectedSelect
            placeholder={i18n('CONTRACTS__CHANGE_OF_VEHICLE__INCIDENT', ['*'])}
            name="incident"
            label={i18n('CONTRACTS__CHANGE_OF_VEHICLE__INCIDENT', ['*'])}
            options="commons.catalogsPermanentChangeIncidents"
          />
        </div>
        
        <div className="col-md-4">
          <NumberInput
            placeholder={i18n('CONTRACTS__CHANGE_OF_VEHICLE__FINAL_KM', ['*'])}
            label={i18n('CONTRACTS__CHANGE_OF_VEHICLE__FINAL_KM', ['*'])}
            name="finalKm"
            disabled={disabledInput}
            decimals={0}
            max={999999}
            maskPrefix=""
          />
        </div>
        <div className="col-md-4">
          <DatePickerV2
            initialValue={initialValues.changeDate}
            placeholder={i18n('CONTRACTS__CHANGE_OF_VEHICLE__CHANGE_DATE', [
              '*',
            ])}
            name="changeDate"
            label={i18n('CONTRACTS__CHANGE_OF_VEHICLE__CHANGE_DATE', ['*'])}
            disabled={disabledInput}
            placement={isSafari() ? 'bottom' : undefined}
            maximumDate={getNextYear({ years: 5 })}
            formatDateValue
          />
        </div>
        <div className="col-md-4">
          <Input
            name="reason"
            placeholder={i18n('CONTRACTS__CHANGE_OF_VEHICLE__REASON', ['*'])}
            label={i18n('CONTRACTS__CHANGE_OF_VEHICLE__REASON', ['*'])}
            mask={alphaMaskWithSpaces(400)}
            maskPlaceholder=""
          />
        </div>
      </div>
    </Section>
  );
};

export default function ChangeOfVehicle({ fetchCatalog, ...props }) {
  useEffect(() => {
    fetchCatalog({
      catalogId: 'catalogs/permanent-change-incidents',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/permanent-change-periodicities',
      catalogPath: 'pricequotes',
    });
  }, []);

  return (
    <ContractBigModal
      {...props}
      config={config}
      responseDialog={props.responseDialogChangeOfVehicle}
    >
      {(helpers) => <ChangeOfVehicleForm {...helpers} />}
    </ContractBigModal>
  );
}
