import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import OfficialIdentification from './OfficialIdentification';

export default compose(
  connect(
    (state) => ({
      loader: state.commons.loader,
      innCodeScore: _get(
        state,
        'customers.identityValidation.data.innCodeInfo.score',
        {}
      ),
      innCodeImages: _get(
        state,
        'customers.identityValidation.data.innCodeInfo.images',
        {}
      ),
    }),
    {}
  )
)(OfficialIdentification);
