import { convertirNumeroALetras } from 'utils/convertPriceToText';
import { Button } from '@casanova/casanova-common';
import { number2mxn } from 'utils/currency';
import { Section, Card } from 'components';
import React, { useRef } from 'react';
import moment from 'moment';

const CashBill = ({ folio, customerFullName, concept, total, onlyPrint }) => {
  const iframeRef = useRef(null);

  const handlePrint = () => {
    if (iframeRef.current) {
      const iframeDocument =
        iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow.document;
      const contenidoAImprimir = document.getElementById('pdfRecibo').innerHTML;
      iframeDocument.open();
      iframeDocument.write(contenidoAImprimir);
      iframeDocument.close();
      iframeRef.current.contentWindow.print();
    }
  };

  return (
    <>
      <Section title="Recibo de pago" className={onlyPrint ? 'hidden' : ''}>
        <Card noStatus noTitle padding="p-0">
          <div id="pdfRecibo">
            <div
              style={{
                border: '1px solid',
                padding: '0.5rem 1rem',
                margin: '1rem',
                borderWidth: 2,
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  gap: 20,
                }}
              >
                <p
                  style={{
                    margin: 0,
                    color: '#f3df70',
                    fontWeight: 'bold',
                    fontSize: 20,
                  }}
                >
                  N° RECIBO
                </p>
                <div
                  style={{
                    // backgroundColor: '#fff7ba',
                    fontWeight: 900,
                    padding: '5px 20px',
                    fontSize: '1.5rem',
                    borderBottom: '1px solid #e7e9ca',
                    borderWidth: 2,
                  }}
                >
                  {folio}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                }}
              >
                <p>{`${moment().format('DD')} de ${moment().format(
                  'MMMM'
                )} del ${moment().format('YYYY')}`}</p>
              </div>
            </div>
            <div
              style={{
                border: '1px solid #a8a8a8',
                padding: '0.5rem 1rem',
                margin: '1rem',
                borderWidth: 2,
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 2fr',
                }}
              >
                <p
                  style={{
                    fontSize: '1.5rem',
                    textAlign: 'center',
                    fontWeight: 900,
                  }}
                >
                  RECIBÍ DE:
                </p>
                <p
                  style={{
                    // backgroundColor: '#fff7ba',
                    fontWeight: 900,
                    padding: '5px 20px',
                    fontSize: '1.5rem',
                    borderBottom: '1px solid #e7e9ca',
                    borderWidth: 2,
                    textAlign: 'center',
                  }}
                >
                  {customerFullName}
                </p>
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '3fr repeat(3, 2fr)',
                }}
              >
                <p
                  style={{
                    fontSize: '1.5rem',
                    textAlign: 'center',
                    fontWeight: 900,
                  }}
                >
                  LA SUMA DE:
                </p>
                <p
                  style={{
                    // backgroundColor: '#fff7ba',
                    fontWeight: 900,
                    padding: '5px 20px',
                    fontSize: '1.5rem',
                    borderBottom: '1px solid #e7e9ca',
                    borderWidth: 2,
                    textAlign: 'center',
                  }}
                >
                  {number2mxn(total)}
                </p>
                <p
                  style={{
                    padding: '5px 20px',
                    fontSize: '1.5rem',
                    borderBottom: '1px solid #e7e9ca',
                    borderWidth: 2,
                    textAlign: 'center',
                  }}
                >
                  Moneda
                </p>
                <p
                  style={{
                    padding: '5px 20px',
                    fontSize: '1.5rem',
                    borderBottom: '1px solid #e7e9ca',
                    borderWidth: 2,
                    textAlign: 'center',
                    color: '#d3535f',
                  }}
                >
                  MXN
                </p>
              </div>
              <div
                style={{
                  // backgroundColor: '#faffe2',
                  border: '5px solid #faf8d1',
                  fontWeight: 900,
                }}
              >
                <p style={{ fontSize: '1.5rem', textAlign: 'center' }}>
                  {/* Quinientos Mil Euros 00/100 */}
                  {convertirNumeroALetras(total)}
                </p>
              </div>
              <div
                style={{
                  fontSize: '1.5rem',
                  textAlign: 'center',
                  fontWeight: 900,
                  margin: '1rem 0',
                }}
              >
                <p>POR CONCEPTO DE:</p>
              </div>
              <div style={{ padding: '5PX 15px' }}>
                <p style={{ borderBottom: '1px solid #505244' }}>{concept}</p>
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '3fr repeat(3, 2fr)',
                  margin: '1rem 0',
                }}
              >
                <p
                  style={{
                    fontSize: '1.5rem',
                    textAlign: 'center',
                    fontWeight: 900,
                  }}
                >
                  Forma de Pago
                </p>
                <p
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                    // backgroundColor: '#fff7ba',
                    padding: '5px 20px',
                    fontSize: '1.5rem',
                  }}
                >
                  <input type="checkbox" id="cbox1" checked disabled />
                  <label htmlFor="cbox1" style={{ fontSize: 15 }}>
                    Efectivo
                  </label>
                </p>
                <p
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                    // backgroundColor: '#fff7ba',
                    padding: '5px 20px',
                    fontSize: '1.5rem',
                  }}
                >
                  <input type="checkbox" id="cbox2" disabled />
                  <label htmlFor="cbox2" style={{ fontSize: 15 }}>
                    Transferencia bancaria
                  </label>
                </p>
                <p
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                    // backgroundColor: '#fff7ba',
                    padding: '5px 20px',
                    fontSize: '1.5rem',
                  }}
                >
                  <input type="checkbox" id="cbox3" disabled />
                  <label htmlFor="cbox3" style={{ fontSize: 15 }}>
                    Cheque
                  </label>
                </p>
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  gridGap: '30rem',
                }}
              >
                <div>
                  <p
                    style={{
                      margin: 0,
                      borderBottom: '1px solid',
                      textAlign: 'center',
                      fontWeight: 900,
                    }}
                  ></p>
                  <p style={{ margin: 0, textAlign: 'center' }}>
                    Firma de Recibido
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      margin: 0,
                      borderBottom: '1px solid',
                      textAlign: 'center',
                      fontWeight: 900,
                    }}
                  ></p>
                  <p style={{ margin: 0, textAlign: 'center' }}>
                    Firma de Entregado
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row m-1 mb-3">
            <div className="col" />
            <div className="col-md-4 col-xl-3">
              <Button block onClick={handlePrint}>
                Imprimir
              </Button>
              <iframe
                ref={iframeRef}
                title="iframe"
                style={{ display: 'none' }}
                width="0"
                height="0"
              />
            </div>
          </div>
        </Card>
      </Section>
      {onlyPrint && (
        <Button block onClick={handlePrint} disabled={total <= 0}>
          Imprimir Recibo
        </Button>
      )}
    </>
  );
};

export default CashBill;
