import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { Icons, withForm } from '@casanova/casanova-common';
import { SchedDate, SpecItem } from '@reservations/components';
import { number2mxn } from 'utils/currency';
import { capitalizeFirstChar } from 'utils/masks';
import { Section, Card, PreventChange, TextField } from 'components';
import { isExtendOfContractFlow } from '@reservations/commons/utils';
import { getQuotePath } from 'utils/navigation';
import i18n from '@i18n';
import { IVA } from 'utils/constants/amounts';
import config from './config';
import './Detail.scss';
import CashBill from '../../Payments/commons/CashBill';

const Detail = ({
  branches,
  pickupDateTime,
  pickupBranch,
  returnDateTime,
  returnBranch,
  vehicleLinePrice,
  selectedCategoryName,
  quoteId,
  getCustomerDetail,
  fullVehicle = {},
  customer,
  customerFullName,
  folio,
  discountAmount,
  isPromocode,
  promocodeDiscount,
  paymentFormValues = {},
  fetchCatalog,
  cfdiCatalog,
  customerBillings,
  cardTypesCatalog,
  extendContract,
}) => {
  if (!quoteId) {
    window.location.href = getQuotePath('one');
  }

  useEffect(() => {
    fetchCatalog({
      catalogId: 'catalogs/cfdi-uses',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/bank-card-types',
      catalogPath: 'pricequotes',
    });
    if (customer) {
      getCustomerDetail(customer);
    }
  }, [customer, getCustomerDetail]);

  const [selectedStartBranch, setSelectedStartBranch] = useState({});
  const [selectedEndBranch, setSelectedEndBranch] = useState({});
  const [invoiceInfo, setInvoiceInfo] = useState({});
  const [hasInvoice, setHasInvoice] = useState({});
  const [paymentMethodName, setPaymentMethodName] = useState('');
  const [showReciboPago, setShowReciboPago] = useState(false);
  const [hasCash, setHasCash] = useState(false);

  const currentPlan = fullVehicle.vehicleLinePrices.filter(
    (plan) => plan.uuid === vehicleLinePrice
  );
  const isExtendContract = useMemo(() => isExtendOfContractFlow(), [
    isExtendOfContractFlow(),
  ]);
  const { name, price, period, includedCoveragePlan } = currentPlan[0];
  const amount = price / period;
  const ivaOfAmount = (amount * IVA) / (1 + IVA);
  const {
    specs,
    vehicleLineName,
    vehicleBrandName,
    vehicleTypeName,
    vehicleLineImagesOriginalUrls,
  } = fullVehicle;
  const {
    cylinder,
    loadCapacity,
    passengers,
    fuelType,
    seatType,
    transmissionType,
    doors,
  } = specs;
  const { paymentForm, cardDetails, multiRequest } = paymentFormValues;

  useEffect(() => {
    let textoPagos = '';

    const cashWasSelected = multiRequest?.find((mr) =>
      mr.paymentForm.includes('CASH')
    );
    setHasCash(cashWasSelected);

    multiRequest?.forEach((element) => {
      if (element.paymentForm.includes('CARD')) {
        const hasCard = element.paymentForm.includes('CARD');

        const paymentName = i18n(
          `COMMONS__PAYMENT_METHOD__${hasCard ? 'CARD' : element.paymentForm}`
        );

        if (hasCard && cardTypesCatalog.length > 0) {
          const method = cardTypesCatalog.find(
            (type) => type.uuid === element.cardDetails.bankCardType
          );

          textoPagos += `${paymentName} de ${method.name}, `;
        }
      } else if (element.paymentForm.includes('CASH')) {
        textoPagos += 'Efectivo, ';
      } else {
        textoPagos += `${element.paymentForm}, `;
      }
    });
    setPaymentMethodName(textoPagos.slice(0, textoPagos.length - 2));
  }, [
    paymentForm,
    paymentFormValues.cardDetails,
    cardTypesCatalog,
    cardDetails,
  ]);

  const startDate = moment(pickupDateTime, 'yyyy-MM-DD hh:mm');
  const endDate = moment(returnDateTime, 'yyyy-MM-DD hh:mm');

  const differenceD = endDate.diff(startDate, 'days');
  const differenceM = endDate.diff(startDate, 'minutes');

  const minutesToDays = differenceM / (24 * 60);
  const hasRemaining = minutesToDays - differenceD > 0;

  const daysDifference = hasRemaining ? differenceD + 1 : differenceD;
  const total = amount * daysDifference;
  const daysAdded = extendContract.daysAdded;
  const extendDiscount = isExtendContract ? daysAdded * amount - total : 0;
  const infoTitle = isExtendContract
    ? 'INFORMACIÓN DE LA EXTENSIÓN'
    : i18n('RESERVATIONS__DETAIL__INFO_SECTION__TITLE__RESERVATION');
  const returnDateTimeName = isExtendContract
    ? 'Nueva fecha de devolución'
    : 'Fecha de devolución';

  useEffect(() => {
    const { invoice = {}, invoiceForm = {} } = paymentFormValues;
    const validInvoice = !_isEmpty(invoice) || !_isEmpty(invoiceForm);
    setHasInvoice(validInvoice);
  }, [paymentFormValues.invoice, paymentFormValues.invoiceForm]);

  const getCfdiName = (uuid) => {
    if (cfdiCatalog.length > 0) {
      const cfdi = cfdiCatalog.find((cfdiElement) => cfdiElement.uuid === uuid);
      return cfdi.name;
    }
    return '';
  };

  useEffect(() => {
    if (hasInvoice) {
      if (!paymentFormValues.invoice && paymentFormValues.invoiceForm) {
        const {
          invoiceSocialReason,
          invoiceRfc,
          emailInvoice,
          invoiceCfdiUse,
        } = paymentFormValues.invoiceForm;
        setInvoiceInfo({
          socialReason: invoiceSocialReason,
          rfc: invoiceRfc,
          email: emailInvoice,
          cfdi: getCfdiName(invoiceCfdiUse),
        });
      } else if (paymentFormValues.invoice && customerBillings.length > 0) {
        const { email, billingDetails } = paymentFormValues.invoice;
        const selectedBilling = customerBillings.find(
          (billing) => billing.uuid === billingDetails
        );
        const { rfc, cfdiUse } = selectedBilling;
        setInvoiceInfo({
          socialReason: name,
          rfc,
          email,
          cfdi: cfdiUse.name,
        });
      }
    }
  }, [hasInvoice, paymentFormValues, cfdiCatalog, customerBillings]);

  useEffect(() => {
    if (branches && branches.length > 0 && pickupBranch && returnBranch) {
      setSelectedStartBranch(
        branches
          .filter((item) => item.uuid === pickupBranch)
          .reduce((_, current) => current, {})
      );

      setSelectedEndBranch(
        branches
          .filter((item) => item.uuid === returnBranch)
          .reduce((_, current) => current, {})
      );
    }
  }, [branches, pickupBranch, returnBranch]);

  const vehicleTitle = `${capitalizeFirstChar(
    vehicleLineName.toLowerCase()
  )} ${capitalizeFirstChar(vehicleBrandName.toLowerCase())}`;

  const discountWord = 'DESCUENTO';

  const iframeRef = useRef(null);

  const handlePrint = () => {
    if (iframeRef.current) {
      const iframeDocument =
        iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow.document;
      const contenidoAImprimir = document.getElementById('pdfRecibo').innerHTML;
      iframeDocument.open();
      iframeDocument.write(contenidoAImprimir);
      iframeDocument.close();
      iframeRef.current.contentWindow.print();
    }
  };

  useEffect(() => {
    if (hasCash && paymentFormValues.withoutPayment) {
      setShowReciboPago(false);
    } else if (hasCash && !paymentFormValues.withoutPayment) {
      setShowReciboPago(true);
    } else {
      setShowReciboPago(false);
    }
  }, [showReciboPago, hasCash, paymentFormValues]);

  return (
    <>
      <PreventChange preventChange onlyWhenCanceling />
      <div className="Detail">
        <Section title={infoTitle}>
          <div className="row">
            <div className="col-7 offset-5">
              <div className="Detail__customer">
                <div className="Detail__customer__name">
                  {`${i18n(
                    'RESERVATIONS__DETAIL__CUSTOMER'
                  )} ${customerFullName}`}
                </div>
                <div className="Detail__customer__folio">
                  {`${i18n('RESERVATIONS__DETAIL__FOLIO')} ${folio}`}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <div className="vehicle-image">
                <img src={vehicleLineImagesOriginalUrls} alt="" />
              </div>
            </div>
            <div className="col-7 vehicle-features">
              <p>
                <span className="kind">
                  {selectedCategoryName} / {vehicleTypeName}
                </span>
                <br />
                <span className="title">{vehicleTitle}</span>
              </p>
              <div className="features">
                <div className="title">Características</div>
                <div className="feature-list">
                  <SpecItem feat="cilindros" specList={cylinder} />
                  <SpecItem feat="de capacidad" specList={loadCapacity} />
                  <SpecItem feat="pasajeros" specList={passengers} />
                  <SpecItem feat="" specList={fuelType} />
                  <SpecItem feat="" specList={seatType} />
                  <SpecItem feat="puertas" specList={doors} />
                  <SpecItem feat="" specList={transmissionType} />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="plan-features">
                    <span className="coverage">
                      <Icons.GoldenShield width="1.25rem" height="1.5rem" />
                      Cuenta con un paquete de cobertura integrado BÁSICO
                    </span>
                    <ul className="plan-features-list">
                      {includedCoveragePlan.features
                        .sort((a, b) => a.length - b.length)
                        .map((feature) => (
                          <li key={feature}>{feature}</li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section title={i18n('RESERVATIONS__DETAIL__PLAN_SECTION__TITLE')}>
          <Card noStatus noTitle>
            <div className="row">
              <div className="col-4 d-flex common-info-text flex-column align-content-center justify-content-center">
                <span className=" font-weight-bold">PLAN {name} MXN</span>
                <span className="common-muted-text text-lg">
                  {number2mxn(amount)} x día
                </span>
              </div>
              <div className="col-4">
                <SchedDate
                  className="mb-0"
                  date={pickupDateTime}
                  branch={selectedStartBranch.name}
                  title="Fecha de salida"
                />
              </div>
              <div className="col-4">
                <SchedDate
                  className="mb-0"
                  date={returnDateTime}
                  branch={selectedEndBranch.name}
                  title={returnDateTimeName}
                  end
                />
              </div>
            </div>
          </Card>
        </Section>
        <Section title={i18n('RESERVATIONS__DETAIL__INVOICE_SECTION__TITLE')}>
          <Card noStatus noTitle padding="p-0">
            <div className="Detail__invoice-summary">
              {hasInvoice && (
                <div className="Detail__invoice-summary__data">
                  <div className="row">
                    <div className="col-4">
                      <TextField title="Pago" value={paymentMethodName} />
                    </div>
                    <div className="col-4">
                      <TextField title="RFC" value={invoiceInfo.rfc} />
                    </div>
                    <div className="col-4">
                      <TextField
                        title="Razón social"
                        value={invoiceInfo.socialReason}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <TextField
                        title="Uso de la factura"
                        value={invoiceInfo.cfdi}
                      />
                    </div>
                    <div className="col-4">
                      <TextField
                        title="Correo electrónico"
                        value={invoiceInfo.email}
                      />
                    </div>
                  </div>
                </div>
              )}
              {!hasInvoice && (
                <div className="Detail__invoice-summary__data Detail__invoice-summary__data--no-data">
                  <div className="row">
                    <div className="col-6">
                      <TextField title="Pago" value={paymentMethodName} />
                    </div>
                    <div className="col-6">
                      <TextField
                        title="FACTURA ELECTRÓNICA"
                        value="PÚBLICO EN GENERAL"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="Detail__invoice-summary__amount">
                <div className="common-info-text text-right text-uppercase">
                  Renta: {number2mxn(amount - ivaOfAmount)} <br />
                  {(discountAmount > 0 || promocodeDiscount > 0) && (
                    <span>
                      {discountWord}:{' '}
                      {number2mxn(
                        isPromocode ? promocodeDiscount : discountAmount
                      )}
                    </span>
                  )}
                  <br />
                  IVA: {number2mxn(ivaOfAmount)}
                  <br />
                  {isExtendContract && (
                    <div>A cuenta: {number2mxn(extendDiscount)}</div>
                  )}
                </div>
                <br />
                <div className="common-info-text font-weight-bold text-uppercase d-flex justify-content-between">
                  <div>PRECIO TOTAL</div>
                  {(discountAmount > 0 || promocodeDiscount > 0) && (
                    <div className="text-right text-decoration-line-through ">
                      {number2mxn(total)}
                    </div>
                  )}
                </div>
                <br />
                <div className="gray-amount-text text-uppercase text-right">
                  mxn{' '}
                  {number2mxn(
                    isExtendContract
                      ? total + extendDiscount
                      : total -
                          (isPromocode ? promocodeDiscount : discountAmount)
                  )}
                </div>
                <div className="common-info-text font-weight-bold text-uppercase text-right">
                  POR {isExtendContract ? daysAdded : daysDifference} DÍAS
                </div>
              </div>
            </div>
          </Card>
        </Section>
        {showReciboPago && (
          <CashBill
            folio={folio}
            customerFullName={customerFullName}
            concept={
              isExtendContract ? 'Extensión de contrato' : 'Contrato de renta'
            }
            total={
              isExtendContract
                ? total + extendDiscount
                : total - (isPromocode ? promocodeDiscount : discountAmount)
            }
          />
        )}
      </div>
    </>
  );
};

Detail.propTypes = {
  pickupDateTime: PropTypes.string,
  pickupBranch: PropTypes.string,
  returnBranch: PropTypes.string,
  customerFullName: PropTypes.string,
  folio: PropTypes.string,
  discountAmount: PropTypes.number,
  isPromocode: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  paymentFormValues: PropTypes.object,
};

Detail.defaultProps = {
  pickupDateTime: '',
  pickupBranch: 'Tlalpan',
  returnBranch: 'Tlalpan',
  customerFullName: '',
  folio: '',
  discountAmount: 0,
  isPromocode: false,
};

export default withForm({ config })(Detail);
