import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import {
  validateCurp,
  validateEndorsementCurp,
  cleanValidateCurp,
  cleanValidateEndorsementCurp,
  setCustomerType,
  setCustomerPersonType,
  getDetail,
  resetDetail,
  resetAvalCustomer,
  setFormValues,
  setAvalPreventChange,
  setSelectionOfAvalCustomerByDetail,
  setAvalCustomerByDetail,
  setTenderType,
  getLinkToValidateIdentity,
  closeResponseGetLinkToValidateIdentity,
  closeResponseValidateIdentity,
  validateIdentity,
} from 'store/customers/actions';
import { getSuburbsByZipCode } from 'store/commons/actions';
import { assignCustomer } from 'store/reservations/actions';
import { postAvailableUser } from 'store/adminPanel/actions';
import CustomerInformation from './CustomerInformation';

export default compose(
  connect(
    (state) => ({
      loader: state.commons.loader,
      statesList: _get(state, 'commons.states.list', []),
      sexesList: _get(state, 'commons.sexes.list', []),
      nacionalities: _get(state, 'commons.nationalities.list', []),
      curpInfo: _get(state, 'customers.curpValidation', {}),
      endorsementCurpInfo: _get(
        state,
        'customers.endorsementCurpValidation',
        {}
      ),
      availableUser: _get(state, 'adminPanel.users.avaibleUser', undefined),
      customerType: _get(state, 'customers.customerType', ''),
      customerPersonType: _get(state, 'customers.customerPersonType', ''),
      tenderType: _get(state, 'customers.tenderType', ''),
      customerDetail: _get(state, 'customers.customerDetail', {}),
      selectionOfAvalCustomerByDetail: _get(
        state,
        'customers.selectionOfAvalCustomerByDetail',
        false
      ),
      customerFormValues: _get(state, 'customers.formValues', {}),
      identityValidation: _get(state, 'customers.identityValidation', {}),
      urlInnCodeInfo: _get(
        state,
        'customers.identityValidation.data.urlInfo',
        {}
      ),
      innCodeInfo: _get(
        state,
        'customers.identityValidation.data.innCodeInfo',
        {}
      ),
      flowInnCodeCompleted: _get(
        state,
        'customers.identityValidation.data.innCodeInfo.flowCompleted',
        true
      ),
    }),
    {
      validateCustomerCurp: validateCurp,
      validateEndorsementCurp,
      postAvailableUser,
      cleanValidateCurp,
      cleanValidateEndorsementCurp,
      assignCustomer,
      setCustomerType,
      setCustomerPersonType,
      getDetail,
      resetDetail,
      resetAvalCustomer,
      setFormValues,
      setAvalPreventChange,
      setSelectionOfAvalCustomerByDetail,
      setAvalCustomerByDetail,
      setTenderType,
      getLinkToValidateIdentity,
      closeResponseGetLinkToValidateIdentity,
      closeResponseValidateIdentity,
      validateIdentity,
      getSuburbsByZipCode,
    }
  )
)(CustomerInformation);
