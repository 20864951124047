import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withLayout } from '@casanova/casanova-common';
import {
  getInvoiceDetails,
  cleanInvoiceDetails,
  getListOfCRPs,
  setCRPsPage,
  reInvoice,
} from 'store/invoices/actions';
import InvoiceDetail from './InvoiceDetail';

export default withLayout(
  compose(
    connect(
      (state) => ({
        invoiceDetails: state.invoices.invoiceDetails.data,
        listOfCrp: state.invoices.listOfCRP,
      }),
      {
        getInvoiceDetails,
        cleanInvoiceDetails,
        getListOfCRPs,
        setCRPsPage,
        reInvoice,
      }
    )
  )(InvoiceDetail)
);
