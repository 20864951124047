import { useContext } from 'react';
import { MultiPaymentContext } from './context';

const useMultiPayment = () => {
  const context = useContext(MultiPaymentContext);

  if (context === undefined)
    throw new Error(
      'useMultiPayment must be used within a MultiPaymentProvider'
    );

  return context;
};

export default useMultiPayment;
