import {
  YYYY_MM_DD,
  DD_MM_YYYY_WITH_SCRIPT,
  MMMM_YYYY,
  timeZone,
  HH_MM,
  SPANISH_FORMAT,
  DD_MM_YYYY,
  formatDateFromISO,
  generateYearsArray,
  generateSelectYearsObject,
  generateSelectMonthsObject,
  generateHoursSelect,
  formattedDateToPickerV2,
  getNextYear,
  cleanDate,
  spanishLocale,
  notSunday,
  generateDateFromValidity,
  date2CalendarDate,
  today2CalendarDate,
  generateCalendarMaxDate,
  generateCalendarMinimumDate,
  generateListOfYears,
  realDate,
  getCurrentDateByTime,
  getTimeParts,
  getDateByTime,
  formatDateWithTime,
} from '@casanova/casanova-common/lib/utils/date';
import moment from 'moment';

const isToday = (date) => {
  const currentDate = moment(date, YYYY_MM_DD);
  const now = moment();
  return currentDate.isSame(now.format(YYYY_MM_DD));
};

export {
  YYYY_MM_DD,
  DD_MM_YYYY_WITH_SCRIPT,
  MMMM_YYYY,
  timeZone,
  HH_MM,
  SPANISH_FORMAT,
  DD_MM_YYYY,
  formatDateFromISO,
  generateYearsArray,
  generateSelectYearsObject,
  generateSelectMonthsObject,
  generateHoursSelect,
  formattedDateToPickerV2,
  getNextYear,
  cleanDate,
  spanishLocale,
  isToday,
  notSunday,
  generateDateFromValidity,
  generateCalendarMaxDate,
  date2CalendarDate,
  today2CalendarDate,
  generateCalendarMinimumDate,
  generateListOfYears,
  realDate,
  getCurrentDateByTime,
  getTimeParts,
  getDateByTime,
  formatDateWithTime,
};
