import moment from 'moment';
import { useFormikContext } from 'formik';

import {
  formattedDateToPickerV2,
  YYYY_MM_DD,
} from '@casanova/casanova-common/lib/utils/date';

import { onlyLettersWithSpaces, numericMask, hourMask } from 'utils/masks';
import { CellText, Cell, CellDashed } from 'components/ColumnsCells';
import { number2mxn } from 'utils/currency';
import { AdditionalHeaderCamp } from 'components';
import { useDispatch } from 'react-redux';
import versions from '@casanova/casanova-common/lib/utils/constants/versions';
import { setListParams } from '../../../store/invoices/actions';

const MIN_DATE = formattedDateToPickerV2(moment('2020').format(YYYY_MM_DD));
const MAX_DATE = formattedDateToPickerV2(
  moment().add(1, 'day').format(YYYY_MM_DD)
);

const invoiceStatus = [
  {
    label: 'Cancelada',
    value: 'e45c829c-0934-4a3f-b552-7b7e6a423826',
    color: '#f75555',
  },
  {
    label: 'Activa',
    value: 'd83ec913-32ec-4944-bd89-ce81ccd2d43c',
    color: '#27CB8A',
  },
  {
    label: 'En proceso',
    value: '0327ac28-d230-4d03-884e-deb912e5b5d2',
    color: '#f2a000',
  },
  {
    label: 'Error',
    value: 'edda0576-1125-43e6-aebf-8fc00d7ef60a',
    color: '#f2a000',
  },
];
const getStatusColor = (status) => {
  const statusSelected = invoiceStatus.find((item) => item.label === status);
  return statusSelected ? statusSelected.color : '#000';
};

export default [
  {
    label: 'No. de contrato',
    field: 'contractNumber',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={numericMask(10)}
        name="contractNumber"
        searchKey="contractNumber"
      />
    ),
  },
  {
    label: 'Serie',
    field: 'serie',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp name="serie" searchKey="serie" />
    ),
  },
  {
    label: 'No. de factura',
    field: 'folio',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={numericMask(10)}
        name="folio"
        searchKey="folio"
      />
    ),
  },
  {
    label: 'Cliente',
    field: 'customerFullName',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="customerFullName"
        searchKey="customerFullName"
      />
    ),
  },
  {
    label: 'Monto',
    field: 'totalAmount',
    align: 'center',
    renderer: (props) => (
      <Cell className="text-center">{() => number2mxn(props.value)}</Cell>
    ),
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="totalAmount"
        searchKey="totalAmount"
        type="number"
      />
    ),
  },
  {
    label: 'Fecha y hora',
    field: 'dateTime',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => {
      const { setFieldValue, values } = useFormikContext();
      const dispatch = useDispatch();
      return (
        <AdditionalHeaderCamp
          name="dateTime"
          searchKey="dateTime"
          mask={numericMask(10)}
          type="DATE"
          version={versions.V2}
          datePickerProps={{
            resetDate: Boolean(values.dateTime),
            onResetDate: () => {
              setFieldValue('dateTime', '');
              dispatch(setListParams({ dateTime: '' }));
            },
            minimumDate: MIN_DATE,
            maximumDate: MAX_DATE,
          }}
        />
      );
    },
  },
  {
    label: 'Estatus',
    field: 'statusInvoice',
    align: 'center',
    renderer: (props) => {
      return (
        <CellDashed
          {...props}
          fixedColor={getStatusColor(props.data.invoiceStatus.name)}
          value={props.data.invoiceStatus.name}
          align="left"
          textClass="text-left"
        />
      );
    },
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="status"
        searchKey="status"
        options={invoiceStatus}
        selectProps={{ fullKey: true, availableFirstValue: true }}
        type="SELECT"
      />
    ),
  },
];
