import ClientCards from '../ClientCards';
import { Toast } from 'primereact/toast';
import { useFormikContext } from 'formik';
import useBank from '../hooks/useBank';
import useMultiPayment from '../hooks/useMultiPayment';

const Bank = () => {
  const { bankModal, setBankModal, showLoader, bankToast } = useBank();
  const { setFieldValue } = useFormikContext();
  const { uuidCustomer } = useMultiPayment();
  const handleCardsClose = () => {
    setBankModal((prev) => ({ ...prev, show: false }));
  };

  return (
    <>
      {showLoader && <Loader show />}
      <Toast ref={bankToast} position="center" />
      <ClientCards
        show={bankModal.show}
        onClose={handleCardsClose}
        uuidCustomer={uuidCustomer}
        setValues={setFieldValue}
        index={bankModal.cardIndex}
      />
    </>
  );
};

export default Bank;
