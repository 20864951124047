import { useContext } from 'react';
import { BankContext } from './context';

const useBank = () => {
  const context = useContext(BankContext);

  if (context === undefined)
    throw new Error('useBank must be used within a BankProvider');

  return context;
};

export default useBank;
