import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { useFormikContext } from 'formik';
import {
  MaskedInput as Input,
  Select,
  PlusMinusInput,
  RadioGroupInput,
  NumberInput,
  Button,
} from '@casanova/casanova-common';
import {
  alphaMask,
  numericMask,
  alphaMaskWithSpaces,
} from '@casanova/casanova-common/lib/utils/masks';
import RoleVerifier from 'components/RoleVerifier';
import { EDIT_VEHICLE_GENERAL_INFORMATION } from 'utils/roles/permissions';
import UserInfo from 'components/UserInfo';
import { useVehicle } from 'hooks';

const VehicleForm = ({
  listSeatType,
  listTransmission,
  lineSelected,
  selectBrand,
  selectLine,
  brandSelected,
  listModels,
  listBrands,
  listColors,
  listLines,
  listFuelType,
  listOrigins,
  listBusinessArea,
  getSpecs,
  specs,
  action,
  restartSpecs,
  linesBySelectedBrand,
  onEdit,
  onCancelEdit,
  onSaveEdit,
}) => {
  const { values, setFieldValue, dirty } = useFormikContext();
  const [customModels, setCustomModels] = useState([]);

  const { isSold } = useVehicle();

  useEffect(() => {
    if (action !== 'add') {
      setCustomModels(_get(values, 'modelsDetail', []));
    }
  }, [action, values]);

  const handleModelChange = useCallback(
    ({
      nativeEvent: {
        target: { value },
      },
    }) => {
      const modelSelected = listModels.filter((model) => model.uuid === value);
      setFieldValue('modelKey', modelSelected[0].key);
      setFieldValue('modelYear', modelSelected[0].name);

      if (values.version && action === 'add') {
        getSpecs({ version: values.version, model: value });
      }
    },
    [values.version, getSpecs, listModels, setFieldValue, action]
  );

  const handleSelectBrand = useCallback(
    (e) => {
      setFieldValue('line', undefined);
      setFieldValue('version', undefined);
      selectBrand(e);
    },
    [selectBrand, setFieldValue]
  );

  const handleSelectLine = useCallback(
    (e) => {
      setFieldValue('version', undefined);
      selectLine(e);
    },
    [selectLine, setFieldValue]
  );

  const handleChangeVersions = useCallback(
    ({
      nativeEvent: {
        target: { value },
      },
    }) => {
      if (values.model && action === 'add') {
        getSpecs({ version: value, model: values.model });
      }
    },
    [values.model, getSpecs, action]
  );

  useEffect(() => {
    if (specs && action === 'add') {
      setFieldValue('cylinder', specs.cylinder);
      setFieldValue('fuelType', specs.fuelType);
      setFieldValue('transmissionType', specs.transmissionType);
      setFieldValue('seatType', specs.seatType);
      setFieldValue('passengers', specs.passengers);
      setFieldValue('doors', specs.doors);
      setFieldValue('loadCapacity', specs.loadCapacity);
    } else if (!specs && action === 'add' && dirty) {
      setFieldValue('cylinder', '');
      setFieldValue('fuelType', '');
      setFieldValue('transmissionType', '');
      setFieldValue('seatType', '');
      setFieldValue('passengers', '');
      setFieldValue('doors', '');
      setFieldValue('loadCapacity', '');
    }
  }, [specs, setFieldValue, action, dirty]);

  useEffect(() => {
    restartSpecs();
  }, [restartSpecs]);

  const linesOptions = useMemo(
    () =>
      listLines && listLines.length > 0 ? listLines : linesBySelectedBrand,
    [listLines, linesBySelectedBrand]
  );

  return (
    <>
      <div className="row">
        {action !== 'add' && (
          <div className="col-md-4">
            <Input label="Estatus" name="status" disabled />
          </div>
        )}
        <div className="col-md-4">
          <Select
            label="Marca*"
            disabled={action !== 'add'}
            onChange={handleSelectBrand}
            name="brand"
            options={listBrands}
          />
        </div>
        <div className="col-md-4">
          <Select
            label="Linea*"
            disabled={action !== 'add'}
            onChange={handleSelectLine}
            name="line"
            options={action !== 'add' ? linesOptions : brandSelected.lines}
          />
        </div>
        <div className="col-md-4">
          <Select
            disabled={action === 'view'}
            label="Versión*"
            name="version"
            onChange={handleChangeVersions}
            options={lineSelected.versions}
          />
        </div>
        <div className="col-md-4">
          <Select
            label="Modelo*"
            disabled={action !== 'add'}
            name="model"
            onChange={handleModelChange}
            options={action !== 'add' ? customModels : listModels}
          />
        </div>
        <div className="col-md-4">
          <Input
            mask={alphaMask(17)}
            maskPlaceholder=""
            label="Número de serie*"
            placeholder="Número de serie*"
            name="serialNumber"
            disabled={action !== 'add'}
          />
        </div>
        <div className="col-md-4">
          <Input
            mask={alphaMaskWithSpaces(30)}
            maskPlaceholder=""
            label="Número de motor*"
            placeholder="Número de motor*"
            name="engineNumber"
            disabled={action === 'view'}
          />
        </div>
        <div className="col-md-4">
          <Input
            mask={alphaMask(7)}
            maskPlaceholder=""
            label="Clave Vehicular*"
            placeholder="Clave Vehicular*"
            name="vehicularKey"
            disabled={action !== 'add'}
          />
        </div>
        <div className="col-md-4">
          <Select
            label="Origen*"
            name="origin"
            options={listOrigins}
            disabled={action === 'view'}
          />
        </div>
        {action !== 'view' && (
          <div className="col-md-4">
            <Select
              label="Unidad de negocio*"
              name="businessArea"
              options={listBusinessArea}
            />
          </div>
        )}
        <div className="col-md-4">
          <PlusMinusInput
            label="Números de cilindros*"
            placeholder="0"
            min={2}
            max={20}
            name="cylinder"
            disabled={action === 'view' || (action === 'add' && specs)}
          />
        </div>
        <div className="col-md-4">
          <Select
            label="Tipo de combustible*"
            name="fuelType"
            options={listFuelType}
            disabled={action === 'view' || (action === 'add' && specs)}
          />
        </div>
        <div className="col-md-4 switch-inverted">
          <RadioGroupInput
            labelClass="d-flex align-items-center col-form-label pt-0 pb-0 font-weight-normal"
            groupClass="col-12 d-flex justify-content-between seat-type"
            parentClass="vehicle-radio-input"
            label="TIPO DE TRANSMISIÓN*"
            name="transmissionType"
            options={listTransmission}
            disabled={action === 'view' || (action === 'add' && specs)}
            standardError
          />
        </div>
        <div className="col-md-4">
          <Select
            label="Color*"
            disabled={action === 'view'}
            name="color"
            options={listColors}
          />
        </div>
        <div className="col-md-4 switch-inverted">
          <RadioGroupInput
            labelClass="d-flex align-items-center col-form-label pt-0 pb-0 font-weight-normal"
            groupClass="col-12 d-flex justify-content-between seat-type"
            parentClass="vehicle-radio-input"
            label="TIPO DE ASIENTOS*"
            name="seatType"
            options={listSeatType}
            disabled={action === 'view' || (action === 'add' && specs)}
            standardError
          />
        </div>
        <div className="col-md-4">
          <PlusMinusInput
            label="Número de Pasajeros*"
            placeholder="0"
            name="passengers"
            min={1}
            max={50}
            disabled={action === 'view' || (action === 'add' && specs)}
          />
        </div>
        <div className="col-md-4">
          <PlusMinusInput
            label="Número de Puertas*"
            placeholder="0"
            name="doors"
            min={1}
            max={5}
            disabled={action === 'view' || (action === 'add' && specs)}
          />
        </div>
        <div className="col-md-4">
          <NumberInput
            decimals={2}
            max={99.99}
            maskPrefix=""
            label="Capacidad de carga (ton)*"
            placeholder="Capacidad de carga (ton)*"
            name="loadCapacity"
            disabled={action === 'view' || (action === 'add' && specs)}
          />
        </div>
        <div className="col-md-4">
          <NumberInput
            max={99999}
            maskPrefix=""
            label="Kilometraje"
            placeholder="Kilometraje"
            name="mileage"
            disabled={action === 'view'}
          />
        </div>
        <div className="col-md-4">
          <PlusMinusInput
            min={1}
            max={3}
            label="Número de llaves*"
            placeholder="0"
            name="keys"
            disabled={action === 'view'}
          />
        </div>
        <br />
      </div>
      <RoleVerifier identifier={EDIT_VEHICLE_GENERAL_INFORMATION}>
        {action === 'view' && !isSold && (
          <div className="row">
            <div className="col-6 offset-3 col-md-2 offset-md-10">
              <br />
              <Button onClick={onEdit} block>
                {' '}
                Editar{' '}
              </Button>
            </div>
          </div>
        )}
      </RoleVerifier>
      {action === 'edit' && (
        <div className="row">
          <div className="offset-md-8 col-md-4 text-right">
            <div className="row">
              <div className="col-md-6">
                <Button onClick={onCancelEdit} outline block>
                  Cancelar
                </Button>
              </div>
              <div className="col-md-6">
                <Button
                  disabled={!dirty}
                  type="submit"
                  block
                  onClick={onSaveEdit}
                >
                  Guardar
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <br />
      <UserInfo {...values} />
    </>
  );
};

VehicleForm.propTypes = {
  action: PropTypes.string,
};

VehicleForm.defaultProps = {
  action: 'add',
};

export default VehicleForm;
