import moment from 'moment';
import { YYYY_MM_DD, timeZone } from '@casanova/casanova-common/lib/utils/date';

export const formatter = (values) => {
  const formatterValues = {
    juridicalPerson: values.customer,
    startDate: moment(values.startDate, YYYY_MM_DD)
      .tz(timeZone)
      .format(YYYY_MM_DD),
    endDate: moment(values.endDate, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD),
    businessArea: values.businessArea,
    noContrato: values.noContrato,
    folio: values.folio,
    rfc: values.rfc,
    maxAmount: values.maxAmount,
    minAmount: values.minAmount,
    naturalName: values.naturalName,
    branch: values.branch,
    manager: values.manager,
    vehiclesAdmin: values.vehiclesAdmin,
    coordinator: values.coordinator,
  };

  return formatterValues;
};
