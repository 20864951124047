export const holdbackInitialValues = {
  holdbackRetention: '',
  holdbackOtherRetention: '',
  holdbackCardHolder: '',
  holdbackBank: '',
  holdbackOtherBankName: '',
  holdbackCard: '',
  holdbackValidity: '',
  holdbackCode: '',
  needHoldback: false,
};

export const holdbackInitialTouched = {
  holdbackRetention: false,
  holdbackOtherRetention: false,
  holdbackCardHolder: false,
  holdbackBank: false,
  holdbackOtherBankName: false,
  holdbackCard: false,
  holdbackValidity: false,
  holdbackCode: false,
};
