import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icons, Modal, withForm } from '@casanova/casanova-common';
import { VehicleSectionChargeValue } from '@reservations/components';
import { number2mxn } from 'utils/currency';
import { useReservation } from 'hooks';
import { IVA } from 'utils/constants/amounts';
import config from './config';
import './ExtraChargeSummary.scss';
import { ActionModal } from '@casanova/casanova-common/lib/Modal';
import { RefundDetail } from '@dailyRentContracts/sections';
import { Button as ButtonPrime } from 'primereact/button';
import _isEmpty from 'lodash/isEmpty';

const ExtraChargeSummary = ({
  reservationInfo = {},
  discountAmount = 0,
  extraCharges = {},
  obtenerPrecio,
  reservationUuid,
  customerRefund,
  savePayment,
  getVehicleLinePrices,
  loader,
}) => {
  const { naturalPerson = {}, folio = '' } = reservationInfo;
  const {
    listOfExtraCharges = [],
    totalPrice: totalBeforeDiscounts = 0,
  } = extraCharges;
  const { fullName = '' } = naturalPerson;

  const { vehicleInfo = {} } = useReservation();
  const { vehicleTitle = '' } = vehicleInfo;

  const IVAOfAmount = (totalBeforeDiscounts * IVA) / (1 + IVA);
  const subTotal = totalBeforeDiscounts - IVAOfAmount;

  const hasRefund =
    !_isEmpty(customerRefund) && folio == customerRefund.reservationFolio;

  const totalPrice = hasRefund
    ? totalBeforeDiscounts - customerRefund.amount
    : totalBeforeDiscounts;

  const [request, setRequest] = useState({});
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showContinueWithoutPayment, setShowContinueWithoutPayment] = useState(
    false
  );

  useEffect(() => {
    obtenerPrecio(totalBeforeDiscounts);
  }, []);

  const handleWithoutPayment = () => {
    setRequest({
      paymentForm: 'CASH',
      amount: 0,
      discount: 0,
      paymentType: 'EXTRA_CHARGES',
      reservation: reservationUuid,
      withoutPayment: true,
      isTheLastPayment: true,
    });
    setShowContinueWithoutPayment(true);
  };

  const handleOnContinueWithoutPayment = () => {
    setShowContinueWithoutPayment(false);
    savePayment({
      request,
      redirect: `/returns/${reservationUuid}/refund-vehicle-parts?rejected=true`,
      inExtraCharges: true,
    });
  };

  const handleOnCancelWithoutPayment = () => {
    setShowContinueWithoutPayment(false);
    setRequest({});
  };

  useEffect(() => {
    if (!_isEmpty(reservationInfo)) {
      const payload = {
        vehicleLineUuid:
          reservationInfo.priceQuote.selectedVehicleLinePrice.vehicle
            .vehicleLineUuid,
      };
      getVehicleLinePrices(payload);
    }
  }, [reservationInfo]);

  const RefundDetailWithForm = withForm({ config })(RefundDetail)({
    reservationUuid,
    layout: false,
    onCancel: () => setShowRefundModal(false),
    disabled: true,
  });

  return (
    <>
      <ActionModal
        open={showContinueWithoutPayment}
        icon={<Icons.Quote width="6rem" height="6rem" />}
        onClose={handleOnCancelWithoutPayment}
        onAction={handleOnContinueWithoutPayment}
        title="¿Continuar?"
        message={
          <>
            Continuar sin pago de cargos extras. <br />
            Se podrán pagar después en la sección de abonos y cargos.
          </>
        }
        actionLabel="Sí"
        closeLabel="No"
      />
      <Modal
        open={showRefundModal}
        className="ViewRefundDetail"
        topContainerClassName={`${loader ? 'd-none' : ''}`}
        contentClassName="px-4 py-4 container"
        onClose={() => setShowRefundModal(false)}
        closeButton
        bigModal
      >
        {RefundDetailWithForm}
      </Modal>
      <div className="col-12 col-lg-4 col-xl-3">
        <div className="main-content Summary">
          <div className="header">{fullName}</div>
          <div className="vehicle-details">
            <div className="row m-0 p-0">
              <div className="d-block d-lg-none col-6">
                <img
                  className="ExtraChargeSummary__Vehicle_image"
                  src={vehicleInfo.image}
                  alt={vehicleTitle}
                />
              </div>
              <div className="col-6 col-lg-12 p-0 m-0">
                <div className="row p-0 m-0">
                  <div className="col-7 m-0 p-0">
                    <p>
                      <span className="common-info-text pr-5">
                        {vehicleInfo.category} / {vehicleInfo.type}
                      </span>
                      <br />
                      <span className="title font-weight-bold">
                        {vehicleTitle}
                      </span>
                    </p>
                  </div>
                  <div className="col-5 m-0 p-0">
                    <div className="ExtraChargeSummary__Rectangle">
                      <h6 className="my-2">Folio: {folio}</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-none d-lg-block col-12 p-0">
                <img
                  className="ExtraChargeSummary__Vehicle_image"
                  src={vehicleInfo.image}
                  alt={vehicleTitle}
                />
              </div>
            </div>
          </div>
          <div className="schedule-dates">
            {listOfExtraCharges.map(
              ({ id, price = 0, name = '', groupName = '', thumbnail }) => (
                <VehicleSectionChargeValue
                  key={id}
                  image={thumbnail}
                  title={groupName}
                  charge={name}
                  price={number2mxn(price)}
                />
              )
            )}
          </div>
          <div className="Detail__invoice-summary__amount ExtraChargeSummary__Invoice--container">
            <div className="common-info-text text-right text-uppercase">
              Subtotal: {number2mxn(subTotal)} <br />
              {discountAmount > 0 && (
                <span>Descuento: {number2mxn(discountAmount)}</span>
              )}
              <br />
              IVA: {number2mxn(IVAOfAmount)}
              <br />
              {hasRefund && (
                <div className="">
                  <ButtonPrime
                    label={'Reembolso: -' + number2mxn(customerRefund.amount)}
                    onClick={() => setShowRefundModal(true)}
                    severity="success"
                    text
                  />
                </div>
              )}
            </div>
            <br />
            <div className="common-info-text font-weight-bold text-uppercase d-flex justify-content-between">
              <div>PRECIO TOTAL</div>
            </div>

            <br />
            <div className="gray-amount-text text-uppercase text-right   ExtraChargeSummary__Invoice--amount-text">
              mxn {number2mxn(totalPrice <= 0 ? 0 : totalPrice)}
            </div>
            <div className="common-info-text font-weight-bold text-uppercase text-right">
              CARGOS EXTRA
            </div>
          </div>
          <div className="footer">
            {totalPrice > 0 && (
              <ButtonPrime
                label="Continuar sin pago"
                text
                onClick={handleWithoutPayment}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

ExtraChargeSummary.propTypes = {
  reservationInfo: PropTypes.shape({
    folio: PropTypes.string,
    naturalPerson: PropTypes.shape({
      fullName: PropTypes.string,
    }),
  }).isRequired,
  discountAmount: PropTypes.number,
  extraCharges: PropTypes.shape({
    listOfExtraCharges: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        price: PropTypes.number,
        name: PropTypes.string,
        groupName: PropTypes.string,
        thumbnail: PropTypes.string,
      })
    ),
    totalPrice: PropTypes.number,
  }).isRequired,
};

export default withForm({ config })(ExtraChargeSummary);
