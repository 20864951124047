import * as yup from 'yup';
import i18n from '@i18n';
import { push } from 'connected-react-router';
import { REQUIRED_MESSAGE } from 'utils/constants';

const initialTouched = {
  customer: true,
  customer_search: true,
};

const initialValues = {
  customer: '',
  customer_search: '',
};

const schema = yup.object().shape({
  customer: yup.string().required(REQUIRED_MESSAGE),
  customer_search: yup
    .string()
    .required(i18n('ERROR__COMMONS__REQUIRED_FIELD'))
    .min(3, i18n('ERROR__COMMONS__MIN_LENGTH', [3]))
    .test(
      'required-customer-search',
      i18n('ERROR__COMMONS__REQUIRED__ITEM_OF_LIST', ['un cliente']),
      function customerSearchTest(value) {
        return value && this.parent.customer;
      }
    ),
});

const config = {
  schema,
  initialTouched,
  initialValues,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  disableResponseDialog: false,
  withButtons: false,
  submitForm: (request) => push(`./${request.customer}/multi-contract-payment`),
  dialogs: {
    confirmation: {},
    response: {
      errorTitle: '',
      errorMessage: '',
      errorLabel: '',
    },
  },
};

export default config;
