import formatMultiPayments from '@payments/sectionsMethodsPayment/MultiPayments/formatter';
import formatInvoice from '@payments/sectionsMethodsPayment/InvoicePayment/formatter';

const withoutPayment = (reservation) => ({
  paymentForm: 'CASH',
  amount: 0,
  discount: 0,
  paymentType: 'EXTRA_CHARGES',
  reservation,
  withoutPayment: true,
  isTheLastPayment: true,
});

const formatCommons = (reservation) => ({
  reservation,
  paymentType: 'EXTRA_CHARGES',
});

const formatter = (values) => {
  if (values.totallyPaidWithRefund) return withoutPayment(values.reservationId);

  const request = formatMultiPayments(values);
  if (request.length == 1) request[0].amount = undefined;

  return {
    ...(values.wantInvoice ? formatInvoice(values) : {}),
    multiRequest: request,
    commons: formatCommons(values.reservationId),
  };
};

export default formatter;
