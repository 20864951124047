import { savePayment } from 'store/reservations/actions';
import {
  multiPaymentsInitialTouched,
  multiPaymentsInitialValues,
} from '@payments/sectionsMethodsPayment/MultiPayments/config';
import { schema } from './schema';
import formatter from './formatter';

const initialTouched = {
  ...multiPaymentsInitialTouched,
  reservationId: true,
};

const initialValues = {
  ...multiPaymentsInitialValues,
  reservationId: '',
};

const config = {
  schema,
  initialTouched,
  initialValues,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  withButtons: true,
  showCancel: false,
  formatter,
  submitForm: (request) => {
    const reservationUuid = request.commons?.reservation ?? request.reservation;
    return savePayment({
      request,
      redirect: `/returns/${reservationUuid}/refund-vehicle-parts?rejected=true`,
      inExtraCharges: true,
    });
  },
  btnSubmitLabel: 'Continuar',
  dialogs: {
    confirmation: {
      title: 'CONFIRMACIÓN DE REGISTRO',
      message:
        'Una vez cargada la información del cliente tendrás que editarlo manualmente\n¿Estás seguro que quieres continuar?',
      actionLabel: 'Sí, continuar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: '¡BIEN HECHO!',
      errorTitle: '¡UPS! ALGO PASÓ!',
      successMessage:
        'El cliente se agregó de forma exitosa al catálogo, si deseas realizar algún cambio tendrás que buscarlo y editarlo manualmente.',
      errorMessage:
        'Hubo un error mientras procesabamos la información, por favor intenta de nuevo.',
      successLabel: 'Ir a reservaciones',
      errorLabel: 'Reintentar',
      cancelLabel: 'Cancelar',
      successPath: '/customers/',
    },
  },
};

export default config;
