import { call, put, select } from 'redux-saga/effects';
import { showLoader, hideLoader } from 'store/commons/actions';
import {
  getInvoicesListSucceed,
  getInvoicesListFailed,
  getInvoiceDetailsSuccess,
  getInvoiceDetailsFailed,
  setListOfCRPs,
  getListOfCRPsSucceed,
  getListOfCRPsFailed,
  saveCRPSucceed,
  saveCRPFailed,
} from 'store/invoices/actions';
import {
  getInvoicesList,
  getInvoiceDetails,
  getListOfCRPs,
  saveCRP,
  getReInvoiceService,
} from 'services/invoices';
import { reInvoiceFailed, reInvoiceSucceed } from './actions';

export function* getInvoicesListSaga({ payload }) {
  try {
    yield put(showLoader());
    const data = yield call(getInvoicesList, payload.params);
    yield put(getInvoicesListSucceed(data));
  } catch (error) {
    yield put(getInvoicesListFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

function* refreshCRPs(invoiceUuid) {
  const params = yield select((state) => state.invoices.listOfCRP.params);
  const listOfCRPs = yield call(getListOfCRPs, invoiceUuid, params);
  yield put(setListOfCRPs(listOfCRPs));
}

export function* getListOfCRPsSaga({ payload }) {
  try {
    yield put(showLoader());
    const { invoiceUuid, params } = payload;
    const listOfCRPs = yield call(getListOfCRPs, invoiceUuid, params);

    yield put(getListOfCRPsSucceed(listOfCRPs));
  } catch (error) {
    yield put(getListOfCRPsFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getInvoiceDetailsSaga(action) {
  try {
    yield put(showLoader());
    const detailsData = yield call(getInvoiceDetails, action.payload);

    yield refreshCRPs(detailsData.uuid);

    yield put(getInvoiceDetailsSuccess(detailsData));
  } catch (error) {
    const hasNotFound = error.message.includes('No se encontró');
    yield put(getInvoiceDetailsFailed({ error, hasNotFound }));
  } finally {
    yield put(hideLoader());
  }
}

export function* saveCRPSaga(action) {
  try {
    yield put(showLoader());
    const { invoiceUuid, ...request } = action.payload;

    const data = yield call(saveCRP, invoiceUuid, request);

    yield refreshCRPs(invoiceUuid);

    yield put(saveCRPSucceed(data));
  } catch (error) {
    yield put(saveCRPFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* reInvoiceSaga(action) {
  try {
    yield put(showLoader());
    const reInvoiceData = yield call(getReInvoiceService, action.payload);
    const invoiceNumber = action.payload.serie + action.payload.folio;
    yield put(reInvoiceSucceed(reInvoiceData));
    const detailsData = yield call(getInvoiceDetails, invoiceNumber);
    yield put(getInvoiceDetailsSuccess(detailsData));
  } catch (error) {
    yield put(reInvoiceFailed(error));
  } finally {
    yield put(hideLoader());
  }
}
