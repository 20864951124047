import i18n from '@i18n';
import transformer from './transformer';
import formatter from './formatter';
import schema from './schema';
import {
  multiPaymentsInitialTouched,
  multiPaymentsInitialValues,
} from '../sectionsMethodsPayment/MultiPayments/config';
import { saveMultiPaymentContract } from 'store/payments/actions';

export const contractDetailTouched = [
  {
    charges: false,
    payment: false,
  },
];

export const contractDetailsValues = {
  contractUuid: '',
  contractNumber: '',
  charges: '',
  payment: '',
  balance: '',
  contractStatus: '',
};

const initialTouched = {
  contractsDetails: false,
  customerUuid: false,
  customer: false,
  amount: false,
  ...multiPaymentsInitialTouched,
};

const initialValues = {
  contractsDetails: [],
  customerUuid: '',
  customer: '',
  amount: '',
  ...multiPaymentsInitialValues,
};

const config = {
  schema,
  transformer,
  formatter,
  initialTouched,
  initialValues,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  withButtons: false,
  submitForm: (request) => saveMultiPaymentContract(request),
  dialogs: {
    confirmation: {
      title: i18n(
        `PAYMENTS_AND_CHARGES__PAYMENT_DETAIL__SAVE_PAYMENT__CONFIRMATION_TITLE`
      ),
      message: i18n(
        `PAYMENTS_AND_CHARGES__PAYMENT_DETAIL__SAVE_PAYMENT__CONFIRMATION_MESSAGE`
      ),
      actionLabel: i18n('COMMONS__YES_CONTINUE__TEXT'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
    },
    response: {
      successTitle: i18n(
        `PAYMENTS_AND_CHARGES__PAYMENT_DETAIL__SAVE_PAYMENT__RESPONSE_TITLE`
      ),
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      successMessage: i18n(
        `PAYMENTS_AND_CHARGES__PAYMENT_DETAIL__SAVE_PAYMENT__RESPONSE_MESSAGE`
      ),
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      successLabel: 'Ir a Detalles',
      errorLabel: i18n('COMMONS__TRY_AGAIN'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
      successPath: './',
    },
  },
};

export default config;
