import formatCard from '../CardMethod/formatter';
import formatCash from '../CashMethod/formatter';
import formatDeposit from '../DepositMethod/formatter';
import formatTransfer from '../TransferMethod/formatter';

const formatMultiPayments = (values) => {
  const request = [];
  const multiplesPayment = [];
  if (values.diffMethods) {
    if (values.amountCard_0) multiplesPayment.push(formatCard(values, 0));
    if (values.amountCard_1) multiplesPayment.push(formatCard(values, 1));
    if (values.amountCard_2) multiplesPayment.push(formatCard(values, 2));
    if (values.amountDeposit) multiplesPayment.push(formatDeposit(values));
    if (values.amountTransfer) multiplesPayment.push(formatTransfer(values));
    if (values.amountCash) multiplesPayment.push(formatCash(values));
    for (let i = 0; i < multiplesPayment.length; i++) {
      multiplesPayment[i].isTheLastPayment = false;
      multiplesPayment[i].discount = 0;
      request.push(multiplesPayment[i]);
    }
  } else {
    switch (values.methodSelected) {
      case 'card_0':
      case 'card_1':
      case 'card_2':
      case 'card':
        request.push(formatCard(values, values.methodSelected.split('_')[1]));
        break;
      case 'deposit':
        request.push(formatDeposit(values));
        break;
      case 'transfer':
        request.push(formatTransfer(values));
        break;
      case 'cash':
        request.push(formatCash(values));
        break;
      default:
        break;
    }
  }
  if (request.length > 0) {
    request[request.length - 1].totalAmount = Number(values.amount);
    request[request.length - 1].discount = values.discount;
    request[request.length - 1].isTheLastPayment = true;
  }
  return request;
};

export default formatMultiPayments;
