import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { ProgressIndicator, withForm } from '@casanova/casanova-common';
import { PreventChange } from 'components';

import i18n from '@i18n';
import { getQuotePath } from 'utils/navigation';
import { isExtendOfContractFlow } from '@reservations/commons/utils';

import { scrollTop } from 'utils/screen';
import config from './config';
import './Payment.scss';
import ResponseDialog from 'components/ResponseDialog';
import { MultiPayments } from '../../Payments/sectionsMethodsPayment';

const Payment = ({
  maxSteps,
  step,
  title,
  subtitle,
  fetchCatalog,
  reservationId,
  getCustomer,
  quoteValues,
  submitted,
  onUpdateForm,
  setNewInvoicePayment,
  setHasAddedBillingDetailsOfPayment,
  customerJuridical,
  priceVehicleRent,
  withoutPayment,
  responseDialogReservationVehicle,
  extendContract,
  history,
  customerFullName,
  folio,
}) => {
  if (!quoteValues.quoteId) {
    window.location.href = getQuotePath('list');
  }

  const isJuridical = Boolean(customerJuridical);

  const { touched, setFieldValue } = useFormikContext();

  const isExtendContract = useMemo(() => isExtendOfContractFlow(), [
    isExtendOfContractFlow(),
  ]);

  const currentPlan = quoteValues.fullVehicle.vehicleLinePrices.filter(
    (plan) => plan.uuid === quoteValues.vehicleLinePrice
  );
  const { price, period } = currentPlan[0];
  const toAccount = extendContract.daysAdded * (price / period);
  const amount = isExtendContract ? toAccount : priceVehicleRent;

  const billDetails = {
    customerFullName,
    folio,
    concept: isExtendContract ? 'Extensión de contrato' : 'Contrato de renta',
  };

  useEffect(() => {
    setFieldValue('needHoldback', !isExtendContract);
  }, [isExtendContract]);

  useEffect(() => {
    getCustomer(quoteValues.customer);
    setFieldValue('reservationId', reservationId);
  }, []);

  useEffect(() => {
    scrollTop();
    // Reset added billing details flag
    setHasAddedBillingDetailsOfPayment(false);

    return () => {
      setNewInvoicePayment(false);
      setHasAddedBillingDetailsOfPayment(false);
    };
  }, []);

  useEffect(() => {
    setFieldValue('amount', amount.toFixed(2));
  }, [amount]);

  const handleOnCloseReservationVehicle = () => {
    history.push('/daily-rent-contracts/contract');
  };

  return (
    <PreventChange preventChange={!submitted && !withoutPayment}>
      <ResponseDialog
        open={responseDialogReservationVehicle.open}
        success={responseDialogReservationVehicle.success}
        errorTitle={i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE')}
        errorLabel={i18n('COMMONS__CLOSE__TEXT')}
        errorMessage={responseDialogReservationVehicle.message}
        errorCode={responseDialogReservationVehicle.errorCode}
        onError={handleOnCloseReservationVehicle}
        onClose={handleOnCloseReservationVehicle}
      />
      <div className="payment-page">
        {!isExtendContract && (
          <>
            <div className="row">
              <div className="col-12 p-0 d-flex align-items-center">
                <ProgressIndicator
                  size={120}
                  stroke={['#e2e2e2', '#007aff']}
                  strokeWidth={6}
                  progress={(step / maxSteps) * 100}
                  label={`${step} de ${maxSteps}`}
                />
                <div className="ml-4">
                  <h3 className="text-primary">{title}</h3>
                  <p>{subtitle}</p>
                </div>
              </div>
            </div>
            <hr className="mt-4" width="100%" />
          </>
        )}
        <div className="payment-page__main">
          <MultiPayments
            touched={touched}
            onUpdateForm={onUpdateForm}
            uuidCustomer={quoteValues.customer}
            fetchCatalog={fetchCatalog}
            isJuridical={isJuridical}
            billDetails={billDetails}
          />
        </div>
      </div>
    </PreventChange>
  );
};

Payment.propTypes = {
  maxSteps: PropTypes.number,
  step: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  setNewInvoicePayment: PropTypes.func,
};

Payment.defaultProps = {
  maxSteps: 2,
  step: 2,
  title: i18n('RESERVATIONS__STEPPER__TITLE'),
  subtitle: i18n('RESERVATIONS__STEPPER__SUBTITLE'),
};

export default withForm({ config })(Payment);
