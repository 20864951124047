import React, { useEffect, useCallback, useState } from 'react';
import { Formik } from 'formik';
import { Layout, Icons, Button } from '@casanova/casanova-common';
import { ActionModal } from '@casanova/casanova-common/lib/Modal';
import PreventChange from 'components/PreventChange';
import RoleVerifier from 'components/RoleVerifier';
import Breadcrumb from 'containers/Breadcrumb';
import { VehicleForm, PurchaseForm, AdaptationForm } from '@vehicles/sections';
import ConversionForm from '@vehicles/sections/ConversionForm/ConversionForm';
import PermissionForm from '@vehicles/sections/PermissionForm/PermissionForm';
import {
  CREATE_VEHICLE_ADAPTATION,
  CREATE_VEHICLE_CONVERSION,
} from 'utils/roles/permissions';
import { getDescriptionByValue } from 'utils/catalogs';
import { VehicleFullSchema, initialTouched } from './vehicleSchema';

const VehicleEditor = ({
  history,
  fetchCatalog,
  commonsFetchCatalog,
  listBrands,
  brandSelected,
  vehicle,
  confirmationMessage,
  resultMessage,
  openConfirmationMessage,
  closeConfirmationMessage,
  closeResultMessage,
  storeVehicleData,
  saveVehicleRequested,
  loader,
  cleanVehicleData,
}) => {
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    commonsFetchCatalog({
      catalogPath: 'pricequotes',
      catalogId: 'catalogs/business-areas',
    });
    fetchCatalog('vehicle-purpose');
    fetchCatalog('brand');
    fetchCatalog('model');
    fetchCatalog('color');
    fetchCatalog('vehicle-type');
    fetchCatalog('payment-type');
    fetchCatalog('provider');
    fetchCatalog('origin');
    fetchCatalog('fuel-type');
    fetchCatalog('owner');
    fetchCatalog('adaptation-origin');
    fetchCatalog('adaptation-type');
    fetchCatalog('conversion-type');
    fetchCatalog('permission-type');
    fetchCatalog('transmission-type');
    fetchCatalog('seat-type');
    fetchCatalog('conversion-origin');
    cleanVehicleData();
  }, [fetchCatalog, cleanVehicleData]);

  const handleSubmitEvent = useCallback(
    (validate, values) => {
      validate().then((errors) => {
        if (Object.keys(errors).length === 0 && errors.constructor === Object) {
          const brandName = getDescriptionByValue(listBrands, values.brand);
          const lineName = getDescriptionByValue(
            brandSelected.lines,
            values.line
          );
          const vehicleName = `${brandName.label} ${lineName.label} ${values.modelYear}`;
          storeVehicleData({ vehicle: values, vehicleName });
          openConfirmationMessage();
        }
      });
    },
    [openConfirmationMessage, storeVehicleData, brandSelected.lines, listBrands]
  );

  const handleConfirmAction = useCallback(() => {
    saveVehicleRequested(vehicle);
  }, [saveVehicleRequested, vehicle]);

  const handleSuccessAction = useCallback(() => {
    history.push('/vehicle');
  }, [history]);

  return (
    <>
      <ActionModal
        open={resultMessage.open}
        icon={
          resultMessage.success ? <Icons.IcoCarOk /> : <Icons.IcoCarError />
        }
        onClose={closeResultMessage}
        onAction={
          resultMessage.success ? handleSuccessAction : handleConfirmAction
        }
        title={resultMessage.title}
        actionLabel={resultMessage.actionLabel}
        message={resultMessage.message}
        modalType="confirmation"
        code={resultMessage.errorCode}
        closeButton
      />
      <ActionModal
        open={confirmationMessage}
        icon={<Icons.StatusWarning />}
        onClose={closeConfirmationMessage}
        onAction={handleConfirmAction}
        title="CONFIRMACIÓN DE CARGA"
        actionLabel="Sí, continuar"
        closeButton
        message="Una vez cargado el vehículo tendrás que editarlo manualmente ¿Estás seguro que quieres continuar?"
      />
      <Layout.Title label={<Breadcrumb />} />
      <Layout.Body showLoader={loader}>
        <div className="section">
          <legend className="section__title">INFORMACIÓN GENERAL</legend>
        </div>
        <hr style={{ marginTop: '0' }} width="100%" />
        <Formik
          initialValues={{ hasAdaptation: false, hasConversion: false }}
          enableReinitialize
          validateOnBlur
          validateOnChange
          validationSchema={VehicleFullSchema}
          initialTouched={submitted ? initialTouched : {}}
        >
          {({ values, validateForm, dirty }) => (
            <PreventChange preventChange={dirty && !submitted}>
              <div className="form_container">
                <VehicleForm />
                <br />
                <RoleVerifier identifier={CREATE_VEHICLE_ADAPTATION}>
                  <AdaptationForm />
                  <br />
                </RoleVerifier>
                <RoleVerifier identifier={CREATE_VEHICLE_CONVERSION}>
                  <ConversionForm />
                  <br />
                </RoleVerifier>
                <PurchaseForm />
                <br />
                <RoleVerifier identifier={CREATE_VEHICLE_CONVERSION}>
                  <PermissionForm />
                </RoleVerifier>
                <div className="row">
                  <div className="col-md-8">
                    <span style={{ marginRight: '1rem' }}>
                      <Icons.Coment height="2rem" />
                    </span>
                    * Campos obligatorios
                  </div>
                  <div className="col-md-4 text-right">
                    <div className="row">
                      <div className="col-md-6">
                        <Button
                          onClick={() => history.push('/vehicle')}
                          outline
                          block
                        >
                          Cancelar
                        </Button>
                      </div>
                      <div className="col-md-6">
                        <Button
                          type="submit"
                          block
                          onClick={() => {
                            setSubmitted(true);
                            handleSubmitEvent(validateForm, values);
                          }}
                        >
                          Guardar
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PreventChange>
          )}
        </Formik>
      </Layout.Body>
    </>
  );
};

export default VehicleEditor;
