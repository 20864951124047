import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { useFormikContext } from 'formik';
import {
  Switch,
  MaskedInput as Input,
  Icons,
  TextAreaInput as TextArea,
  ConnectedSelect,
  PlusButton,
  CardRow,
  ActionModal,
  Select as NormalSelect,
} from '@casanova/casanova-common';
import { ConnectedSelect as Select } from 'components';
import i18n from '@i18n';
import {
  alphaMask,
  alphaEmailMask,
  textAreaRegex,
  numericMask,
} from 'utils/masks';
import {
  transformIdOfList,
  transformForSelect,
} from '@casanova/casanova-common/lib/utils/transformHelpers';
import { INVOICE_CARD_ROW_FIELDS } from './constants';
import './InvoicePayment.scss';
import useMultiPayment from '../hooks/useMultiPayment';
// import { getInvoice } from '../../../../services/reservations/index';

const InvoicePayment = ({
  quote,
  reservationId,
  email,
  invoiceElements,
  setNewInvoicePayment,
  newInvoicePayment,
  getSuburbsByZipCode,
  zipCode,
  catalogsCfdiUsesFiscalRegime,
  catalogsCfdiFiscalRegimeNaturalPerson,
  catalogsCfdiPaymentFrom,
  catalogsCfdiPaymentMethod,
}) => {
  const { fetchCatalog, isJuridical } = useMultiPayment();
  const [invoiceSelected, setInvoiceSelected] = useState('');
  const [selectedInvoiceData, setSelectedInvoiceData] = useState({});
  const [invoiceDeleted, setInvoiceDeleted] = useState('');
  const [invoices, setInvoices] = useState([]);
  const [showDeleteInvoiceModal, setShowDeleteInvoiceModal] = useState(false);
  const [invoiceToDelete, setInvoiceToDelete] = useState(null);
  const { values, setFieldValue, errors } = useFormikContext();
  const zipCodeExist = zipCode?.list?.length >= 1;

  const resetInvoiceData = () => {
    const RESET_FIELDS = [
      'invoiceSelected',
      'invoiceSocialReason',
      'invoiceRfc',
      'invoiceCfdiUse',
      'invoicePayForm',
      'invoicePayMethod',
      'observationsInvoice',
      'emailInvoice',
      'invoiceTaxRegime',
      'invoiceZipCode',
      'CodigoSociedad',
      'CodigoSucursal',
      'sapReponse',
    ];

    setInvoiceSelected('');
    setSelectedInvoiceData({});

    RESET_FIELDS.forEach((field) => {
      setFieldValue(field, '');
    });
  };

  useEffect(() => {
    if (values.invoiceTaxRegime !== '') {
      fetchCatalog({
        catalogId: 'catalogs/cfdi-uses/fiscal-regime',
        params: {
          uuid: values.invoiceTaxRegime,
          isJuridical,
        },
        catalogPath: 'pricequotes',
      });
    }
  }, [values.invoiceTaxRegime]);

  const handleOnSelectInvoice = useCallback(
    (uuid) => {
      setFieldValue('isAdding', false);
      setNewInvoicePayment(false);

      if (invoiceSelected === uuid) {
        resetInvoiceData();
      } else {
        setInvoiceSelected(uuid);
        setFieldValue('invoiceSelected', uuid);

        const invoiceData = invoices.find((invoice) => invoice.uuid === uuid);
        if (invoiceData) {
          setFieldValue('invoiceSocialReason', invoiceData.name);
          setFieldValue('invoiceRfc', invoiceData.rfc);
          setFieldValue('invoiceCfdiUse', invoiceData.cfdiUse);
          setFieldValue('invoicePayForm', invoiceData.paymentForm);
          setFieldValue('invoicePayMethod', invoiceData.paymentMethod);
          setFieldValue('invoiceTaxRegime', invoiceData.fiscalRegime);
          setFieldValue('invoiceZipCode', invoiceData.taxZipCode);
          getSuburbsByZipCode(invoiceData.taxZipCode);
          setFieldValue('emailInvoice', email);
          setFieldValue('observationsInvoice', invoiceData.observationsInvoice);
          setFieldValue('quote', invoiceData.quote);
          setSelectedInvoiceData(invoiceData);
        }
      }
    },
    [invoiceSelected, setFieldValue, invoices, setNewInvoicePayment]
  );

  const handleGetSuburbs = useCallback(
    (e) => {
      const { value } = e.target;
      if (value && value.length >= 5) {
        getSuburbsByZipCode(value);
      }
    },
    [getSuburbsByZipCode, setFieldValue]
  );

  const handleDeleteInfo = useCallback(
    (uuid) => {
      setInvoiceDeleted(uuid);
      setFieldValue('invoiceDeleted', uuid);
      const remainingInvoices = invoices.filter(
        (invoice) => invoice.uuid !== uuid
      );
      setInvoices(remainingInvoices);
      setFieldValue('invoices', remainingInvoices);
    },
    [invoiceDeleted, invoices]
  );

  const handleCloseDeleteModal = useCallback(() => {
    setShowDeleteInvoiceModal(false);
    setInvoiceToDelete(null);
  }, []);

  const handleActionDeleteModal = useCallback(() => {
    if (invoiceToDelete) {
      handleDeleteInfo(invoiceToDelete.uuid);
      if (
        invoiceSelected &&
        selectedInvoiceData?.uuid === invoiceToDelete?.uuid
      ) {
        resetInvoiceData();
      }
    }
    setInvoiceToDelete(null);
    setShowDeleteInvoiceModal(false);
  }, [invoiceToDelete, handleDeleteInfo, selectedInvoiceData, invoiceSelected]);

  const handleSwitch = useCallback(() => {
    setFieldValue('isAdding', false);
    setNewInvoicePayment(false);
    setFieldValue('invoiceSelected', '');
    setFieldValue('invoiceDataToSend', invoiceDataToSend);
    setInvoiceSelected('');
  }, [setFieldValue, setNewInvoicePayment]);

  const handleAddInvoice = useCallback(() => {
    if (invoiceSelected) resetInvoiceData();

    setFieldValue('isAdding', true);
    setNewInvoicePayment(true);
  }, [setFieldValue, invoiceSelected, setNewInvoicePayment]);

  const showNewInvoicePayForm = useMemo(
    () =>
      (values.isAdding && newInvoicePayment) ||
      invoices.length === 0 ||
      !_isEmpty(invoiceSelected),
    [values.isAdding, invoices, invoiceSelected, newInvoicePayment]
  );

  useEffect(() => {
    setInvoices(invoiceElements);
    setFieldValue('invoices', invoiceElements);
  }, [invoiceElements, setFieldValue]);

  useEffect(() => {
    setFieldValue('quote', quote);
  }, [quote, setFieldValue]);

  useEffect(() => {
    setFieldValue('reservationId', reservationId);
  }, [reservationId, setFieldValue]);

  useEffect(() => {
    setFieldValue('withBillingInfo', invoiceElements.length > 0);
  }, [invoiceElements, setFieldValue]);

  useEffect(() => {
    setFieldValue('invoiceZipCode', values.zipCode);
  }, [values.zipCode, setFieldValue]);

  useEffect(() => {
    setFieldValue('observationsInvoice', values.observationsInvoice);
  }, [values.observationsInvoice, setFieldValue]);

  useEffect(() => {
    setFieldValue('invoiceZipCodeExist', !zipCodeExist);
  }, [zipCodeExist, setFieldValue, values.invoiceZipCodeExist]);

  useEffect(() => {
    setFieldValue('taxRegime', values.postalCode);
  }, [values.postalCode, setFieldValue]);

  useEffect(() => {
    setFieldValue('CodigoSucursal', values.CodigoSucursal);
  }, [values.CodigoSucursal, setFieldValue]);

  useEffect(() => {
    setFieldValue('CodigoSociedad', values.CodigoSociedad);
  }, [values.CodigoSociedad, setFieldValue]);

  useEffect(() => {
    if (values.isAdding && values.wantInvoice) {
      setFieldValue(`invoiceTaxRegime`, '');
    }
  }, [values.invoiceRfc, setFieldValue, values.isAdding, values.wantInvoice]);

  useEffect(() => {
    if (values.isAdding && values.wantInvoice) {
      setFieldValue(`invoiceCfdiUse`, '');
    }
  }, [values.invoiceTaxRegime, values.isAdding, values.wantInvoice]);

  const clientType = [{ type: 'Fisica' }, { type: 'Moral' }];

  const formasDePago = {
    '5b5b4503-7f4a-4cef-a5b9-462679f30505': {
      TipoFormaPago: 'Efectivo',
      CodigoFormaPago: 'EF1',
    },
    'f392458a-1c31-4090-84e9-ea99249f029f': {
      TipoFormaPago: 'TarjetaBancaria',
      CodigoFormaPago: 'TJ1',
    },
    'e895b335-1578-46f9-9048-edfb6f4758b7': {
      TipoFormaPago: 'TarjetaBancaria',
      CodigoFormaPago: 'TJ1',
    },
    '6e435293-4507-476d-a40f-dff9c0f22694': {
      TipoFormaPago: 'TransferenciaBancaria',
      CodigoFormaPago: 'TR1',
      ReferenciaTransferencia: '0001',
    },
  };

  let invoiceDataToSend = {
    CodigoSociedad: '',
    CodigoSucursal: '',
    ReversationID: reservationId,
    Factura: {
      FolioUID: '',
      CodigoSocio: quote.values.customer,
      FechaCreacion: '',
      Comentarios: values.observationsInvoice,
      RFCSocio: selectedInvoiceData.rfc,
      NombreSocio: selectedInvoiceData.name,
      CodigoPostal: selectedInvoiceData.taxZipCode,
      Descuento: 0,
      TotalDocumento: 0,
      UsoCFDIFiscal: invoiceElements.cfdiUse,
      RegimenFiscalReceptor: invoiceElements.fiscalRegime,
      MetodoPagoFiscal: invoiceElements.paymentMethod,
      FormaPagoFiscal: invoiceElements.paymentForm,
      Partidas: [],
      FormasPago: [
        {
          TipoFormaPago: 'Efectivo',
          CodigoFormaPago: 'EF1',
          ReferenciaTransferencia: '0001',
          Monto: 0,
        },
      ],
    },
  };

  const handleChangeClientType = (e) => {
    setFieldValue('ClienteType', e.target.value);
    setFieldValue(
      'CodigoSociedad',
      clientType.find((el) => el.type === e.target.value)?.type
    );
  };

  const changeValue = (e, type) => {
    const forms = {
      regimen_fiscal: (uuid) => {
        setFieldValue(
          'RegimenFiscalReceptor',
          catalogsCfdiFiscalRegimeNaturalPerson.find((e) => e.uuid === uuid)
            ?.code
        );
      },
      uso_cfdi: (uuid) => {
        setFieldValue(
          'UsoCFDIFiscal',
          catalogsCfdiUsesFiscalRegime.find((e) => e.uuid === uuid)?.code
        );
      },
      forma_pago: (uuid) => {
        setFieldValue(
          'FormaPagoFiscal',
          catalogsCfdiPaymentFrom.list.find((e) => e.uuid === uuid)?.code
        );
        setFieldValue(
          'FormasPago',
          formasDePago[uuid] ||
            formasDePago['f392458a-1c31-4090-84e9-ea99249f029f']
        );
      },
      metodo_pago: (uuid) => {
        setFieldValue(
          'MetodoPagoFiscal',
          catalogsCfdiPaymentMethod.list.find((e) => e.uuid === uuid)?.code
        );
      },
    };
    forms[type](e.target.value);
  };

  return (
    <>
      <ActionModal
        open={showDeleteInvoiceModal}
        icon={<Icons.StatusWarning />}
        closeButton
        onClose={handleCloseDeleteModal}
        onAction={handleActionDeleteModal}
        modalType="action"
        title={i18n('RESERVATIONS__PAYMENT__DELETE_INVOICE_DATA__MODAL__TITLE')}
        message={i18n(
          'RESERVATIONS__PAYMENT__DELETE_INVOICE_DATA__MODAL__MESSAGE'
        )}
        actionLabel={i18n('COMMONS__ACCEPT__TEXT')}
        closeLabel={i18n('COMMONS__CANCEL__TEXT')}
      />

      <div className="invoice-payment">
        <div className="disclaimer mt-3">
          <span className="disclaimer-title">
            {i18n('RESERVATIONS__HOLDBACK__INVOICE_DISCLAIMER__TITLE')}
          </span>
          <span className="disclaimer-body">
            <span>
              {i18n('RESERVATIONS__HOLDBACK__INVOICE_DISCLAIMER__DESCRIPTION')}
            </span>
          </span>
        </div>
        <div className="row mt-4">
          <div className="col-md-5 switch-inverted">
            <label htmlFor="wantInvoice">
              {i18n('RESERVATIONS__PAYMENT__INVOICE__ADD')}
            </label>
            <Switch name="wantInvoice" onClick={handleSwitch} />
          </div>
        </div>
        {values.wantInvoice && (
          <>
            {invoices.map((invoice) => {
              const { name, rfc } = invoice;
              const invoiceName = `${name}_${rfc}`;

              const handleDeleteInvoice = () => {
                setInvoiceToDelete(invoice);
                setShowDeleteInvoiceModal(true);
              };

              const items = Object.entries(invoice)
                .filter(([key]) => INVOICE_CARD_ROW_FIELDS[key])
                .map(([key, value]) => ({
                  value,
                  ...INVOICE_CARD_ROW_FIELDS[key],
                }));

              const itemsWithId = transformIdOfList(items);
              const isSelected = invoiceSelected === invoice.uuid;

              return (
                <CardRow
                  key={invoice.uuid}
                  className="container-fluid pr-5 py-5"
                  rowClassName="py-5"
                  items={itemsWithId}
                  withRadio
                  name={invoiceName}
                  onChange={() => handleOnSelectInvoice(invoice.uuid)}
                  direction="left"
                  check={isSelected}
                  RightRender={() => (
                    <>
                      {invoices.length === 3 && (
                        <div className="pointer">
                          <Icons.DeleteTrash
                            width="1.5rem"
                            height="1.5rem"
                            onClick={handleDeleteInvoice}
                          />
                        </div>
                      )}
                    </>
                  )}
                />
              );
            })}

            {invoices.length > 0 && invoices.length < 3 && (
              <div className="row">
                <div className="col" />
                <div className="d-flex justify-content-end align-items-center col-12 col-md-7">
                  <PlusButton
                    textLeft="Nuevos datos de facturación"
                    color="dark"
                    onClick={handleAddInvoice}
                  />
                </div>
              </div>
            )}

            <div className="row">
              {showNewInvoicePayForm && (
                <>
                  <div className="col-6">
                    <Input
                      // mask={alphaMaskWithSpaces(230)}
                      maskPlaceholder=""
                      label="Nombre o razón social*"
                      placeholder="Nombre o razón social*"
                      name="invoiceSocialReason"
                      disabled={Boolean(selectedInvoiceData.name)}
                    />
                  </div>
                  <div className="col-6">
                    <Input
                      mask={alphaMask(13)}
                      maskPlaceholder=""
                      label="RFC*"
                      placeholder="RFC*"
                      name="invoiceRfc"
                      disabled={Boolean(selectedInvoiceData.rfc)}
                    />
                  </div>
                  <div className="col-6">
                    <Select
                      label="Regimen fiscal*"
                      name="invoiceTaxRegime"
                      options={
                        values.invoiceRfc.length == 13
                          ? 'commons.catalogsCfdiFiscalRegimeNaturalPerson'
                          : 'commons.catalogsCfdiFiscalRegimeJuridicalPerson'
                      }
                      onChange={(event) => changeValue(event, 'regimen_fiscal')}
                    />
                  </div>
                  {values.invoiceTaxRegime.length !== 0 && (
                    <div className="col-6">
                      <ConnectedSelect
                        label="Uso CFDI*"
                        name="invoiceCfdiUse"
                        options="commons.catalogsCfdiUsesFiscalRegime"
                        onChange={(event) => changeValue(event, 'uso_cfdi')}
                      />
                    </div>
                  )}
                  <div className="col-6">
                    <ConnectedSelect
                      label="Forma de pago*"
                      name="invoicePayForm"
                      options="commons.catalogsPaymentForms"
                      onChange={(event) => changeValue(event, 'forma_pago')}
                    />
                  </div>
                  <div className="col-6">
                    <ConnectedSelect
                      label="Método de pago*"
                      name="invoicePayMethod"
                      options="commons.catalogsPaymentMethods"
                      onChange={(event) => changeValue(event, 'metodo_pago')}
                    />
                  </div>
                  <div className="col-6">
                    <Input
                      mask={alphaEmailMask(50)}
                      maskPlaceholder=""
                      label="Correo facturación"
                      placeholder="Correo facturación"
                      name="emailInvoice"
                    />
                  </div>
                  <div className="col-6">
                    <Input
                      mask={numericMask(5)}
                      maskPlaceholder=""
                      label="Código Postal*"
                      placeholder="Código Postal*"
                      name="invoiceZipCode"
                      onChange={handleGetSuburbs}
                    />
                  </div>
                  <div className="col-6">
                    <NormalSelect
                      label="Tipo de cliente"
                      name="clientType"
                      options={transformForSelect(clientType, 'type', 'type')}
                      onChange={handleChangeClientType}
                      className="m-0 select"
                      disabled={false}
                    />
                  </div>

                  <div className="col-12">
                    <TextArea
                      label="Observaciones"
                      placeholder="Observaciones"
                      name="observationsInvoice"
                      regex={textAreaRegex}
                      className="invoice-payment__invoice-row__text-area"
                      maxLength={200}
                    />
                  </div>
                </>
              )}
            </div>

            {errors && errors.invoiceSelected && (
              <div className="col-form-error text-danger">
                {errors.invoiceSelected}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

InvoicePayment.propTypes = {
  invoiceElements: PropTypes.arrayOf(PropTypes.object),
  email: PropTypes.string,
  setNewInvoicePayment: PropTypes.func,
  newInvoicePayment: PropTypes.bool,
};

InvoicePayment.defaultProps = {
  invoiceElements: [],
  email: '',
};

export default InvoicePayment;
