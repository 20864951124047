import _get from 'lodash/get';
import store from 'store';

const cardFields = [
  'authorization',
  'cardType',
  'hasHolderName',
  'cardNumber',
  'reference',
  'movementDate',
  'movementHour',
  'voucher',
  'accountStatus',
  'bank',
  'amountCard',
];

const formatCard = (values, index = 0) => {
  const { diffMethods, amount, paymentDate, paymentTime } = values;

  const extractedValues = cardFields.reduce((acc, field) => {
    acc[field] = values[`${field}_${index}`];
    return acc;
  }, {});

  const {
    cardType,
    bank,
    cardNumber,
    reference,
    authorization,
    voucher,
    amountCard,
    hasHolderName,
    accountStatus,
  } = extractedValues;

  const cardTypes = _get(
    store.getState(),
    'commons.catalogsBankCardTypes.list',
    []
  );

  const fullCardType = cardTypes.find((card) => card.uuid === cardType);

  const cardDetails = {
    bank,
    bankAuthorization: authorization,
    bankCardType: cardType,
    cardHasCustomerName: hasHolderName,
    cardNumber,
    referenceNumber: reference,
  };

  const paymentForm = `${fullCardType?.identifier}_CARD`;

  return {
    paymentForm,
    cardDetails,
    paymentDate,
    paymentTime,
    files: {
      paymentProof: voucher,
      accountStatus: !hasHolderName ? accountStatus : null,
    },
    amount: diffMethods ? Number(amountCard) : Number(amount),
  };
};

export default formatCard;
