import { CUSTOMER_TYPES, TENDER_TYPES } from '@customers/common/constants';

export const VALUES_LIST = [
  { value: CUSTOMER_TYPES.FISIC, label: 'Física' },
  { value: CUSTOMER_TYPES.JURIDICAL, label: 'Moral' },
  { value: CUSTOMER_TYPES.TENDER, label: 'Licitación' },
];

export const VALUES_LIST_TENDER = [
  { value: TENDER_TYPES.FISIC, label: 'Física' },
  { value: TENDER_TYPES.JURIDICAL, label: 'Moral' },
];

export const VERIFICATION_TYPES = {
  CASANOVA_INE: 'CASANOVA_INE',
  CASANOVA_LICENSE: 'CASANOVA_LICENSE',
};
