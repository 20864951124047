import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import { Icons } from '@casanova/casanova-common';
import { Card } from 'components';

import './PaymentType.scss';

const PaymentType = ({
  title,
  subtitle,
  right,
  onSelect,
  initialValues,
  hasUpdateDifMetods,
  children,
  name,
  withoutContent,
  noContainer = false,
}) => {
  const { values, setFieldValue, setValues } = useFormikContext();
  const [selected, setSelected] = useState(values.methodSelected === name);

  const handleOnSelect = useCallback(() => {
    const selectedMethods = values.selectedMethods || [];
    if (selected) {
      setValues((current) => ({
        ...current,
        ...initialValues,
        methodSelected: '',
        selectedMethods: selectedMethods.filter((method) => method !== name),
      }));
      setSelected(false);
    } else {
      selectedMethods.push(name);
      setFieldValue('methodSelected', name);
      setFieldValue('selectedMethods', selectedMethods);
    }
    if (onSelect) {
      onSelect(name);
    }
  }, [name, onSelect, setFieldValue, selected, values.selectedMethods]);

  useEffect(() => {
    if (hasUpdateDifMetods) {
      if (values.methodSelected === name) {
        if (selected) {
          setSelected(false);
        } else {
          setSelected(true);
        }
      }
    } else {
      setSelected(values.methodSelected === name);
      if (values.methodSelected === name) {
        setFieldValue('selectedMethods', [name]);
      }
    }
  }, [values.methodSelected]);

  return (
    <Card noStatus padding="px-5">
      <div className="payment-type">
        <div className="payment-type__header">
          <div
            className={classNames(
              'd-flex p-0',
              !noContainer && 'container-fluid'
            )}
          >
            <div
              className="payment-type__header__icon mr-4"
              onClick={handleOnSelect}
              onKeyDown={handleOnSelect}
              tabIndex={0}
              role="button"
            >
              {selected ? (
                <Icons.CheckAvailable width="2.25rem" height="2.25rem" />
              ) : (
                <Icons.CheckUnavailable width="2.25rem" height="2.25rem" />
              )}
            </div>
            <div className="payment-type__header__titles">
              <div className="common-info-text font-weight-bolder">{title}</div>
              <div className="common-info-text">{subtitle}</div>
            </div>
          </div>
          <div className="payment-type__header__right">{right}</div>
        </div>
        {selected && !withoutContent && (
          <div className="payment-type__content">{children}</div>
        )}
      </div>
    </Card>
  );
};

PaymentType.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onSelect: PropTypes.func,
  hasUpdateDifMetods: PropTypes.bool,
  right: PropTypes.node,
  name: PropTypes.string,
  withoutContent: PropTypes.bool,
  noContainer: PropTypes.bool,
};

PaymentType.defaultProps = {
  title: '',
  subtitle: '',
  name: '',
  withoutContent: false,
};

export default PaymentType;
