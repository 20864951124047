import moment from 'moment';
import { YYYY_MM_DD } from '@casanova/casanova-common/lib/utils/date';

export { default as CardMethod } from './CardMethod';
export { default as CashMethod } from './CashMethod';
export { default as DepositMethod } from './DepositMethod';
export { default as TransferMethod } from './TransferMethod';
export { default as InvoicePayment } from './InvoicePayment';
export { default as MultiPayments } from './MultiPayments';
export { default as ClientCards } from './ClientCards';
export { default as Holdback } from './Holdback';

export const obtenerHoraActual = () => {
  return moment().format('HH:mm:ss');
};

export const obtenerFechaActual = () => {
  return moment().format(YYYY_MM_DD);
};
