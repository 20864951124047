export const CONTRACTS_MODULE = 'CONTRACTS_MODULE';

export const NEW_CONTRACT = 'CREATE_VIRTUAL_CONTRACT';
export const VIEW_CONTRACTS = 'VIEW_VIRTUAL_CONTRACTS';
export const CONTRACT_DETAIL = 'VIRTUAL_CONTRACT_DETAIL';
export const ADD_REMOVE_VEHICLE_VIRTUAL_CONTRACT =
  'ADD_REMOVE_VEHICLE_VIRTUAL_CONTRACT';
export const CHANCE_VEHICLE_VIRTUAL_CONTRACT =
  'CHANCE_VEHICLE_VIRTUAL_CONTRACT';
export const EDIT_CONTRACT =
  ADD_REMOVE_VEHICLE_VIRTUAL_CONTRACT || CHANCE_VEHICLE_VIRTUAL_CONTRACT;

export const CREATE_RESERVATION = 'CREATE_RESERVATION';
export const VIEW_REPORTS_ACCOUNTING = 'VIEW_REPORTS_ACCOUNTING';
export const EDIT_VIRTUAL_CONTRACT = 'EDIT_VIRTUAL_CONTRACT';
