import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withLayout } from '@casanova/casanova-common';
import {
  getMultiPaymentContract,
  cleanMultiContractDetails,
  setCancelMovement,
  closeResponseDialog,
} from 'store/payments/actions';
import MultiContractPayment from './MultiContractPayment';
import { getDetail } from 'store/customers/actions';
import { fetchCatalog } from 'store/commons/actions';
import _get from 'lodash/get';

export default withLayout(
  compose(
    connect(
      (state) => ({
        multiContractPaymentDetails: _get(
          state,
          'payments.multiContractPayment.data',
          []
        ),
        responseDialogMultiContract: state.payments.responseDialog,
        loader: state.commons.loader,
      }),
      {
        getMultiPaymentContract,
        cleanMultiContractDetails,
        setCancelMovement,
        getDetail,
        fetchCatalog,
        closeResponseDialog,
      }
    )
  )(MultiContractPayment)
);
