import { takeLatest } from 'redux-saga/effects';
import types from './action-types';
import {
  getInvoicesListSaga,
  getInvoiceDetailsSaga,
  getListOfCRPsSaga,
  saveCRPSaga,
  reInvoiceSaga,
} from './sagas';

export default function* watchers() {
  yield takeLatest(types.GET_LIST, getInvoicesListSaga);
  yield takeLatest(types.GET_INVOICE_DETAILS, getInvoiceDetailsSaga);
  yield takeLatest(types.GET_LIST_OF_CRPS, getListOfCRPsSaga);
  yield takeLatest(types.SAVE_CRP, saveCRPSaga);
  yield takeLatest(types.RE_INVOICE, reInvoiceSaga);
}
