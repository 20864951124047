const RESET = 'invoices/RESET';

const CLOSE_RESPONSE_DIALOG = 'invoices/CLOSE_RESPONSE_DIALOG';

const SET_PARAMS = 'invoices/SET_PARAMS';
const RESET_PARAMS = 'invoices/RESET_PARAMS';
const GET_LIST = 'invoices/GET_LIST';
const GET_LIST_SUCCEED = 'invoices/GET_LIST_SUCCEED';
const GET_LIST_FAILED = 'invoices/GET_LIST_FAILED';
const SET_LIST_PAGE = 'invoices/SET_LIST_PAGE';

const GET_INVOICE_DETAILS = 'invoices/GET_INVOICE_DETAILS';
const GET_INVOICE_DETAILS_SUCCEED = 'invoices/GET_INVOICE_DETAILS_SUCCEED';
const GET_INVOICE_DETAILS_FAILED = 'invoices/GET_INVOICE_DETAILS_FAILED';

const CLEAN_INVOICE_DETAILS = 'invoices/CLEAN_INVOICE_DETAILS';

const GET_LIST_OF_CRPS = 'invoices/GET_LIST_OF_CRPS';
const GET_LIST_OF_CRPS_SUCCEED = 'invoices/GET_LIST_OF_CRPS_SUCCEED';
const GET_LIST_OF_CRPS_FAILED = 'invoices/GET_LIST_OF_CRPS_FAILED';

const SET_LIST_OF_CRPS = 'invoices/SET_LIST_OF_CRPS';
const SET_CRPS_PARAMS = 'invoices/SET_CRPS_PARAMS';
const SET_CRPS_LIST_PAGE = 'invoices/SET_CRPS_LIST_PAGE';

const SAVE_CRP = 'invoices/SAVE_CRP';
const SAVE_CRP_SUCCEED = 'invoices/SAVE_CRP_SUCCEED';
const SAVE_CRP_FAILED = 'invoices/SAVE_CRP_FAILED';

const RE_INVOICE = 'RE_INVOICE';
const RE_INVOICE_SUCCEED = 'RE_INVOICE_SUCCEED';
const RE_INVOICE_FAILED = 'RE_INVOICE_FAILED';

export default {
  RESET,
  CLOSE_RESPONSE_DIALOG,
  GET_LIST,
  GET_LIST_SUCCEED,
  GET_LIST_FAILED,
  SET_PARAMS,
  RESET_PARAMS,
  SET_LIST_PAGE,
  GET_INVOICE_DETAILS,
  GET_INVOICE_DETAILS_SUCCEED,
  GET_INVOICE_DETAILS_FAILED,
  CLEAN_INVOICE_DETAILS,
  GET_LIST_OF_CRPS,
  GET_LIST_OF_CRPS_SUCCEED,
  GET_LIST_OF_CRPS_FAILED,
  SET_LIST_OF_CRPS,
  SET_CRPS_PARAMS,
  SET_CRPS_LIST_PAGE,
  SAVE_CRP,
  SAVE_CRP_SUCCEED,
  SAVE_CRP_FAILED,
  RE_INVOICE,
  RE_INVOICE_SUCCEED,
  RE_INVOICE_FAILED,
};
