import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import moment from 'moment';
import {
  timeZone,
  YYYY_MM_DD,
} from '@casanova/casanova-common/lib/utils/constants/index.js';
import { GENDER_INNCODE } from 'utils/constants/genders';
import { getUUIDOfAddressProofByIssuer } from 'utils/address';
import { DRIVER_LICENSES_TYPES } from 'utils/licenses';

const formatterCustomerInfoCommon = (values) => ({
  birthDate: moment(values.birthDate).tz(timeZone).format(YYYY_MM_DD),
  cellphone: values.phoneNumber,
  email: values.email,
  landline: values.homeNumber,
  lastName: values.lastName,
  names: values.names,
  secondLastName: values.secondLastName,
  sexUuid: values.gender,
});

export const customerInfoFormatter = (values = {}) => ({
  ...formatterCustomerInfoCommon(values),
  birthStateUuid: values.birthPlace,
  curp: _get(values, 'curp', null),
});

export const foreignCustomerInfoFormatter = (values) => ({
  ...formatterCustomerInfoCommon(values),
  originCountry: values.originCountry,
});

export const customerINEInnCodeInfoFormatter = (values) => ({
  names: _get(values, 'name.firstName', ''),
  lastName: _get(values, 'name.paternalLastName', ''),
  secondLastName: _get(values, 'name.maternalLastName', ''),
  birthDate: moment.utc(values.birthDate).format(YYYY_MM_DD),
  birthPlace: _get(values, 'birthPlace', ''),
  curp: _get(values, 'curp', ''),
  gender: GENDER_INNCODE[_get(values, 'gender', '')] ?? '',
  identificationType: '767836a9-4e1d-4110-a392-0a2f7a44904e',
  identificationCode: _get(values, 'mrz1', '').split('<')[0],
  validity: moment.utc(parseInt(values.expireAt)).format(YYYY_MM_DD),
  emissionYear: _get(values, 'issueDate', '')
    ? moment.utc(values.issueDate + '-01-01').format(YYYY_MM_DD)
    : '',
  street: _get(values, 'addressFieldsFromStatement.street', ''),
  zipCode: _get(values, 'addressFieldsFromStatement.postalCode', ''),
  addressDocumentType: getUUIDOfAddressProofByIssuer(
    _get(values, 'additionalDocumentAttempts[0].issuer', '')
  ),
  period: moment
    .utc(parseInt(_get(values, 'additionalDocumentAttempts[0].timestamp', 0)))
    .startOf('month')
    .format('YYYY-MM-DD'),
  // indoorNumber: _get(values, 'interiorNumber', '').substring(0, 10),
  // outdoorNumber: _get(values, 'exteriorNumber', ''),
});

export const customerLicenseInnCodeInfoFormatter = (values) => ({
  licenseType: DRIVER_LICENSES_TYPES[_get(values, 'license.classes', '')],
  validityDriverLicense: moment
    .utc(_get(values, 'license.expireAt', 0))
    .format(YYYY_MM_DD),
  expedition: moment
    .utc(_get(values, 'license.issuedAt', 0))
    .format(YYYY_MM_DD),
  antiquity: moment.utc(_get(values, 'license.issuedAt', 0)).format(YYYY_MM_DD),
  licenseNumber: _get(values, 'license.documentNumber', ''),
  nationality: _get(values, 'license.nationality', ''),
  // emissionState: _get(values, 'license.issuingState', ''),
});
