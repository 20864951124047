/* eslint-disable jsx-a11y/label-has-associated-control */
import { useCallback, useMemo, useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { useFormikContext } from 'formik';
import {
  RadioGroupInput,
  MaskedInput as Input,
  FileInput,
  Switch,
  Icons,
  DatePickerV2,
  ConnectedSelect,
  NumberInput,
  Button,
  Loader,
  Modal,
} from '@casanova/casanova-common';
import { PaymentType } from '@reservations/components';
import {
  alphaMask,
  numericMask,
  hourMask,
  alphaMaskWithSpacesAndAccent,
  cardNumberValidations,
} from 'utils/masks';
import { generateCalendarMinimumDate, today2CalendarDate } from 'utils/date';
import i18n from '@i18n';
import { BANK_CARD_TYPES } from 'utils/constants/bankCardTypes';
import { options } from '@casanova/casanova-common/lib/Loader/code';
import ClientCards from '../ClientCards';

const minimumDate = generateCalendarMinimumDate(0, 0, 9);
const maximumDate = today2CalendarDate();

const CardMethod = ({
  name,
  setHasUpdatedHoldBack,
  onSelect,
  hasUpdateDifMetods,
  uuidCustomer,
  index,
}) => {
  const buttonGuardarBancoStyle = {
    backgroundColor: '#450E99',
    color: 'white',
    padding: '15px 32px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    marginTop: '10px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '4px',
  };

  const { values, setFieldValue } = useFormikContext();
  const cardNumber = values[`cardNumber_${index}`];
  const bank = values[`bank_${index}`];
  const [showModal, setShowModal] = useState();

  const [otroBanco, setOtroBanco] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedBank, setSelectedBank] = useState('');
  const [show, setShow] = useState(false);
  const [titleModalBancoNuevo, setTitleModalBancoNuevo] = useState('');
  const [subtitleModalBancoNuevo, setSubtitleModalBancoNuevo] = useState('');

  const cardTypes = useSelector((state) =>
    _get(state, 'commons.catalogsBankCardTypes.list', [])
  );

  const isCreditCard = useMemo(() => {
    const cardType = cardTypes.find(
      (card) => card.uuid === values[`cardType_${index}`]
    );
    return cardType && cardType.identifier === BANK_CARD_TYPES.CREDIT;
  }, [cardTypes, values[`cardType_${index}`]]);

  const handleCardNumberChange = useCallback(
    (evt) => {
      if (isCreditCard) {
        setFieldValue(`holdbackCard`, evt.target.value);
        setHasUpdatedHoldBack(true);
      }
    },
    [isCreditCard]
  );

  const obtenerFechaActual = () => {
    const fechaActual = new Date();
    const year = fechaActual.getFullYear();
    const month = String(fechaActual.getMonth() + 1).padStart(2, '0'); // Los meses comienzan desde 0
    const day = String(fechaActual.getDate()).padStart(2, '0');
    const fechaFormateada = `${year}-${month}-${day}`;
    return fechaFormateada;
  };

  const obtenerHoraActual = () => {
    const horaActual = new Date();
    const horas = String(horaActual.getHours()).padStart(2, '0');
    const minutos = String(horaActual.getMinutes()).padStart(2, '0');
    const segundos = String(horaActual.getSeconds()).padStart(2, '0');
    const horaFormateada = `${horas}:${minutos}:${segundos}`;
    return horaFormateada;
  };

  useEffect(() => {
    setFieldValue(`movementDate_${index}`, obtenerFechaActual());
    setInterval(() => {
      setFieldValue(`movementHour_${index}`, obtenerHoraActual());
    }, 1000);
  }, []);

  useEffect(() => {
    if (isCreditCard) {
      setFieldValue('holdbackCard', cardNumber);
      setFieldValue('holdbackBank', bank);
      setHasUpdatedHoldBack(true);
    }
  }, [isCreditCard, cardNumber, bank]);

  const handleClickCharge = () => {
    setShowModal(true);
  };

  const handleCardsClose = () => {
    setShowModal(false);
  };

  const handleChangeBanco = (e) => {
    if (isCreditCard) {
      setFieldValue('holdbackBank', e.target.value);
      setHasUpdatedHoldBack(true);
    }
    setSelectedBank(e.target.value);
    if (e.target.value === 'c1f56866-0904-499b-a3fe-1b70a3e207b3') {
      setOtroBanco(true);
      return;
    }
    setOtroBanco(false);
  };

  const handleAddBank = async (newBank, options = {}) => {
    let uuidNuevoBanco = '';
    try {
      const headers = options.headers || new Headers();

      const { authToken } = options;
      if (authToken) {
        headers.append('Authorization', `Bearer ${authToken}`);
        headers.append('Content-Type', 'application/json');
      }
      headers.append('Content-Type', 'application/json');

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/pricequotes/v1/catalogs/banks`,
        {
          method: 'POST',
          body: JSON.stringify(newBank),
          headers: headers,
          credentials: 'include',
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      // actualizarSelectBancos();

      uuidNuevoBanco = data.uuid;
      setSelectedBank(data.uuid);
      setShowLoader(false);
      setTitleModalBancoNuevo('Banco agregado');
      setSubtitleModalBancoNuevo('Selecciona el banco que has agregado.');
      setShow(true);
    } catch (error) {
      setShowLoader(false);
      setTitleModalBancoNuevo('Error');
      setSubtitleModalBancoNuevo(
        'El banco que intentas agregar ya existe o vuelve a intentarlo más tarde.'
      );
      setShow(true);
    }
    setFieldValue('holdbackBank', uuidNuevoBanco);
  };

  const handleSaveNewBank = () => {
    setShowLoader(true);
    setOtroBanco(false);
    const newBank = {
      name: values[`holdbackOtherBankName_${index}`],
      legalName: values[`holdbackOtherBankName_${index}`],
    };
    handleAddBank(newBank, options);
  };

  const handleCloseModal = useCallback(() => {
    setShow(false);
  }, [setShow]);

  return (
    <>
      <ClientCards
        show={showModal}
        onClose={handleCardsClose}
        uuidCustomer={uuidCustomer}
        setValues={setFieldValue}
      />
      <PaymentType
        name={`${name}_${index}`}
        onSelect={onSelect}
        hasUpdateDifMetods={hasUpdateDifMetods}
        title="PAGO CON TARJETA DE CRÉDITO O DÉBITO"
        subtitle="Visa, MasterCard, American Express."
        noContainer
        right={
          <div className="d-flex flex-wrap mt-0">
            <div className="px-2 mr-3">
              <Icons.American width="3.125rem" height="3.125rem" />
            </div>
            <div className="px-2 mr-3">
              <Icons.Mastercard width="3.125rem" height="3.125rem" />
            </div>
            <div className="px-2">
              <Icons.Visa width="3.125rem" height="3.125rem" />
            </div>
          </div>
        }
      >
        {showLoader && <Loader show />}
        <Modal
          open={show}
          header={null}
          footer={null}
          className="ViewRefundDetail"
          contentClassName="px-4 py-4 container"
          closeButton
          onClose={handleCloseModal}
          bigModal
        >
          <div className="ml-4">
            <h3 className="text-primary">{titleModalBancoNuevo}</h3>
            <p>{subtitleModalBancoNuevo}</p>
          </div>
        </Modal>
        <div className="row">
          <div className="col-6 switch-inverted">
            <RadioGroupInput
              labelClass="d-flex align-items-center"
              groupClass="col-12 d-flex justify-content-between"
              parentClass="mb-0"
              label=""
              name={`cardType_${index}`}
              options={cardTypes}
            />
          </div>
          <div className="col-6 switch-inverted">
            <Button
              block
              className="PaymentButton mt-0 mr-1"
              onClick={handleClickCharge}
            >
              Seleccionar tarjeta
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <ConnectedSelect
              label="Banco*"
              name={`bank_${index}`}
              value={selectedBank}
              options="commons.catalogsBanks"
              onChange={handleChangeBanco}
            />
            {/* <div className='row'>
              <div className='col-8'>
                <Input
                  maskPlaceholder=""
                  label="NUEVO BANCO*"
                  placeholder="NUEVO BANCO*"
                  // name="reference"
                />
              </div>
              <div className='col-4'>
                <Button>+</Button> 
              </div>
            </div>
            <a>
              Agregar otro banco
            </a> */}
          </div>
          {otroBanco && (
            <>
              <div className="col-md-6 col-sm-12">
                <Input
                  mask={alphaMaskWithSpacesAndAccent(40)}
                  maskPlaceholder=""
                  label="Otro Banco*"
                  placeholder="Ingresa nuevo banco*"
                  name={`holdbackOtherBankName_${index}`}
                  disabled={!otroBanco}
                />
              </div>
              <div className="col-md-6 col-sm-12 d-flex align-items-center">
                <Button
                  style={buttonGuardarBancoStyle}
                  onClick={() => handleSaveNewBank()}
                >
                  Guardar Banco
                </Button>
              </div>
            </>
          )}
          <div className="col-6">
            <Input
              mask={cardNumberValidations}
              maskPlaceholder="#### #### #### ####"
              label="No. de tarjeta*"
              placeholder="No. de tarjeta*"
              name={`cardNumber_${index}`}
              onChange={handleCardNumberChange}
            />
          </div>
          <div className="col-6">
            <Input
              mask={numericMask(30)}
              maskPlaceholder=""
              label="NO. DE REFERENCIA*"
              placeholder="NO. DE REFERENCIA*"
              name={`reference_${index}`}
            />
          </div>
          <div className="col-6">
            <Input
              mask={alphaMask(30)}
              maskPlaceholder=""
              label="AUTORIZACIÓN*"
              placeholder="AUTORIZACIÓN*"
              name={`authorization_${index}`}
            />
          </div>
          <div className="col-6">
            <DatePickerV2
              initialValue={values[`movementDate_${index}`]}
              placeholder="Fecha*"
              name={`movementDate_${index}`}
              label="Fecha*"
              autoComplete="off"
              minimumDate={minimumDate}
              maximumDate={maximumDate}
              disabled
            />
          </div>
          <div className="col-6">
            <Input
              mask={hourMask}
              maskPlaceholder=""
              label="Hora*"
              placeholder="Hora*"
              name={`movementHour_${index}`}
              disabled
            />
          </div>
          <div className="col-6">
            <FileInput
              url={values[`voucher_${index}`]}
              displayText={values[`voucher_${index}`]}
              type="file"
              label="Comprobante*"
              placeholder="Comprobante*"
              name={`voucher_${index}`}
              showIcon={false}
            />
          </div>
          <div className="col-6">
            {hasUpdateDifMetods && (
              <NumberInput
                decimals={2}
                max={9_999_999}
                label="Monto en tarjeta"
                placeholder="Monto en tarjeta"
                name={`amountCard_${index}`}
                disabled={!hasUpdateDifMetods}
              />
            )}
          </div>
          <div className="col-md-8 switch-inverted">
            <label htmlFor={`hasHolderName_${index}`}>
              {i18n('RESERVATIONS__PAYMENT__CARD__HOLDER')}
            </label>
            <Switch name={`hasHolderName_${index}`} />
          </div>
          {!values[`hasHolderName_${index}`] && (
            <div className="col-md-6">
              <FileInput
                url={values[`accountStatus_${index}`]}
                displayText={values[`accountStatus_${index}`]}
                type="file"
                label="Comprobante de Titularidad*"
                placeholder="Comprobante de Titularidad*"
                name={`accountStatus_${index}`}
                showIcon={false}
              />
            </div>
          )}
        </div>
      </PaymentType>
    </>
  );
};

CardMethod.propTypes = {
  name: PropTypes.string,
  setHasUpdatedHoldBack: PropTypes.func,
  hasUpdateDifMetods: PropTypes.bool,
  onSelect: PropTypes.func,
  uuidCustomer: PropTypes.string,
  index: PropTypes.number,
};

CardMethod.defaultProps = {
  name: 'card',
  index: 0,
};

export default CardMethod;
