const RESET = 'contracts/RESET';

const SET_PARAMS = 'contracts/SET_PARAMS';
const RESET_PARAMS = 'contracts/RESET_PARAMS';
const GET_LIST = 'contracts/GET_LIST';
const GET_LIST_SUCCEED = 'contracts/GET_LIST_SUCCEED';
const GET_LIST_FAILED = 'contracts/GET_LIST_FAILED';
const GET_FILTERS = 'contracts/GET_FILTERS';
const GET_FILTERS_SUCCEED = 'contracts/GET_FILTERS_SUCCEED';
const GET_FILTERS_FAILED = 'contracts/GET_FILTERS_FAILED';
const SET_LIST_PAGE = 'contracts/SET_LIST_PAGE';

const CLOSE_RESPONSE_DIALOG = 'loans/CLOSE_RESPONSE_DIALOG';

const SAVE_CONTRACT = 'contracts/SAVE_CONTRACT';
const SAVE_CONTRACT_SUCCEED = 'contracts/SAVE_CONTRACT_SUCCEED';
const SAVE_CONTRACT_FAILED = 'contracts/SAVE_CONTRACT_FAILED';

const GET_CONTRACT_DETAILS = 'contracts/GET_CONTRACT_DETAILS';
const GET_CONTRACT_DETAILS_SUCCEED = 'contracts/GET_CONTRACT_DETAILS_SUCCEED';
const GET_CONTRACT_DETAILS_FAILED = 'contracts/GET_CONTRACT_DETAILS_FAILED';
const CLEAN_CONTRACT_DETAILS = 'contracts/CLEAN_CONTRACT_DETAILS';

const CHANGE_OF_VEHICLE = 'contracts/CHANGE_OF_VEHICLE';
const CHANGE_OF_VEHICLE_SUCCEED = 'contracts/CHANGE_OF_VEHICLE_SUCCEED';
const CHANGE_OF_VEHICLE_FAILED = 'contracts/CHANGE_OF_VEHICLE_FAILED';

const CREATE_LOAN = 'contracts/CREATE_LOAN';
const CREATE_LOAN_SUCCEED = 'contracts/CREATE_LOAN_SUCCEED';
const CREATE_LOAN_FAILED = 'contracts/CREATE_LOAN_FAILED';

const UPDATE_CONTRACT = 'contracts/UPDATE_CONTRACT';
const UPDATE_CONTRACT_SUCCEED = 'contracts/UPDATE_CONTRACT_SUCCEED';
const UPDATE_CONTRACT_FAILED = 'contracts/UPDATE_CONTRACT_FAILED';

const CLOSE_RESPONSE_DIALOG_UPDATE_CONTRACT =
  'contacts/CLOSE_RESPONSE_DIALOG_UPDATE_CONTRACT';

export default {
  RESET,
  CLOSE_RESPONSE_DIALOG,
  SET_PARAMS,
  RESET_PARAMS,
  GET_LIST,
  GET_LIST_SUCCEED,
  GET_LIST_FAILED,
  GET_FILTERS,
  GET_FILTERS_SUCCEED,
  GET_FILTERS_FAILED,
  SET_LIST_PAGE,
  SAVE_CONTRACT,
  SAVE_CONTRACT_SUCCEED,
  SAVE_CONTRACT_FAILED,
  GET_CONTRACT_DETAILS,
  GET_CONTRACT_DETAILS_SUCCEED,
  GET_CONTRACT_DETAILS_FAILED,
  CLEAN_CONTRACT_DETAILS,
  CHANGE_OF_VEHICLE,
  CHANGE_OF_VEHICLE_SUCCEED,
  CHANGE_OF_VEHICLE_FAILED,
  CREATE_LOAN,
  CREATE_LOAN_SUCCEED,
  CREATE_LOAN_FAILED,
  UPDATE_CONTRACT,
  UPDATE_CONTRACT_SUCCEED,
  UPDATE_CONTRACT_FAILED,
  CLOSE_RESPONSE_DIALOG_UPDATE_CONTRACT,
};
