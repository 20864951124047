import {
  sendRequest,
  sendFormRequest,
  sendPostRequest,
  sendPutRequest,
  sendDeleteRequest,
  sendCustomRequest,
} from '@casanova/casanova-common/lib/utils/service';
import _kebabCase from 'lodash/kebabCase';
import {
  VIEW_VEHICLE_PURCHASE_CFDI_ORIGINAL,
  VIEW_VEHICLE_PURCHASE_CFDI_COPY,
  VIEW_VEHICLE_ADAPTATION,
} from 'utils/roles/permissions';

import { validateElement } from 'utils/roles';

import { downloadFileFromBase64 } from 'services/commons';
import { MASSIVE_LOAD_PARAMS } from '@vehicles/common/constants';

import qs from 'qs';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import { APIError } from 'utils/exceptions';
import { cleanParams } from 'utils/requests';
import * as saraServiceUtils from 'utils/service';
import {
  transformForGetDetail,
  transformForVehicleFilters,
  transformForSaveVehicle,
  transformForAdaptation,
  transformForPurchase,
  transformForSelect,
  transformForVehicleListFilters,
} from '../../utils/transformHelpers';
import * as additionalsMeta from './additionals';

const root = 'vehicle/v1/';

export async function fetchAllVehicles(filters, options = {}) {
  const vehicleParams = transformForVehicleFilters(
    filters.filters,
    filters.searchPattern
  );
  const url = `${root}vehicles?${qs.stringify(
    cleanParams(filters.params)
  )}&${qs.stringify(cleanParams(vehicleParams))}`;

  const response = await sendCustomRequest(url, options);
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data;
}

export async function fetchVehicleFilters() {
  const url = `${root}vehicles/filter`;

  const response = await sendCustomRequest(url, {}, undefined, 'GET');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return transformForVehicleListFilters(response.data.data);
}

export async function exportVehicles(filters, options = {}) {
  const vehicleParams = transformForVehicleFilters(
    filters.filters,
    filters.searchPattern
  );
  const url = `${root}management/export/?${qs.stringify(
    cleanParams(filters.params)
  )}&${qs.stringify(cleanParams(vehicleParams))}`;
  const response = await sendRequest(url, options);
  downloadFileFromBase64(
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    response.data,
    'export_vehicles.xlsx'
  );
}

export async function exportVehicleDetail(id, options = {}) {
  const url = `${root}management/export/${id}`;
  const response = await sendRequest(url, options);
  downloadFileFromBase64(
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    response.data,
    'export_vehicle_detail.xlsx'
  );
}

export async function downloadTemplateForMassiveLoad(options = {}) {
  const url = `${root}management/massive-load/template`;
  const response = await sendRequest(url, options);
  downloadFileFromBase64(
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    response.data,
    'vehicles_template.xlsx'
  );
}

const canGetPurchase = (purchaseUuid) =>
  purchaseUuid &&
  (validateElement(VIEW_VEHICLE_PURCHASE_CFDI_COPY) ||
    validateElement(VIEW_VEHICLE_PURCHASE_CFDI_ORIGINAL));

export async function fetchVehicleInfo(id, options = {}) {
  const url = `${root}management/${id}/detail`;

  const dataVehicle = await sendRequest(url, options);

  if (dataVehicle.success) {
    const { purchase = {} } = dataVehicle.data;
    const purchaseUuid = purchase.uuid;

    if (canGetPurchase(purchaseUuid)) {
      const filesPurchase = await fetchPurchaseFilesUrl(purchaseUuid, options);
      if (filesPurchase.success) {
        dataVehicle.data.purchase.pdf = filesPurchase.data.pdf;
        dataVehicle.data.purchase.xml = filesPurchase.data.xml;
        dataVehicle.data.purchase.pdfNoValue = filesPurchase.data.pdfNoValue;
      }
    }

    if (dataVehicle.data.adaptation) {
      const adaptationUuid = dataVehicle.data.adaptation.uuid;
      if (adaptationUuid !== null && validateElement(VIEW_VEHICLE_ADAPTATION)) {
        const filesAdaptation = await fetchAdaptationFilesUrl(
          adaptationUuid,
          options
        );
        if (filesAdaptation.success && filesAdaptation.data) {
          dataVehicle.data.adaptation.pdf = filesAdaptation.data.pdf;
          dataVehicle.data.adaptation.xml = filesAdaptation.data.xml;
        }
      }
    }
  }
  return transformForGetDetail(dataVehicle.data);
}

export async function fetchPurchaseFilesUrl(uuid, options = {}) {
  const url = `${root}purchase/${uuid}/cfdi`;
  return await sendRequest(url, options);
}

export async function fetchAdaptationFilesUrl(uuid, options = {}) {
  const url = `${root}adaptation/${uuid}/cfdi`;
  return await sendRequest(url, options);
}

export async function saveVehicle(request, options = {}) {
  let responseVehicle;
  let responsePurchase;
  let responseAdaptation;
  let responseConversion;
  let responsePermission;
  let generalResponse = {
    success: true,
    data: {},
  };
  const url = `${root}management`;
  const transformedRequest = transformForSaveVehicle(request);
  responseVehicle = await sendPostRequest(url, transformedRequest, options);
  if (!responseVehicle.data.errorCode) {
    responsePurchase = await saveVehiclePurchaseFiles(
      [
        {
          file: request.invoicePDF,
          name: transformedRequest.purchase.invoicePDFName,
        },
        {
          file: request.invoice,
          name: transformedRequest.purchase.invoiceName,
        },
        {
          file: request.invoiceCopy,
          name: transformedRequest.purchase.invoiceCopyName,
        },
      ],
      responseVehicle.data.purchase,
      options
    );
    if (!responsePurchase.data.errorCode) {
      if (request.hasAdaptation) {
        responseAdaptation = await saveVehicleAdaptationFiles(
          [
            {
              file: request.adaptationInvoicePDF,
              name: transformedRequest.adaptation.adaptationInvoicePDFName,
            },
            {
              file: request.adaptationInvoiceXML,
              name: transformedRequest.adaptation.adaptationInvoiceXMLName,
            },
          ],
          responseVehicle.data.adaptation,
          options
        );
        if (responseAdaptation.data.errorCode) {
          generalResponse = {
            success: false,
            data: _pick(responseAdaptation.data, ['errorCode', 'message']),
          };
        }
      }
      // conversion
      if (
        (request.hasConversion && request.conversionInvoicePDF) ||
        (request.hasConversion && request.conversionInvoiceXML)
      ) {
        responseConversion = await saveVehicleConversionFiles(
          [
            {
              file: request.conversionInvoicePDF,
              name: transformedRequest.conversion.conversionInvoicePDFName,
            },
            {
              file: request.conversionInvoiceXML,
              name: transformedRequest.conversion.conversionInvoiceXMLName,
            },
          ],
          responseVehicle.data.conversion,
          options
        );
        if (responseConversion.data.errorCode) {
          generalResponse = {
            success: false,
            data: _pick(responseConversion.data, ['errorCode', 'message']),
          };
        }
      }
      // permission
      if (
        request.permissionType !== undefined &&
        request.permissionType !== ''
      ) {
        responsePermission = await saveVehiclePermissionFiles(
          [
            {
              file: request.permissionPDF,
              name: transformedRequest.permission.permissionInvoicePDFName,
            },
          ],
          responseVehicle.data.permission,
          options
        );
        if (responsePermission.data.errorCode) {
          generalResponse = {
            success: false,
            data: _pick(responsePermission.data, ['errorCode', 'message']),
          };
        }
      }
    } else {
      generalResponse = {
        success: false,
        data: _pick(responsePurchase.data, ['errorCode', 'message']),
      };
    }
  } else {
    generalResponse = {
      success: false,
      data: _pick(responseVehicle.data, ['errorCode', 'message']),
    };
  }

  return generalResponse;
}

export async function editVehicle({ vehicle, uuid, element }, options) {
  let request;
  let url;
  let response = { success: true, data: {} };

  try {
    if (element === 'adaptation') {
      // Editing vehicle and adaptation section
      /* url = `${root}management/${uuid}`;
      request = transformForVehicleData(vehicle);
      const vehicleResponse = await sendPutRequest(url, request, options);
      if(vehicleResponse.data.errorCode) {
        throw Error({errorCode: vehicleResponse.data.errorCode, message: 'Error updating vehicleData'});
      } */
      if (vehicle.hasAdaptation) {
        const adaptationRequest = transformForAdaptation(vehicle);
        if (vehicle.adaptationUuid !== '') {
          // Edit adaptation
          url = `${root}adaptation/${vehicle.adaptationUuid}`;
          const editAdaptationResponse = await sendPutRequest(
            url,
            adaptationRequest,
            options
          );
          if (editAdaptationResponse.data.errorCode) {
            throw Error({
              errorCode: editAdaptationResponse.data.errorCode,
              message: 'Error editing adaptation',
            });
          }

          if (
            vehicle.initialAdaptationCost !== vehicle.adaptationCost &&
            vehicle.initialAdaptationInvoiceNumber !==
              vehicle.adaptationInvoiceNumber
          ) {
            await saveVehicleAdaptationFiles(
              [
                {
                  file: vehicle.adaptationInvoicePDF,
                  name: vehicle.adaptation.adaptationInvoicePDFName,
                },
                {
                  file: vehicle.adaptationInvoiceXML,
                  name: vehicle.adaptation.adaptationInvoiceXMLName,
                },
              ],
              vehicle.adaptationUuid,
              options
            );
          }
        } else {
          // Add adaptation
          url = `${root}adaptation/`;
          const addAdaptationResponse = await sendPostRequest(
            url,
            { vehicle: uuid, ...adaptationRequest },
            options
          );
          if (addAdaptationResponse.data.errorCode) {
            throw Error({
              errorCode: addAdaptationResponse.data.errorCode,
              message: 'Error creating adaptation',
            });
          }
          await saveVehicleAdaptationFiles(
            [
              {
                file: vehicle.adaptationInvoicePDF,
                name: adaptationRequest.adaptationInvoicePDFName,
              },
              {
                file: vehicle.adaptationInvoiceXML,
                name: adaptationRequest.adaptationInvoiceXMLName,
              },
            ],
            addAdaptationResponse.data.adaptation,
            options
          );
        }
      } else if (!vehicle.hasAdaptation && vehicle.adaptationUuid !== '') {
        // Delete adaptation
        url = `${root}adaptation/${vehicle.adaptationUuid}`;
        const deleteAdaptationResponse = await sendDeleteRequest(url, options);
        if (
          deleteAdaptationResponse.data &&
          deleteAdaptationResponse.data.errorCode
        ) {
          throw Error({
            errorCode: deleteAdaptationResponse.data.errorCode,
            message: 'Error deleting adaptation',
          });
        }
      }
    } else if (element === 'vehicle') {
      const vehicleRequest = transformForSaveVehicle(vehicle);
      url = `${root}management/${uuid}`;
      const editVehicleResponse = await sendPostRequest(
        url,
        vehicleRequest,
        options
      );
      if (editVehicleResponse.data.errorCode) {
        throw Error({
          errorCode: editVehicleResponse.data.errorCode,
          message: 'Error editing vehicle',
        });
      }
    } else {
      // Editing purchase section
      const purchaseRequest = transformForPurchase(vehicle);
      if (vehicle.initialInvoiceNumber !== vehicle.invoiceNumber) {
        url = `${root}purchase/`;
        const purchaseResponse = await sendPostRequest(
          url,
          { vehicle: vehicle.vehicleUuid, ...purchaseRequest },
          {}
        );
        if (purchaseResponse.data.errorCode) {
          throw Error({
            errorCode: purchaseResponse.data.errorCode,
            message: 'Error creating purchase',
          });
        }
        await saveVehiclePurchaseFiles(
          [
            { file: request.invoicePDF, name: request.invoicePDFName },
            { file: request.invoice, name: request.invoiceName },
            { file: request.invoiceCopy, name: request.invoiceCopyName },
          ],
          purchaseResponse.data.purchase
        );
      } else {
        url = `${root}purchase/${vehicle.purchaseUuid}`;
        const editPurchaseResponse = await sendPutRequest(
          url,
          purchaseRequest,
          {}
        );
        if (editPurchaseResponse.data.errorCode) {
          throw Error({
            errorCode: editPurchaseResponse.data.errorCode,
            message: 'Error editing purchase',
          });
        }
      }
    }
  } catch (err) {
    const { errorCode, message } = err;
    response = {
      data: {
        success: false,
        errorCode,
        message,
      },
    };
  }

  return response;
}

export async function saveVehiclePurchaseFiles(files, uuid, options = {}) {
  const filesToUpload = files.filter((f) => f.file !== undefined);
  console.log('files', files, 'filesToUpload', filesToUpload);
  if (filesToUpload.length === 0)
    return {
      data: {
        success: true,
        errorCode: '',
        message: '',
      },
    };
  const url = `${root}purchase/${uuid}/cfdi`;
  const form = new FormData();
  filesToUpload.forEach((file) => {
    if (file.file) {
      form.append('cfdi', file.file, file.name);
    }
  });
  return sendFormRequest(url, form, options);
}
export async function saveVehicleAdaptationFiles(files, uuid, options = {}) {
  const url = `${root}adaptation/${uuid}/cfdi`;
  const form = new FormData();
  files.forEach((file) => {
    if (file.file) {
      form.append('cfdi', file.file, file.name);
    }
  });
  return sendFormRequest(url, form, options);
}
export async function saveVehicleConversionFiles(files, uuid, options = {}) {
  const url = `${root}conversion/${uuid}/cfdi`;
  const form = new FormData();
  files.forEach((file) => {
    if (file.file) {
      form.append('cfdi', file.file, file.name);
    }
  });
  return sendFormRequest(url, form, options);
}
export async function saveVehiclePermissionFiles(files, uuid, options = {}) {
  const url = `${root}permission/${uuid}/pdf`;
  const form = new FormData();
  files.forEach((file) => {
    if (file.file) {
      form.append('pdf', file.file, file.name);
    }
  });
  return sendFormRequest(url, form, options);
}

export async function uploadVehicles(params, options = {}, massiveLoadType) {
  const path = `${root}management/${MASSIVE_LOAD_PARAMS[massiveLoadType]}?process=${params.process}`;
  try {
    const formData = new FormData();
    const appendFile = (file) => {
      formData.append('files', file, _get(file, 'name'));
    };

    params.files.forEach(appendFile);

    const responseForm = await sendFormRequest(path, formData, options);
    if (responseForm.data.errorCode) {
      throw Error(responseForm.data.errorCode);
    }
    return { success: true, ...responseForm.data };
  } catch (error) {
    return { success: false, data: { errorCode: error.message } };
  }
}

export async function downloadRecords(data) {
  downloadFileFromBase64(
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    data,
    'RegistrosErroneos.xlsx'
  );
}

const getAdditionalUrl = (name, uuid) =>
  `${root}${_kebabCase(name)}?vehicleUuid=${uuid}`;
const getAdditionalUrlGpsOrders = (name, uuid) =>
  `${root}${_kebabCase(name)}/orders?vehicleUuid=${uuid}`;
const saveAdditionalUrl = (name, { id }, isCreate) =>
  `${root}${_kebabCase(name)}${name === 'gps' ? '/orders' : ''}${
    id && !isCreate ? `/${id}` : ''
  }`;
const uploadAdditionalFileUrl = (name, data, field) =>
  `${root}${_kebabCase(name)}/${data.id}/${field}`;

const fileDownloadAdditionalUrl = (name, data, field) =>
  `${root}${_kebabCase(name)}/${data.id}/${field}`;

const fileDownloadAdditionalDataUrl = (name, data, field) =>
  `${root}${_kebabCase(name)}/${data}/${field}`;

export async function getAdditional(name, data, withFiles = [], options = {}) {
  let files = {};
  const path =
    name === 'gps'
      ? getAdditionalUrlGpsOrders(name, data)
      : getAdditionalUrl(name, data);
  const result = await saraServiceUtils.sendRequest(path, options);
  const { transformer } = additionalsMeta[name];
  for (const field of withFiles) {
    const content = _get(result, 'content', []);
    let uuid = _get(result, 'uuid');
    if (content.length > 0) {
      uuid = content[content.length - 1].uuid;
    }
    const filesResponse = await getAdditionalDownloadData(name, uuid, field);
    files = {
      ...files,
      [field]: filesResponse.data,
    };
  }
  return transformer
    ? name === 'gps' || name === 'key'
      ? transformer(result)
      : transformer({ ...result, files })
    : result;
}

export async function saveAdditional(name, data, options = {}) {
  const path = saveAdditionalUrl(name, data, true);
  const { formatter } = additionalsMeta[name];
  const result = await saraServiceUtils.sendPostRequest(
    path,
    formatter ? formatter(data) : data,
    options
  );
  const { targetField } = additionalsMeta[name];
  return targetField ? _get(result, targetField) : result[name];
}

export async function updateAdditional(name, data, options = {}) {
  const path = saveAdditionalUrl(name, data);
  const { formatter } = additionalsMeta[name];
  const { transformer } = additionalsMeta[name];
  const result = await saraServiceUtils.sendPutRequest(
    path,
    formatter ? formatter(data) : data,
    options
  );
  return transformer ? transformer(result) : result;
}

function getAdditionalFileName(name, data, field) {
  const {
    serialNumber,
    plateNumber,
    policyNumber,
    plateOptions,
    fiscalYear,
    folio,
    fineFolio,
  } = data;
  switch (name) {
    case 'policy':
      if (field === 'receipt') {
        return `${serialNumber}_${policyNumber}_REC_PLZ.pdf`;
      }
      return `${serialNumber}_${policyNumber}_PLZ.pdf`;

    case 'roadTax':
      return `${serialNumber}_${plateOptions[0].label}_${fiscalYear}_REC_TNC.pdf`;
    case 'circulationCard':
      if (field === 'receipt') {
        return `${serialNumber}__REC_TCC.pdf`;
      }
      return `${serialNumber}__TCC.pdf`;

    case 'verification':
      if (field === 'receipt') {
        return `${serialNumber}_${plateOptions[0].label}_${folio}_REC_VFC.pdf`;
      }
      return `${serialNumber}_${plateOptions[0].label}_${folio}_VFC.pdf`;

    case 'fine':
      return `${serialNumber}_${plateOptions[0].label}_${fineFolio}_REC_INF.pdf`;
    case 'plate':
    default:
      return `${serialNumber}_${plateNumber}_REC_PLC.pdf`;
  }
}

export async function uploadAdditionalFile(name, data, field, options) {
  const path = uploadAdditionalFileUrl(name, data, field);
  const fileName = getAdditionalFileName(name, data, field);
  const formData = new FormData();
  const file = data[field];
  if (!file || !fileName) {
    return false;
  }
  formData.append(field, file, fileName);
  const response = await saraServiceUtils.sendUploadRequest(
    path,
    formData,
    options
  );
  return response;
}

export async function getAdditionalDownloadData(
  name,
  data,
  field,
  options = {}
) {
  try {
    const path = fileDownloadAdditionalDataUrl(name, data, field);
    return await sendRequest(path, options);
  } catch (e) {
    console.error('There is an error: ', e);
  }
}

export async function getAdditionalDownloadLink(name, data, field, options) {
  const path = fileDownloadAdditionalUrl(name, data, field);
  const result = await saraServiceUtils.sendRequest(path, data.id, options);
  return result.pdfPresignedUrl;
}

export async function getVehiclesTypes(category) {
  const url = `${root}management/rental/filter?category=${category}`;

  const response = await sendCustomRequest(url, {}, undefined, 'GET');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return transformForSelect(response.data.data.types, 'uuid', 'name');
}

export async function getVehiclesByPlate(query = {}) {
  let params = { plateNumber: query };

  if (typeof query === 'object') {
    params = { ...params, ...query };

    delete params.showLoader;
    delete params.plateNumber;

    if (!query.isVirtualContract) {
      params.plateNumber = query.plateNumber || query.query;
      params.serialNumber = query.serialNumber || query.serial;
    }
  }

  const url = `${root}vehicles?${qs.stringify(cleanParams(params))}`;

  const response = await sendCustomRequest(url, {});
  if (!response.success || response.data.errorCode) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return transformForSelect(response.data.data.content, 'uuid', 'plateNumber');
}

export async function getVehicleHistoricStatus({ uuid = '', params = {} }) {
  const queryParams = `?${qs.stringify(cleanParams(params), {
    encode: false,
  })}`;
  const url = `${root}vehicles/${uuid}/status/history${queryParams}`;
  const response = await sendCustomRequest(url, {});
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getVehicleHistoricContracts({ uuid = '', params = {} }) {
  const queryParams = `?${qs.stringify(cleanParams(params), {
    encode: false,
  })}`;
  const url = `${root}vehicles/${uuid}/contract/history${queryParams}`;

  const response = await sendCustomRequest(url, {});
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getVehicleHistoricVirtualContracts(
  vehicleUuid,
  params,
  options = {}
) {
  const queryParams = `?${qs.stringify(cleanParams(params), {
    encode: false,
  })}`;
  const endpoint = `pricequotes/v1/virtual-contracts/vehicle/${vehicleUuid}/history${queryParams}`;

  const response = await sendCustomRequest(endpoint, options);
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  const { data = {} } = response.data;
  return data;
}

export async function patchVehicleDetails({ vehicleUuid, ...request } = {}) {
  const endpoint = `${root}management/${vehicleUuid}`;

  const response = await sendCustomRequest(endpoint, {}, request, 'PUT');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return {
    ...response.data.data,
    vehicleUuid,
  };
}

export async function getVehicleDetails(uuid) {
  const endpoint = `${root}vehicles/${uuid}/details`;

  const response = await sendCustomRequest(endpoint, uuid);
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return {
    ...response.data.data,
    vehicleUuid: uuid,
  };
}

export async function patchMarkVehicleForSale(body = {}) {
  // const endpoint = `${rootV2}/vehicles/${body.uuid}/detail`;
  // console.log(
  //   'mock response patch mark for sale ==>',
  //   patchMarkVehicleForSaleRes
  // );
  return null;
  // const response = await sendCustomRequest(endpoint, body);
  // if (!response.success) {
  //   const { errorCode, message } = response.data;
  //   throw new APIError(errorCode, message);
  // }
  // return response;
}
