import { cardInitialTouched, cardInitialValues } from '../CardMethod/config';
import { cashInitialTouched, cashInitialValues } from '../CashMethod/config';
import {
  depositInitialTouched,
  depositInitialValues,
} from '../DepositMethod/config';
import {
  invoiceInitialTouched,
  invoiceInitialValues,
} from '../InvoicePayment/config';
import {
  transferInitialTouched,
  transferInitialValues,
} from '../TransferMethod/config';
import {
  holdbackInitialTouched,
  holdbackInitialValues,
} from '../Holdback/config';
import { obtenerFechaActual } from '../index';

export const multiPaymentsInitialTouched = {
  movementType: false,
  amount: false,
  diffMethods: false,
  methodSelected: false,
  selectedMethods: false,
  priceIsInvalid: false,
  paymentDate: false,
  paymentTime: false,
  discount: false,
  totallyPaidWithRefund: true,
  ...cardInitialTouched,
  ...cashInitialTouched,
  ...depositInitialTouched,
  ...invoiceInitialTouched,
  ...transferInitialTouched,
  ...holdbackInitialTouched,
};

export const multiPaymentsInitialValues = {
  movementType: 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
  amount: '',
  diffMethods: false,
  selectedMethods: [],
  priceIsInvalid: '',
  methodSelected: '',
  paymentDate: obtenerFechaActual(),
  paymentTime: '',
  discount: 0,
  totallyPaidWithRefund: false,
  ...cardInitialValues,
  ...cashInitialValues,
  ...depositInitialValues,
  ...invoiceInitialValues,
  ...transferInitialValues,
  ...holdbackInitialValues,
};
