import * as Yup from 'yup';
import moment from 'moment';
import { pdfRule, xmlRule } from 'utils/validations';

const REQUIRED_MESSAGE = 'Este campo es obligatorio.';
const PERSONAL_USE_STRING_RULE_REQUESTER = {
  is: true,
  then: Yup.string().required(REQUIRED_MESSAGE),
  otherwise: Yup.string().nullable(),
};
const PERSONAL_USE_STRING_RULE = {
  is: true,
  then: Yup.string().nullable().max(200, 'El limite son 200 caracteres'),
  otherwise: Yup.string().nullable(),
};

const infLimit = moment().subtract(10, 'years');

const dateValidation = Yup.string()
  .test('valid-date', 'La fecha es invalida', (value) => {
    if (!value) {
      return true;
    }
    const isValid = moment(value, 'YYYY-MM-DD').isValid();
    return isValid;
  })
  .test('max-date', 'La fecha no puede ser mayor al día actual', (value) => {
    if (!value) {
      return true;
    }
    const date = moment(value, 'YYYY-MM-DD').toDate();
    return date <= new Date();
  });

export const purchaseValidationProps = {
  description: Yup.string()
    .required(REQUIRED_MESSAGE)
    .max(200, 'El limite son 200 caracteres'),
  personalUse: Yup.boolean().required(REQUIRED_MESSAGE),
  hasAdaptation: Yup.bool().required(REQUIRED_MESSAGE),
  owner: Yup.string().required(REQUIRED_MESSAGE),
  provider: Yup.string().required(REQUIRED_MESSAGE),
  buyDate: dateValidation
    .required(REQUIRED_MESSAGE)
    .test(
      'min-date',
      'La fecha de compra no debe ser mayor a 10 años de antigüedad.',
      (value) => {
        if (!value) {
          return true;
        }
        const date = moment(value, 'YYYY-MM-DD');
        return date.diff(infLimit, 'years') < 10;
      }
    ),
  personalUseRequester: Yup.string().when(
    'personalUse',
    PERSONAL_USE_STRING_RULE_REQUESTER
  ),
  personalUseRemarks: Yup.string().when(
    'personalUse',
    PERSONAL_USE_STRING_RULE
  ),
  invoicePrice: Yup.string()
    .required(REQUIRED_MESSAGE)
    .test(
      'invoicePrice',
      'El costo debe estar entre $ 50,000.00 y $ 9,999,999.99',
      (invoicePrice) => {
        if (invoicePrice) {
          invoicePrice = invoicePrice.replace(/[^0-9.]+/g, '');
          return (
            parseFloat(invoicePrice) >= 50000 &&
            parseFloat(invoicePrice) <= 9999999.99
          );
        } else {
          return false;
        }
      }
    ),
  invoiceNumber: Yup.string()
    .required(REQUIRED_MESSAGE)
    .min(2, 'El número de factura debe tener más de 2 carácteres.'),
  invoicePDF: Yup.mixed()
    .nullable()
    .test('fileFormat', 'El tipo de archivo debe ser pdf.', function (file) {
      const { invoiceNumber, initialInvoiceNumber } = this.parent;
      if (!file || invoiceNumber === initialInvoiceNumber) {
        return true;
      }
      return pdfRule(file);
    }),
  invoice: Yup.mixed()
    .nullable()
    .test('fileFormat', 'El tipo de archivo debe ser xml.', function (file) {
      const { invoiceNumber, initialInvoiceNumber } = this.parent;
      if (!file || invoiceNumber === initialInvoiceNumber) {
        return true;
      }
      return xmlRule(file);
    }),
  invoiceCopy: Yup.mixed()
    .nullable()
    .test('fileFormat', 'El tipo de archivo debe ser pdf.', function (file) {
      const { invoiceNumber, initialInvoiceNumber } = this.parent;
      if (!file || invoiceNumber === initialInvoiceNumber) {
        return true;
      }
      return pdfRule(file);
    }),
  purchaseOrder: Yup.string()
    .required(REQUIRED_MESSAGE)
    .min(2, 'El número de orden de compra debe tener más de 2 caracteres.'),
};

export const PurchaseOnlySchema = Yup.object().shape({
  ...purchaseValidationProps,
});
