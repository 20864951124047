export const invoiceInitialTouched = {
  invoiceSocialReason: true,
  invoiceRfc: true,
  invoiceCfdiUse: true,
  invoicePayForm: true,
  invoicePayMethod: true,
  emailInvoice: true,
  observationsInvoice: true,
  invoiceSelected: true,
  invoiceZipCode: true,
  invoiceTaxRegime: true,
  invoiceZipCodeExist: true,
};

export const invoiceInitialValues = {
  invoiceSocialReason: '',
  invoiceRfc: '',
  invoiceCfdiUse: '',
  invoicePayForm: '',
  invoicePayMethod: '',
  emailInvoice: '',
  observationsInvoice: '',
  invoiceSelected: '',
  invoiceZipCode: '',
  invoiceTaxRegime: '',
  invoiceZipCodeExist: false,
  isAdding: false,
};
