import _get from 'lodash/get';

export const transformer = (permanentChangeData) => {
  const transformedData = {
    finalKm: _get(permanentChangeData, 'finalKm', {}),
    // periodicity: _get(permanentChangeData, 'periodicity.uuid', ''),
    incident: _get(permanentChangeData, 'incident.uuid', ''),
    VEHICLE_A: _get(permanentChangeData, 'vehicleA', {}),
    total: _get(permanentChangeData, 'total', '').toString(),
    changeDate: _get(permanentChangeData, 'changeDate', '').toString(),
    reason: _get(permanentChangeData, 'reason', {}),
    uuid: permanentChangeData.uuid,
  };

  return transformedData;
};
