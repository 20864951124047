import * as yup from 'yup';
import i18n from '@i18n';
import { setParamsListOfReservations } from 'store/reservations/actions';
import { getBrach, getRoleName } from '../../../services/auth';

const userBranchUuid =
  getRoleName() != 'CONTACT_CENTER' && getBrach() ? getBrach() : '';

const schema = yup.object().shape({
  folioR: yup.string(),
  nameR: yup.string(),
  emailR: yup.string(),
  cellphoneR: yup.string(),
  createdDate: yup.string(),
  categoryR: yup.string(),
  deliveryDate: yup.string(),
  returnDate: yup.string(),
  daysDiffR: yup.string(),
  deliveryBranchR: yup.string(),
  observationsR: yup.string(),
  stepR: yup.string(),
  plateNumberR: yup.string(),
  createdByR: yup.string(),
  lineVersionR: yup.string(),
});

const initialValues = {
  folioR: '',
  nameR: '',
  emailR: '',
  cellphoneR: '',
  createdDate: '',
  categoryR: '',
  deliveryDate: '',
  returnDate: '',
  daysDiffR: '',
  deliveryBranchR: userBranchUuid,
  observationsR: '',
  stepR: '',
  plateNumberR: '',
  createdByR: '',
  lineVersionR: '',
};

export const initialTouched = {
  folioR: true,
  nameR: true,
  emailR: true,
  cellphoneR: true,
  createdDate: true,
  categoryR: true,
  deliveryDate: true,
  returnDate: true,
  daysDiffR: true,
  deliveryBranchR: true,
  observationsR: true,
  stepR: true,
  plateNumberR: true,
  createdByR: true,
  lineVersionR: true,
};

const formatter = (values) => {
  const { folioR } = values;
  return {
    folio: folioR == '' ? folioR : folioR.padStart(3, '0'),
    name: values.nameR,
    email: values.emailR,
    cellphone: values.cellphoneR,
    category: values.categoryR,
    daysDiff: values.daysDiffR,
    deliveryBranch: values.deliveryBranchR,
    observations: values.observationsR,
    step: values.stepR,
    plateNumber: values.plateNumberR,
    createdBy: values.createdByR,
    lineVersion: values.lineVersionR,
  };
};

export const config = {
  schema,
  modalType: 'empty',
  initialTouched,
  initialValues,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  formatter,
  submitForm: setParamsListOfReservations,
  withButtons: false,
  onSuccessAction: setParamsListOfReservations,
  dialogs: {
    confirmation: {},
    response: {
      successTitle: 'Reservación Cancelada',
      successMessage: 'La reservación fue cancelada correctamente',
      successLabel: 'Continuar',
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      errorLabel: i18n('COMMONS__CLOSE__TEXT'),
    },
  },
};
