import { useFormikContext } from 'formik';
import { options } from '@casanova/casanova-common/lib/Loader/code';
import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import useMultiPayment from './useMultiPayment';

export const BankContext = createContext();
export const MultiPaymentContext = createContext();

export const BankProvider = ({ children }) => {
  const bankToast = useRef(null);
  const [bankModal, setBankModal] = useState({ show: false, cardIndex: '' });
  const [selectedBank, setSelectedBank] = useState('');
  const { setFieldValue } = useFormikContext();
  const { setShowLoader, fetchCatalog } = useMultiPayment();

  const showToast = (detail, summary = 'Info', severity = 'info') => {
    bankToast.current.show({ severity, summary, detail });
  };

  const handleSaveNewBank = (name, valuesName) => {
    setShowLoader(true);
    const newBank = {
      name: name,
      legalName: name,
    };
    return handleAddBank(newBank, valuesName);
  };

  const handleAddBank = async (newBank, valuesName) => {
    try {
      const headers = options.headers || new Headers();

      const { authToken } = options;
      if (authToken) {
        headers.append('Authorization', `Bearer ${authToken}`);
        headers.append('Content-Type', 'application/json');
      }
      headers.append('Content-Type', 'application/json');

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/pricequotes/v1/catalogs/banks`,
        {
          method: 'POST',
          body: JSON.stringify(newBank),
          headers: headers,
          credentials: 'include',
        }
      );

      if (!response.ok) {
        throw new { code: '303', error: 'Network response was not ok' }();
      }

      const data = await response.json();
      fetchCatalog({
        catalogId: 'catalogs/banks',
        catalogPath: 'pricequotes',
      });

      setFieldValue(valuesName.bank, data.uuid);
      setShowLoader(false);
      setFieldValue(valuesName.otherBank, '');
      showToast(
        'Selecciona el banco que has agregado.',
        'Banco agregado',
        'info'
      );
      return false;
      // setFieldValue('holdbackBank', 'caadf013-a8ee-4904-b85c-a8b139562187');
    } catch (error) {
      console.log('error', error);
      setShowLoader(false);
      showToast(
        'El banco que intentas agregar ya existe o vuelve a intentarlo más tarde.',
        'Error',
        'error'
      );
      return true;
    }
  };

  return (
    <BankContext.Provider
      value={{
        selectedBank,
        setSelectedBank,
        handleSaveNewBank,
        bankModal,
        setBankModal,
        bankToast,
      }}
    >
      {children}
    </BankContext.Provider>
  );
};

export const MultiPaymentProvider = ({ children, props }) => {
  const { setFieldValue, values } = useFormikContext();

  const [hasTotalPay, setHasTotalPay] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [hasUpdatedHoldBack, setHasUpdatedHoldBack] = useState(false);
  const [hasUpdateDifMethods, setHasUpdateDifMethods] = useState(false);

  const handleMethodChange = useCallback(() => {
    if (hasUpdatedHoldBack) {
      setFieldValue('holdbackCard', '');
      setFieldValue('holdbackBank', '');
    }
  }, [hasUpdatedHoldBack]);

  const handleDifMethodsChange = () => {
    if (hasUpdateDifMethods) {
      setHasUpdateDifMethods(false);
      setHasTotalPay(0);
      values.amountCard_0 = '';
      values.amountCard_1 = '';
      values.amountCard_2 = '';
      values.amountCash = '';
      values.amountTransfer = '';
      values.amountDeposit = '';
      values.methodSelected = '';
      values.selectedMethods = [];
      return;
    }
    setHasUpdateDifMethods(true);
  };

  const sumaTotal = () =>
    Number(values.amountCard_0) +
    Number(values.amountCard_1) +
    Number(values.amountCard_2) +
    Number(values.amountCash) +
    Number(values.amountDeposit) +
    Number(values.amountTransfer);

  useEffect(() => {
    const sumAmounts = sumaTotal();
    setHasTotalPay(sumAmounts);
    setFieldValue(
      'priceIsInvalid',
      sumAmounts.toFixed(2) === Number(values.amount).toFixed(2)
    );
  }, [
    values.amountCard_0,
    values.amountCard_1,
    values.amountCard_2,
    values.amountCash,
    values.amountTransfer,
    values.amountDeposit,
  ]);

  useEffect(() => {
    setFieldValue('diffMethods', hasUpdateDifMethods);
  }, [hasUpdateDifMethods]);

  return (
    <MultiPaymentContext.Provider
      value={{
        showLoader,
        setShowLoader,
        hasTotalPay,
        hasUpdatedHoldBack,
        setHasUpdatedHoldBack,
        hasUpdateDifMethods,
        handleMethodChange,
        handleDifMethodsChange,
        ...props,
      }}
    >
      <BankProvider>{children}</BankProvider>
    </MultiPaymentContext.Provider>
  );
};
