import { useEffect, useCallback, useState } from 'react';
import { lettersAndNumbersWithSpaces } from 'utils/masks';
import _isEmpty from 'lodash/isEmpty';
import SearchInput from 'components/SearchInput';
import { SercheableSelect } from 'components';
import { fieldSearch } from '@casanova/casanova-common/lib/utils/transformHelpers';
import useCleanModule from 'hooks/useCleanModule';
import i18n from '@i18n';
import { Button, withForm } from '@casanova/casanova-common';
import { useFormikContext } from 'formik';
import config from './config';

function ContractSearch({
  contractDetails,
  hasNotFound,
  getContractDetails,
  resetDetail,
  searchJuridicalCustomer,
  history,
}) {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    submitForm,
  } = useFormikContext();
  const [hasSearched, setHasSearched] = useState(false);
  useCleanModule({ module: 'paymentAndCharges' });

  const handleSearch = useCallback(({ searchText }) => {
    getContractDetails(searchText);
    setHasSearched(true);
  }, []);

  useEffect(() => {
    if (!_isEmpty(contractDetails) && hasSearched) {
      const { number } = contractDetails;
      history.push(`/payments-and-charges/${number}/detail`);
    }
  }, [contractDetails, hasSearched]);

  const handleSelectCustomer = useCallback(
    (value) => {
      setFieldTouched('customer', true);
      setFieldValue('customer', value);
    },
    [setFieldValue, setFieldTouched]
  );

  const handleSearchCustomer = useCallback(
    (query) => {
      if (query && query.length > 2) {
        setFieldValue('customer', '');
        searchJuridicalCustomer(query, 'uuid');
      }
    },
    [setFieldValue, searchJuridicalCustomer]
  );

  const handleBlurCustomer = useCallback(() => {
    setFieldTouched(fieldSearch('customer'), true);
    setFieldTouched('customer', true);
  }, [setFieldTouched]);

  const handleRemove = useCallback(() => {
    setFieldValue(fieldSearch('customer'), '');
    setFieldValue('customer', '');
    resetDetail();
  }, [setFieldValue, resetDetail]);

  return (
    <section className="row">
      <div className="col-6">
        <div className="col-md-12">
          <SearchInput
            name="searchText"
            placeholder="Buscar contrato"
            subtitle="Buscar por ID de contrato."
            onSubmit={handleSearch}
          />
        </div>
        {hasNotFound && (
          <div className="row">
            <div className="col-12">
              <h3 className="text-center">
                {i18n('ERROR__COMMONS__NOT_FOUND')}
              </h3>
            </div>
          </div>
        )}
      </div>
      <div className="col-6">
        <div className="row">
          <div className="col-7">
            <SercheableSelect
              onChange={handleSelectCustomer}
              onBlur={handleBlurCustomer}
              onSearching={handleSearchCustomer}
              name="customer"
              label={i18n('RESERVATIONS__SEARCH_CLIENT')}
              placeholder={i18n('RESERVATIONS__SEARCH_CLIENT')}
              options="customers.juridicalCustomers"
              mask={lettersAndNumbersWithSpaces(100)}
              infoText={i18n('RESERVATIONS__SEARCH_LEYENT__CLIENT_LEGAL')}
              icon="svg-icon-search"
              remove
              onRemove={handleRemove}
              showRightIcon={!values.customer}
            />
          </div>
          <div className="col" style={{ marginTop: '2rem' }}>
            <Button className="px-4" onClick={submitForm}>
              Buscar
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default withForm({ config })(ContractSearch);
