import { multiPaymentsInitialValues } from '../sectionsMethodsPayment/MultiPayments/config';

const transformerContract = (contractsDetails) =>
  contractsDetails.map((cd) => ({
    contractUuid: cd.uuid,
    contractNumber: cd.number,
    charges: Math.abs(cd.balance),
    payment: 0,
    balance: Math.abs(cd.balance),
    contractStatus: cd.status,
  }));

const transformer = (context) => ({
  contractsDetails: transformerContract(context),
  customerUuid: context[0].naturalPerson,
  customer: context[0].naturalPersonName,
  ...multiPaymentsInitialValues,
});

export default transformer;
