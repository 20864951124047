import { removeSpaces } from '@casanova/casanova-common/lib/utils/masks';

const formatHoldback = ({
  needHoldback,
  holdbackRetention,
  holdbackOtherRetention,
  holdbackCardHolder,
  holdbackBank,
  holdbackCard,
  holdbackCode,
  holdbackValidity,
}) => {
  if (!needHoldback || holdbackRetention == 0) return { amountWithheld: 0 };
  const elements = holdbackValidity.split('/');
  const month = parseInt(elements[0], 10);
  const year = parseInt(elements[1], 10) + 2000;
  const realMonth = month >= 10 ? month : `0${month}`;

  const retention = holdbackRetention.toLowerCase().includes('otro')
    ? parseFloat(holdbackOtherRetention)
    : parseFloat(holdbackRetention);

  return {
    bank: holdbackBank,
    cardHolderName: holdbackCardHolder,
    cardNumber: removeSpaces(holdbackCard),
    cvc: holdbackCode,
    expDate: `${year}-${realMonth}-01`,
    amountWithheld: retention,
  };
};

export default formatHoldback;
