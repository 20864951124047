import types from './action-types';

export function closeResponseDialog() {
  return { type: types.CLOSE_RESPONSE_DIALOG };
}

export function getInvoicesList(payload) {
  return { type: types.GET_LIST, payload };
}

export function getInvoicesListSucceed(payload) {
  return { type: types.GET_LIST_SUCCEED, payload };
}

export function getInvoicesListFailed(payload) {
  return { type: types.GET_LIST_FAILED, payload };
}

export function setListParams(payload) {
  return { type: types.SET_PARAMS, payload };
}

export function resetListParams(payload) {
  return { type: types.RESET_PARAMS, payload };
}

export function setListPage(payload) {
  return { type: types.SET_LIST_PAGE, payload };
}

export function getInvoiceDetails(payload) {
  return { type: types.GET_INVOICE_DETAILS, payload };
}

export function getInvoiceDetailsSuccess(payload) {
  return { type: types.GET_INVOICE_DETAILS_SUCCEED, payload };
}

export function getInvoiceDetailsFailed(payload) {
  return { type: types.GET_INVOICE_DETAILS_FAILED, payload };
}

export function cleanInvoiceDetails(payload) {
  return { type: types.CLEAN_INVOICE_DETAILS, payload };
}

export function getListOfCRPs(payload) {
  return { type: types.GET_LIST_OF_CRPS, payload };
}

export function getListOfCRPsSucceed(payload) {
  return { type: types.GET_LIST_OF_CRPS_SUCCEED, payload };
}

export function getListOfCRPsFailed(payload) {
  return { type: types.GET_LIST_OF_CRPS_FAILED, payload };
}

export function setListOfCRPs(payload) {
  return { type: types.SET_LIST_OF_CRPS, payload };
}

export function setCrpsParams(payload) {
  return { type: types.SET_CRPS_PARAMS, payload };
}

export function setCRPsPage(payload) {
  return { type: types.SET_CRPS_LIST_PAGE, payload };
}

export function saveCRP(payload) {
  return { type: types.SAVE_CRP, payload };
}

export function saveCRPSucceed(payload) {
  return { type: types.SAVE_CRP_SUCCEED, payload };
}

export function saveCRPFailed(payload) {
  return { type: types.SAVE_CRP_FAILED, payload };
}

export function reInvoice(payload) {
  return { type: types.RE_INVOICE, payload };
}

export function reInvoiceSucceed(payload) {
  return { type: types.RE_INVOICE_SUCCEED, payload };
}

export function reInvoiceFailed(payload) {
  return { type: types.RE_INVOICE_FAILED, payload };
}
