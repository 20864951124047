import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import moment from 'moment';
import {
  Button,
  Section,
  DatePickerV2,
  MaskedInput as Input,
  ConnectedSelect,
  NumberInput,
  Select,
  ActionModal,
  Icons,
} from '@casanova/casanova-common';
import { QUOTE_CONTRACT_QUERY } from 'utils/navigation';
import { SearchableSelect } from 'components';
import i18n from '@i18n';
import { isSafari } from 'utils/browser';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { alphaMaskWithSpacesAndAccent } from 'utils/masks';
import { fieldSearch } from '@casanova/casanova-common/lib/utils/transformHelpers';
import {
  formattedDateToPickerV2,
  getNextYear,
  timeZone,
  YYYY_MM_DD,
} from '@casanova/casanova-common/lib/utils/date';
import { AMOUNT_MAX, schema } from './schema';
import _get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { branch } from 'recompose';
import { getBussinesAreaList } from '../../../../services/contracts/index.js';
import { formatter } from './formatter';
import ResponseDialog from 'components/ResponseDialog';
import { EDIT_VIRTUAL_CONTRACT } from 'utils/roles/permissions/contractsPermissions';
import { validateRolePermissions } from 'utils/roles/index';

export default function ContractInformation({
  action = FORM_ACTIONS.ADD,
  searchTenderCustomer,
  fetchCatalog,
  contractDetails,
  updateContract,
  responseDialogUpdateContract,
  closeResponseDialogUpdateContract,
  getContractDetails,
  match,
}) {
  const {
    initialValues,
    setFieldTouched,
    setFieldValue,
    values,
    errors,
    validateForm,
  } = useFormikContext();

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const { startDate, customer } = values;
  const { VIEW, EDIT } = FORM_ACTIONS;
  const [permitedBranches, setPermitedBranches] = useState([]);
  const [actionForm, setActionForm] = useState(action);
  const [isShowActionModalOpen, setIsShowActionModalOpen] = useState(false);

  const contractId = match.params.id;

  const branches = useSelector((state) =>
    _get(state, 'commons.branches.list', [])
  );

  const handleSelectCustomer = useCallback(
    (plate) => {
      setFieldTouched('customer', true);
      setFieldValue('customer', plate);
    },
    [setFieldValue, setFieldTouched]
  );

  const handleSearchTenderCustomer = useCallback(
    (query) => {
      if (query && query.length > 2) {
        setFieldValue('customer', '');
        searchTenderCustomer(query);
      }
    },
    [setFieldValue, searchTenderCustomer]
  );

  const handleBlurCustomer = useCallback(() => {
    setFieldTouched(fieldSearch('customer'), true);
    setFieldTouched('customer', true);
  }, [setFieldTouched]);

  useEffect(() => {
    fetchCatalog({
      catalogPath: 'pricequotes',
      catalogId: 'catalogs/business-areas',
    });
    fetchCatalog('branches');
  }, [fetchCatalog]);

  const startDateMaximumDate = formattedDateToPickerV2(
    moment().add(3, 'months').tz(timeZone).format(YYYY_MM_DD)
  );

  const endDateMinimumDate = useMemo(
    () =>
      startDate &&
      formattedDateToPickerV2(
        moment(startDate).add(2, 'day').tz(timeZone).format(YYYY_MM_DD)
      ),
    [startDate]
  );

  const history = useHistory();

  const handleAddCustomer = useCallback(() => {
    setTimeout(() => {
      history.push(`/contracts/customer/add`);
    }, 1);
  }, [history]);

  const [getBussinesArea, setBussinesArea] = useState([]);
  const getList = async () => {
    const bussinesList = await getBussinesAreaList();
    const rest = bussinesList.content.filter(
      (e) => e.identifier != 'DAILY_RENTAL'
    );
    const filter = rest.map((e) => {
      return {
        uuid: e.uuid,
        value: e.uuid,
        label: e.name,
      };
    });
    setBussinesArea(filter);
  };

  useEffect(() => {
    getList();
  }, []);

  const [getBranchSession, setBranchSession] = useState();
  const handleEditContract = () => {
    setIsShowActionModalOpen(false);
    updateContract(formatter(values));
  };

  const handleCancelEditContract = () => {
    setActionForm(VIEW);
    updateForm(contractDetails.data);
  };

  const handelOnCloseConfirm = () => {
    closeResponseDialogUpdateContract();
    setActionForm(VIEW);
    getContractDetails({ uuid: contractId });
  };

  const updateForm = (contractInfo) => {
    const transformedData = {
      businessArea: _get(contractInfo, 'businessArea.uuid', ''),
      customer: _get(contractInfo, 'juridicalPerson', ''),
      [fieldSearch('customer')]: _get(contractInfo, 'juridicalPersonName', ''),
      startDate: _get(contractInfo, 'startDate', ''),
      endDate: _get(contractInfo, 'endDate', ''),
      no_contract: _get(contractInfo, 'no_contract', ''),
      folio: _get(contractInfo, 'folio', ''),
      rfc:
        _get(contractInfo, 'rfc', 'Sin Información') != ''
          ? _get(contractInfo, 'rfc', 'Sin Información')
          : 'Sin Información',
      noContrato: _get(contractInfo, 'noContrato', ''),
      maxAmount: _get(contractInfo, 'maxAmount', ''),
      minAmount: _get(contractInfo, 'minAmount', ''),
      naturalName: _get(contractInfo, 'naturalName', ''),
      branch: _get(contractInfo, 'branch', ''),
    };
    Object.entries(transformedData).forEach(([key, value]) =>
      setFieldValue(key, value)
    );
  };

  useEffect(() => {
    if (branches <= 0) return;
    const branchSession = localStorage.getItem('br');
    const onlySucursal = branches.filter((e) => e.uuid == branchSession);
    setPermitedBranches(onlySucursal);
    setBranchSession(branchSession);
  }, [branches]);

  const InputView = () => (
    <>
      <div className="col-md-4">
        <Input
          placeholder="RFC"
          name="rfc"
          label="RFC"
          disabled={disabledInput}
        />
      </div>
      <div className="col-md-4">
        <Input
          placeholder="nombre del contacto"
          name="naturalName"
          label="nombre del contacto"
          disabled={disabledInput}
        />
      </div>
      <div className="col-md-4">
        <Input
          placeholder="Folio"
          name="folio"
          label="Folio"
          disabled={disabledInput}
        />
      </div>
    </>
  );

  const disabledInput = actionForm === FORM_ACTIONS.VIEW;
  return (
    <Section title={i18n('CONTRACTS__CONTRACT_DETAIL__TITLE')} upperCase>
      <ActionModal
        open={isShowActionModalOpen}
        icon={<Icons.StatusWarning />}
        onClose={() => setIsShowActionModalOpen(false)}
        onAction={handleEditContract}
        title="Modificar contrato"
        message="¿Está seguro de ejecutar los cambios?"
        actionLabel="Aceptar"
        closeLabel="Cancelar"
      />
      <ResponseDialog
        open={responseDialogUpdateContract.open}
        success={responseDialogUpdateContract.success}
        errorCode={responseDialogUpdateContract.errorCode}
        successTitle="CONTRATO MODIFICADO"
        successLabel={i18n('COMMONS__CLOSE__TEXT')}
        successMessage="El contrato fue modificado exitosamente"
        onError={handelOnCloseConfirm}
        onClose={handelOnCloseConfirm}
        onSuccess={handelOnCloseConfirm}
        errorLabel={i18n('COMMONS__CLOSE__TEXT')}
      />
      <div className="row">
        <div className="col-md-4">
          <DatePickerV2
            initialValue={initialValues.startDate}
            placeholder={i18n('CONTRACTS__CONTRACT_DETAIL__START_DATE', ['*'])}
            name="startDate"
            label={i18n('CONTRACTS__CONTRACT_DETAIL__START_DATE', ['*'])}
            disabled={disabledInput}
            // placement={isSafari() ? 'bottom' : undefined}
            maximumDate={startDateMaximumDate}
          />
        </div>
        <div className="col-md-4">
          <DatePickerV2
            initialValue={initialValues.endDate}
            placeholder={i18n('CONTRACTS__CONTRACT_DETAIL__END_DATE', ['*'])}
            name="endDate"
            label={i18n('CONTRACTS__CONTRACT_DETAIL__END_DATE', ['*'])}
            disabled={disabledInput}
            // placement={isSafari() ? 'bottom' : undefined}
            minimumDate={endDateMinimumDate}
            maximumDate={getNextYear({ years: 5 })}
          />
        </div>
        <div className="col-md-4">
          <SearchableSelect
            onChange={handleSelectCustomer}
            onBlur={handleBlurCustomer}
            name="customer"
            label={i18n('CONTRACTS__CONTRACT_DETAIL__CUSTOMER', ['*'])}
            placeholder={i18n('CONTRACTS__CONTRACT_DETAIL__CUSTOMER', ['*'])}
            options="customers.tenderCustomers"
            mask={alphaMaskWithSpacesAndAccent(100)}
            onSearching={handleSearchTenderCustomer}
            icon="svg-icon-search"
            disabled={disabledInput}
            infoText={
              actionForm !== FORM_ACTIONS.VIEW
                ? 'La búsqueda de cliente es por razón social o RFC.'
                : undefined
            }
            remove={!disabledInput}
            showRightIcon={!customer}
            onRemove={() => {
              setFieldValue('customer', '');
              setFieldValue('customer_search', '');
              setFieldValue('customer_lastSearch', '');
              setFieldTouched('customer', true);
              setFieldTouched('customer_search', true);
              setFieldTouched('customer_lastSearch', true);
            }}
          />
        </div>
        {/* <div className="col-md-4">
          <NumberInput
            placeholder={i18n('CONTRACTS__CONTRACT_DETAIL__AMOUNT', ['*'])}
            name="amount"
            label={i18n('CONTRACTS__CONTRACT_DETAIL__AMOUNT', ['*'])}
            disabled={disabledInput}
            decimals={2}
            max={AMOUNT_MAX}
            min={1}
          />
        </div> */}
        <div className="col-md-4">
          <Select
            placeholder={i18n('CONTRACTS__CONTRACT_DETAIL__BUSINESS_UNIT', [
              '*',
            ])}
            name="businessArea"
            label={i18n('CONTRACTS__CONTRACT_DETAIL__BUSINESS_UNIT', ['*'])}
            disabled={disabledInput}
            options={getBussinesArea}
          />
        </div>
        <div className="col-md-4">
          <Input
            placeholder="No. De Contrato *"
            name="noContrato"
            label="No. De Contrato *"
            disabled={disabledInput || actionForm === EDIT}
          />
        </div>
        <div className="col-md-4">
          <NumberInput
            placeholder="Monto mínimo *"
            name="minAmount"
            label="Monto mínimo *"
            disabled={disabledInput}
            decimals={2}
            max={AMOUNT_MAX}
            min={1}
          />
        </div>
        <div className="col-md-4">
          <NumberInput
            placeholder="Máximo de operación *"
            name="maxAmount"
            label="Máximo de operación *"
            disabled={disabledInput}
            decimals={2}
            max={AMOUNT_MAX}
            min={1}
          />
        </div>
        <div className="col-md-4">
          <ConnectedSelect
            disabled
            // disabled={actionForm === VIEW}
            label="Sucursal *"
            name="branch"
            options="commons.branches"
          />
        </div>
        {actionForm === 'view' ? <InputView /> : ''}
        <div className="col-md-4">
          {actionForm !== 'view' ? (
            <Button
              className="px-5"
              style={{ marginTop: '2rem' }}
              onClick={handleAddCustomer}
            >
              Nuevo Cliente
            </Button>
          ) : (
            ''
          )}
        </div>
        <div className="col-md-4">
          <Input
            placeholder="Gerente *"
            name="manager"
            label="Gerente *"
            disabled={disabledInput}
          />
        </div>
        <div className="col-md-4">
          <Input
            placeholder="ADMINISTRADOR DE FLOTA *"
            name="vehiclesAdmin"
            label="ADMINISTRADOR DE FLOTA *"
            disabled={disabledInput}
          />
        </div>
        <div className="col-md-4">
          <Input
            placeholder="COORDINADOR *"
            name="coordinator"
            label="COORDINADOR *"
            disabled={disabledInput}
          />
        </div>
        {validateRolePermissions(EDIT_VIRTUAL_CONTRACT) &&
          actionForm !== FORM_ACTIONS.ADD && (
            <div className="col-md-2 mr-1">
              <Button
                className="px-5"
                style={{ marginTop: '2rem' }}
                onClick={
                  actionForm === EDIT
                    ? () => {
                        validateForm().then((errors) => {
                          if (
                            Object.keys(errors).length === 0 &&
                            errors.constructor === Object
                          ) {
                            setIsShowActionModalOpen(true);
                          }
                        });
                      }
                    : () => setActionForm(EDIT)
                }
              >
                {actionForm === EDIT ? 'Aceptar' : 'Editar'}
              </Button>
            </div>
          )}
        {actionForm === EDIT && (
          <div className="col-md-4">
            <Button
              className="px-5"
              style={{ marginTop: '2rem' }}
              outline
              onClick={handleCancelEditContract}
            >
              Cancelar
            </Button>
          </div>
        )}
      </div>
    </Section>
  );
}
