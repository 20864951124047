const formatDeposit = ({
  bankAuthorization,
  paymentDate,
  paymentTime,
  bankVoucher,
  amountDeposit,
  amount,
  diffMethods,
}) => {
  return {
    paymentForm: 'DEPOSIT',
    depositDetails: {
      bankAuthorization,
    },
    paymentDate,
    paymentTime,
    files: {
      paymentProof: bankVoucher,
    },
    amount: diffMethods ? Number(amountDeposit) : Number(amount),
  };
};

export default formatDeposit;
