const formatTransfer = ({
  bankTo,
  bankClave,
  bankReference,
  paymentDate,
  paymentTime,
  transferVoucher,
  amountTransfer,
  amount,
  diffMethods,
}) => {
  const transferDetails = {
    bank: bankTo,
    referenceNumber: bankReference,
    trackingKey: bankClave,
  };

  return {
    paymentForm: 'TRANSFER',
    transferDetails: transferDetails,
    paymentDate,
    paymentTime,
    files: {
      paymentProof: transferVoucher,
    },
    amount: diffMethods ? Number(amountTransfer) : Number(amount),
  };
};

export default formatTransfer;
