import withReservationAuth from '@hocs/withReservationAuth';
import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { withForm, Icons } from '@casanova/casanova-common';
import { Section } from 'components';
import { scrollTop } from 'utils/screen';
import config from './config';
import './Payment.scss';
import _isEmpty from 'lodash/isEmpty';
import { MultiPayments } from '../../Payments/sectionsMethodsPayment';

const PaymentExtraCharges = ({
  match,
  reservationInfo = {},
  fetchCatalog,
  getCustomer,
  setNewInvoicePayment,
  setHasAddedBillingDetailsOfPayment,
  customerJuridical,
  priceExtraCharge,
  customerRefund,
  onUpdateForm,
}) => {
  const { naturalPerson = {}, folio = '' } = reservationInfo;

  const { setFieldValue, touched, values } = useFormikContext();

  const isJuridical = Boolean(customerJuridical);

  const hasRefund =
    !_isEmpty(customerRefund) && folio == customerRefund.reservationFolio;

  const totalAfterRefund =
    priceExtraCharge - (hasRefund ? customerRefund.amount : 0);

  const withoutPay = hasRefund && totalAfterRefund <= 0;

  const billDetails = {
    customerFullName: naturalPerson.fullName,
    folio,
    concept: 'Cargos extras',
  };

  useEffect(() => {
    setFieldValue('amount', totalAfterRefund);
  }, [totalAfterRefund]);

  useEffect(() => {
    setFieldValue('totallyPaidWithRefund', withoutPay);
  }, [withoutPay]);

  useEffect(() => {
    if (customerRefund?.amount && hasRefund) {
      setFieldValue('discount', customerRefund?.amount);
    }
  }, [customerRefund.amount, hasRefund]);

  useEffect(() => {
    setFieldValue('reservationId', match.params.id);
  }, [setFieldValue, match.params]);

  useEffect(() => {
    fetchCatalog({
      catalogId: 'catalogs/banks',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/cfdi-fiscal-regime/natural-person',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/cfdi-fiscal-regime/juridical-person',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/bank-card-types',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/cfdi-uses',
      catalogPath: 'pricequotes',
    });
    fetchCatalog('branches');
    fetchCatalog({
      catalogId: 'catalogs/payment-forms',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/payment-methods',
      catalogPath: 'pricequotes',
    });
  }, [fetchCatalog]);

  useEffect(() => {
    if (naturalPerson.uuid) {
      getCustomer(naturalPerson.uuid);
      // setFieldValue('reservationId', reservationInfo.uuid);
    }
  }, [getCustomer, naturalPerson?.uuid]);

  useEffect(() => {
    scrollTop();
    // Reset added billing details flag
    setHasAddedBillingDetailsOfPayment(false);

    return () => {
      setNewInvoicePayment(false);
      setHasAddedBillingDetailsOfPayment(false);
    };
  }, []);

  useEffect(() => {
    if (values.invoiceTaxRegime !== '') {
      fetchCatalog({
        catalogId: 'catalogs/cfdi-uses/fiscal-regime',
        params: {
          uuid: values.invoiceTaxRegime,
          isJuridical,
        },
        catalogPath: 'pricequotes',
      });
    }
  }, [values.invoiceTaxRegime]);

  return (
    <Section title="Formas de pago">
      <div className={withoutPay ? 'blur' : '' + 'relative'}>
        <div className={withoutPay ? 'disable' : ''}></div>
        <MultiPayments
          touched={touched}
          onUpdateForm={onUpdateForm}
          uuidCustomer={naturalPerson.uuid}
          fetchCatalog={fetchCatalog}
          billDetails={billDetails}
        />
        <div className="col">
          <span style={{ marginRight: '1rem' }}>
            <Icons.Coment height="2rem" />
          </span>
          * Campos obligatorios.
        </div>
      </div>
    </Section>
  );
};

export default withReservationAuth(withForm({ config })(PaymentExtraCharges));
