/* eslint-disable func-names */
import * as yup from 'yup';
import 'moment/locale/es';
import i18n from '@i18n';
import multiPaymentSchema from '../sectionsMethodsPayment/MultiPayments/schema';

const string = yup.string().required(i18n('ERROR__COMMONS__REQUIRED_FIELD'));

const schema = yup.object().shape({
  contractNumber: yup.string(),
  contractUuid: yup.string(),
  customerUuid: yup.string(),
  movementType: string,
  amount: yup
    .number()
    .min(1, i18n('ERROR__COMMONS__NUMBER_AMOUNT__INVALID_MIN', [1]))
    .required(i18n('ERROR__COMMONS__REQUIRED_FIELD')),
  branch: string,
  paymentForm: yup.string(),
  requester: string,
  requester_search: string
    .min(3, i18n('ERROR__COMMONS__MIN_LENGTH', [3]))
    .test(
      'required-requester-search',
      i18n('ERROR__COMMONS__REQUIRED__ITEM_OF_LIST', ['un solicitante']),
      function (value) {
        return value && this.parent.requester;
      }
    ),
  concept: string,
  reason: string,
  observations: yup.string().notRequired(),
  error: yup.string().notRequired(),
  ...multiPaymentSchema,
});

export default schema;
