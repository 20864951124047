import { withLayout } from '@casanova/casanova-common';
import { connect } from 'react-redux';
import {
  closeResponseDialog,
  getContractDetails,
  cleanContractDetails,
  updateContract,
  closeResponseDialogUpdateContract,
} from 'store/contracts/actions';
import { fetchCatalog } from 'store/commons/actions';
import {
  searchJuridicalCustomer,
  searchTenderCustomer,
  reset as resetCustomer,
} from 'store/customers/actions';
import Contract from './Contract';
import _get from 'lodash/get';

export default withLayout(
  connect(
    (state) => ({
      onCancelPath: '/contracts',
      responseDialog: state.contracts.responseDialog,
      contractDetails: state.contracts.contractDetails,
      responseDialogUpdateContract: _get(
        state,
        'contracts.responseDialogUpdateContract',
        {}
      ),
    }),
    {
      closeResponseDialog,
      getContractDetails,
      cleanContractDetails,
      fetchCatalog,
      searchJuridicalCustomer,
      searchTenderCustomer,
      resetCustomer,
      updateContract,
      closeResponseDialogUpdateContract,
    }
  )(Contract)
);
