import { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import {
  MaskedInput as Input,
  FileInput,
  NumberInput,
  Button,
} from '@casanova/casanova-common';
import { ConnectedSelect, DatePickerV2 } from 'components';
import { PaymentType } from '@reservations/components';
import {
  alphaMask,
  hourMask,
  alphaMaskWithSpacesAndAccent,
  userMask,
} from 'utils/masks';
import { transferInitialValues } from './config';
import useMultiPayment from '../hooks/useMultiPayment';
import useBank from '../hooks/useBank';

const buttonGuardarBancoStyle = {
  backgroundColor: '#450E99',
  color: 'white',
  padding: '15px 32px',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '16px',
  margin: '4px 2px',
  marginTop: '10px',
  cursor: 'pointer',
  border: 'none',
  borderRadius: '4px',
};

const TransferMethod = ({ name }) => {
  const { values, setFieldValue } = useFormikContext();
  const { handleSaveNewBank } = useBank();
  const { handleMethodChange, hasUpdateDifMethods } = useMultiPayment();

  const [otroBanco, setOtroBanco] = useState(false);

  const valuesName = {
    bank: 'bankTo',
    otherBank: 'transferOtherBankName',
  };

  const handleChangeBanco = (e) => {
    setFieldValue('bankTo', e.target.value);
    if (e.target.value === 'c1f56866-0904-499b-a3fe-1b70a3e207b3') {
      setOtroBanco(true);
      return;
    }
    setOtroBanco(false);
  };

  const handleNewBank = () => {
    const passed = handleSaveNewBank(values.transferOtherBankName, valuesName);
    setOtroBanco(passed);
  };

  return (
    <PaymentType
      name={name}
      onSelect={handleMethodChange}
      initialValues={transferInitialValues}
      hasUpdateDifMetods={hasUpdateDifMethods}
      title="TRANSFERENCIA ELECTRÓNICA"
    >
      <div className="row">
        <div className="col-6">
          <ConnectedSelect
            label="Banco EMISOR DEL PAGO*"
            name="bankTo"
            options="commons.catalogsBanks"
            onChange={handleChangeBanco}
          />
        </div>
        {otroBanco && (
          <Fragment>
            <div className="col-md-6 col-sm-12">
              <Input
                mask={alphaMaskWithSpacesAndAccent(40)}
                maskPlaceholder=""
                label="Otro Banco*"
                placeholder="Ingresa nuevo banco*"
                name="transferOtherBankName"
                disabled={!otroBanco}
              />
            </div>
            <div className="col-md-6 col-sm-12 d-flex align-items-center">
              <Button style={buttonGuardarBancoStyle} onClick={handleNewBank}>
                Guardar Banco
              </Button>
            </div>
          </Fragment>
        )}
        <div className="col-6">
          <Input
            mask={alphaMask(30)}
            maskPlaceholder=""
            label="CLAVE DE RASTREO*"
            placeholder="CLAVE DE RASTREO*"
            name="bankClave"
          />
        </div>
        <div className="col-6">
          <Input
            mask={userMask(31)}
            maskPlaceholder=""
            label="NO. DE REFERENCIA*"
            placeholder="NO. DE REFERENCIA*"
            name="bankReference"
          />
        </div>
        <div className="col-6">
          <DatePickerV2
            label="Fecha*"
            name="paymentDate"
            placeholder="Fecha*"
            disabled
          />
        </div>
        <div className="col-6">
          <Input
            mask={hourMask}
            maskPlaceholder=""
            label="HORA*"
            placeholder="Hora*"
            name="paymentTime"
            disabled
          />
        </div>
        <div className="col-md-6">
          <FileInput
            url={values.transferVoucher}
            displayText={values.transferVoucher}
            type="file"
            label="Comprobante*"
            placeholder="Comprobante*"
            name="transferVoucher"
            showIcon={false}
          />
        </div>
        <div className="col-6">
          {hasUpdateDifMethods && (
            <NumberInput
              decimals={2}
              max={9_999_999}
              label="Monto en Transferencia"
              placeholder="Monto en Transferencia"
              name="amountTransfer"
              disabled={!hasUpdateDifMethods}
            />
          )}
        </div>
      </div>
    </PaymentType>
  );
};

TransferMethod.propTypes = {
  name: PropTypes.string,
  onSelect: PropTypes.func,
  hasUpdateDifMethods: PropTypes.bool,
};

TransferMethod.defaultProps = {
  name: 'transfer',
};

export default TransferMethod;
