import _get from 'lodash/get';
import { fieldSearch } from '@casanova/casanova-common/lib/utils/transformHelpers';
import branch from 'recompose/branch';

export const contractInfoTransformer = (contractInfo) => {
  console.log(contractInfo);
  const transformedData = {
    businessArea: _get(contractInfo, 'businessArea.uuid', ''),
    customer: _get(contractInfo, 'juridicalPerson', ''),
    [fieldSearch('customer')]: _get(contractInfo, 'juridicalPersonName', ''),
    startDate: _get(contractInfo, 'startDate', ''),
    endDate: _get(contractInfo, 'endDate', ''),
    no_contract: _get(contractInfo, 'no_contract', ''),
    folio: _get(contractInfo, 'folio', ''),
    rfc:
      _get(contractInfo, 'rfc', 'Sin Información') != ''
        ? _get(contractInfo, 'rfc', 'Sin Información')
        : 'Sin Información',
    noContrato: _get(contractInfo, 'noContrato', ''),
    maxAmount: _get(contractInfo, 'maxAmount', ''),
    minAmount: _get(contractInfo, 'minAmount', ''),
    naturalName: _get(contractInfo, 'naturalName', ''),
    branch: _get(contractInfo, 'branch', ''),
    manager: _get(contractInfo, 'manager', 'Sin Información'),
    vehiclesAdmin: _get(contractInfo, 'vehiclesAdmin', 'Sin Información'),
    coordinator: _get(contractInfo, 'coordinator', 'Sin Información'),
  };
  return transformedData;
};
