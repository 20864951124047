import React, { useCallback, useMemo, useState } from 'react';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { useFormikContext } from 'formik';

import { pdf } from '@react-pdf/renderer';

import PropTypes from 'prop-types';
import _get from 'lodash/get';
import {
  Icons,
  TextField,
  UserInfo,
  withForm,
  Select,
} from '@casanova/casanova-common';
import { IconLink, RoleVerifier } from 'components';
import { CustomerLevel, AccountStatement } from '@customers/components';
import {
  getCustomerSubtitle,
  isForeignCustomer,
  isMoralCustomer,
  isTenderCustomer,
} from '@customers/common/utils';
import { HAS_BIRTH_IN_FOREIGN } from '@customers/common/constants';
import './CustomerInformation.scss';
import { getEstadoCuentaSirena } from 'services/customers';
import {
  CUSTOMER_STATUS,
  CUSTOMER_STATUS_VALUES,
} from 'utils/constants/customerLevels';
import ChangeCustomerStatusModal from '@customers/components/ChangeCustomerStatusModal';
import {
  VIEW_CUSTOMER_FORMATS,
  VIEW_CUSTOMER_STATEMENT,
  EDIT_CUSTOMER_INFORMATION,
} from 'utils/roles/permissions/customerPermissions';
import { validateRolePermissions } from 'utils/roles';

import DocumentRd9 from '../pdf/RD9';
import DocumentRd5 from '../pdf/RD5';
import DocumentRd2 from '../pdf/RD2';
import MyDocument from '../pdf/RD4';

const RightButtons = ({
  onDownloadAccountReport,
  onDownloadAccountReportSirena,
  onDownloadRD4,
  onDownloadRD2,
  onDownloadRD5,
  onDownloadRD9,
}) => (
  <div>
    <RoleVerifier identifier={[VIEW_CUSTOMER_FORMATS]}>
      <IconLink onClick={onDownloadRD2} icon={<Icons.Account />}>
        RD2
      </IconLink>
      &nbsp;&nbsp;
      <IconLink onClick={onDownloadRD4} icon={<Icons.Account />}>
        RD4
      </IconLink>
      &nbsp;&nbsp;
      <IconLink onClick={onDownloadRD5} icon={<Icons.Account />}>
        RD5
      </IconLink>
      &nbsp;&nbsp;
      <IconLink onClick={onDownloadRD9} icon={<Icons.Account />}>
        RD9
      </IconLink>
      &nbsp;&nbsp;
    </RoleVerifier>
    <RoleVerifier identifier={[VIEW_CUSTOMER_STATEMENT]}>
      <IconLink onClick={onDownloadAccountReport} icon={<Icons.Account />}>
        Estado de cuenta
      </IconLink>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <IconLink
        onClick={onDownloadAccountReportSirena}
        icon={<Icons.Download />}
      >
        Estado de cuenta SIRENA
      </IconLink>
    </RoleVerifier>
  </div>
);

const CustomerPodium = ({
  data = {},
  downloadFiles,
  isReservation = false,
  getStatement,
  updateCustomerStatus,
  responseDialog,
}) => {
  const [showStatementAccount, setShowStatementAccount] = useState(false);
  const { values } = useFormikContext();

  const [showStatementAccountSirena, setShowStatementAccountSirena] = useState(
    false
  );

  const { curp, fullName, category = {} } = data;

  const {
    birthState,
    originCountry,
    birthDate,
    sex,
    cellphone,
    landline,
    email,
    platform,
    customerStatus,
    tender,
    subtitle,
    isForeign,
    isMoral,
    isTender,
    banReason,
    selfieFile,
  } = useMemo(
    () => ({
      birthState: _get(data, 'userInfo.birthState', {}),
      originCountry: _get(data, 'userInfo.originCountry', ''),
      birthDate: _get(data, 'userInfo.birthDate', ''),
      sex: _get(data, 'userInfo.sex.name', ''),
      cellphone: _get(data, 'userInfo.cellphone', ''),
      landline: _get(data, 'userInfo.landline', ''),
      email: _get(data, 'userInfo.email', ''),
      platform: _get(data, 'userInfo.registrationPlatform.name', ''),
      customerStatus: _get(data, 'customerStatus.uuid', ''),
      tender: _get(data, 'tender', ''),
      subtitle: getCustomerSubtitle(data),
      isForeign: isForeignCustomer(data),
      isMoral: isMoralCustomer(data),
      isTender: isTenderCustomer(data),
      banReason: _get(data, 'banReason', ''),
      selfieFile: _get(data, 'selfieFile', {}),
    }),
    [data]
  );
  const [customerStatusState, setCustomerStatusState] = useState(
    customerStatus
  );
  const [isModalChangeStatusOpen, setIsModalChangeStatusOpen] = useState(false);
  const [isNewsletter, setIsNewsletter] = useState(false);

  const hasBirthInForeign = birthState.name === HAS_BIRTH_IN_FOREIGN;

  const editCustomerPermition = validateRolePermissions(
    EDIT_CUSTOMER_INFORMATION
  );

  const validateInfo = useCallback((text) => text || 'No hay info.', []);

  const handleChangeCustomerStatus = ({ target: { value } }) => {
    setCustomerStatusState(value);
    console.log(value);
    if (value === '82c49bce-b66b-4cd0-8950-e0d4b476b3f7') {
      setIsNewsletter(true);
    } else {
      setIsNewsletter(false);
    }
    handleOnCloseModalChangeStatus();
  };

  const handleOnCloseModalChangeStatus = () =>
    setIsModalChangeStatusOpen((prev) => !prev);

  const handleSubmitCustomerStatusModal = () => {
    handleOnCloseModalChangeStatus();
    updateCustomerStatus({
      customerStatusState,
      reasonNewsletter: values.reason,
    });
  };

  const handleDownloadFiles = useCallback(
    (e) => {
      e.preventDefault();
      downloadFiles();
    },
    [downloadFiles]
  );

  const handleDownloadAccountReport = useCallback(
    (e) => {
      e.preventDefault();
      getStatement({
        uuid: data.uuid,
        onSuccess: () => setShowStatementAccount(true),
      });
    },
    [getStatement, data]
  );

  const makePDFSirena = async (name) => {
    const sirena = await getEstadoCuentaSirena(name);
    const doc = new jsPDF('landscape');
    doc.text(name, 14, 20);
    autoTable(doc, {
      startY: 30,
      columns: [
        { header: 'Sucursal', dataKey: 'sucursal' },
        { header: 'Folio', dataKey: 'folio' },
        { header: 'Contrato', dataKey: 'id_contrato' },
        { header: 'Fecha Inicial', dataKey: 'fecha_inicial' },
        { header: 'Fecha Final', dataKey: 'fecha_final' },
        { header: 'Monto', dataKey: 'monto' },
        { header: 'Pagado', dataKey: 'pagado' },
        { header: 'Descuento', dataKey: 'descuento' },
        { header: 'Total', dataKey: 'total' },
      ],
      body: sirena || [],
    });
    return doc.output('bloburl');
  };

  const [pdfUrl, setPdfUrl] = useState(null);
  const handleDownloadAccountReportSirena = async (e) => {
    e.preventDefault();
    const urlPdf = await makePDFSirena(fullName);
    setPdfUrl(urlPdf);
    setShowStatementAccountSirena(true);
  };

  const openInNewTab = (blobUrl) => {
    const newWindow = window.open(blobUrl, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  /**
   * Descarga el rds9 con datos del cliente
   * @returns { void }
   */
  const onDownloadRD4 = async (e) => {
    e.preventDefault();
    const blob = await pdf(<MyDocument />).toBlob();
    const blobUrl = URL.createObjectURL(blob);
    openInNewTab(blobUrl);
  };

  /**
   * Descarga el rds9 con datos del cliente
   * @returns { void }
   */
  const onDownloadRD2 = async (e) => {
    e.preventDefault();
    const blob = await pdf(
      <DocumentRd2 name={fullName} number={cellphone} email={email} />
    ).toBlob();
    const blobUrl = URL.createObjectURL(blob);
    openInNewTab(blobUrl);
  };

  /**
   * Descarga el rds5 con datos del cliente
   * @returns { void }
   */
  const onDownloadRD5 = async (e) => {
    e.preventDefault();
    const blob = await pdf(<DocumentRd5 />).toBlob();
    const blobUrl = URL.createObjectURL(blob);
    openInNewTab(blobUrl);
  };

  /**
   * Descarga el rds9 con datos del cliente
   * @returns { void }
   */
  const onDownloadRD9 = async (e) => {
    e.preventDefault();
    const blob = await pdf(
      <DocumentRd9
        name={fullName}
        number={cellphone}
        landline={landline}
        email={email}
        billingDetails={data.billingDetails}
      />
    ).toBlob();
    const blobUrl = URL.createObjectURL(blob);
    openInNewTab(blobUrl);
  };

  return (
    <>
      {showStatementAccount && (
        <AccountStatement
          show={showStatementAccount}
          onClose={() => setShowStatementAccount(false)}
          customerName={fullName}
          customerNumber={_get(data, 'customerNumber', '')}
        />
      )}

      {showStatementAccountSirena && (
        <AccountStatement
          show={showStatementAccountSirena}
          onClose={() => setShowStatementAccountSirena(false)}
          defineUrl={pdfUrl}
        />
      )}

      <div className="CustomerPodium__container">
        <div className="CustomerPodium__header">
          <div>
            <h3 className="CustomerPodium__title">Información del cliente</h3>
          </div>
          <div>
            <RightButtons
              onDownloadAccountReport={handleDownloadAccountReport}
              onDownloadAccountReportSirena={handleDownloadAccountReportSirena}
              onDownloadRD4={onDownloadRD4}
              onDownloadRD2={onDownloadRD2}
              onDownloadRD5={onDownloadRD5}
              onDownloadRD9={onDownloadRD9}
            />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-4 d-flex justify-content-center align-items-center">
            <CustomerLevel {...category} src={selfieFile.presignedUrl} />
          </div>
          <div className="col-8">
            <div className="row">
              <div className="CustomerPodium__profile-title">
                <h3 className="CustomerPodium__profile-subtitle">
                  {tender.tenderType
                    ? `${subtitle} - ${
                        tender.tenderType === 'FISIC' ? 'Física' : 'Moral'
                      }`
                    : subtitle}
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <TextField title="Alta" value={validateInfo(platform)} />
              </div>
              {!isForeign && !isTender && (
                <div className="col-4">
                  <TextField title="Curp" value={validateInfo(curp)} />
                </div>
              )}
              <div className="col-4">
                <TextField
                  title={isMoral ? 'Nombre representante moral' : 'Nombre'}
                  value={validateInfo(fullName)}
                />
              </div>
              {!isTender && (
                <>
                  <div className="col-4">
                    <TextField
                      title="Fecha de nacimiento"
                      value={validateInfo(birthDate)}
                    />
                  </div>
                  <div className="col-4">
                    <TextField
                      title={
                        isForeign || hasBirthInForeign
                          ? 'País de origen'
                          : 'Lugar de nacimiento'
                      }
                      value={validateInfo(
                        isForeign || hasBirthInForeign
                          ? originCountry.name
                          : birthState.name
                      )}
                    />
                  </div>
                  <div className="col-4">
                    <TextField title="Género" value={validateInfo(sex)} />
                  </div>
                </>
              )}
              <div className="col-4">
                <TextField
                  title="Número de celular"
                  value={validateInfo(cellphone)}
                />
              </div>
              <div className="col-4">
                <TextField
                  title="Número de casa u oficina"
                  value={validateInfo(landline)}
                />
              </div>
              <div className="col-4">
                <TextField
                  title="Correo electrónico"
                  value={validateInfo(email)}
                />
              </div>
              {!isReservation && (
                <div className="col-4">
                  <TextField
                    title="Estatus del cliente"
                    value={
                      <Select
                        disableLabel
                        label="Estatus"
                        name="customerStatus"
                        options={CUSTOMER_STATUS}
                        onChange={handleChangeCustomerStatus}
                        value={customerStatus}
                        className="m-0 select"
                        disabled={
                          !!isReservation ||
                          !customerStatus ||
                          !editCustomerPermition
                        }
                      />
                    }
                  />
                </div>
              )}
              {customerStatus === CUSTOMER_STATUS_VALUES.BANNED && banReason && (
                <div className="col-4">
                  <TextField title="Comentario:" value={banReason} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="CustomerPodium__footer">
          <UserInfo {...data} />
        </div>
      </div>
      <ChangeCustomerStatusModal
        show={isModalChangeStatusOpen}
        onCancel={handleOnCloseModalChangeStatus}
        onSubmit={handleSubmitCustomerStatusModal}
        responseDialog={responseDialog}
        isNewsletter={isNewsletter}
      />
    </>
  );
};

CustomerPodium.propTypes = {
  data: PropTypes.object,
  isReservation: PropTypes.bool,
};

export default withForm({
  config: {
    initialValues: { ban: false },
    dialogs: { response: {}, confirmation: {} },
    withButtons: false,
  },
})(CustomerPodium);
