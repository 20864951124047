export const getDescriptionByValue = (catalog, value) => {
  const result = catalog.filter((catElement) => catElement.value === value);
  return result.length > 0 ? result[0] : null;
};

export const saveImeiSelecteds = (imeiSelecteds, gps, nameGPS, valueGPS) => {
  const exctractIndex = (data, indicador) =>
    imeiSelecteds.map((e) => e[indicador]).indexOf(data);

  const arr = imeiSelecteds;
  if (imeiSelecteds.length > 0) {
    gps.list.forEach((element) => {
      const i = exctractIndex(element.value, 'selected');
      const e = exctractIndex(nameGPS, 'name');
      if (e === -1 && i !== -1) {
        if (i !== -1) {
          arr.push({
            ...element,
            name: nameGPS,
            selected: element.value,
          });
        }
      } else if (e !== -1) {
        arr[e] = { name: nameGPS, selected: valueGPS };
      }
    });
  } else {
    arr.push({
      name: nameGPS,
      selected: valueGPS,
    });
  }
  return arr;
};

export const selectTagGPS = (gps, valueGPS) =>
  gps.list.map((imeiGPS) => {
    if (imeiGPS.uuid === valueGPS) {
      return { ...imeiGPS, disabled: true };
    }
    return { ...imeiGPS, disabled: false };
  });
export const disabledImeiGPS = (gps, imeiSelecteds) =>
  gps.list.map((imeiGPS) => {
    if (imeiSelecteds.some((e) => e.selected === imeiGPS.value)) {
      return { ...imeiGPS, disabled: true };
    }
    return { ...imeiGPS, disabled: false };
  });

export const findUuidByProperty = (
  catalog = [],
  searchText = '',
  property = ''
) => {
  const result = catalog.find((item) => item[property] === searchText);
  return result ? result.uuid : null;
};
