export const cardInitialTouched = {
  cardType_0: true,
  cardType_2: true,
  cardType_1: true,
  bank_0: true,
  bank_1: true,
  bank_2: true,
  cardNumber_0: true,
  cardNumber_1: true,
  cardNumber_2: true,
  reference_0: true,
  reference_1: true,
  reference_2: true,
  authorization_0: true,
  authorization_1: true,
  authorization_2: true,
  voucher_0: true,
  voucher_1: true,
  voucher_2: true,
  hasHolderName_0: true,
  hasHolderName_1: true,
  hasHolderName_2: true,
  accountStatus_0: true,
  accountStatus_1: true,
  accountStatus_2: true,
  amountCard_0: true,
  amountCard_1: true,
  amountCard_2: true,
};

export const cardInitialValues_0 = {
  cardType_0: '',
  bank_0: '',
  cardNumber_0: '',
  reference_0: '',
  authorization_0: '',
  voucher_0: '',
  hasHolderName_0: true,
  accountStatus_0: '',
  amountCard_0: '',
};

export const cardInitialValues_1 = {
  cardType_1: '',
  bank_1: '',
  cardNumber_1: '',
  reference_1: '',
  authorization_1: '',
  voucher_1: '',
  hasHolderName_1: true,
  accountStatus_1: '',
  amountCard_1: '',
};

export const cardInitialValues_2 = {
  cardType_2: '',
  bank_2: '',
  cardNumber_2: '',
  reference_2: '',
  authorization_2: '',
  voucher_2: '',
  hasHolderName_2: true,
  accountStatus_2: '',
  amountCard_2: '',
};

export const cardInitialValues = {
  ...cardInitialValues_0,
  ...cardInitialValues_1,
  ...cardInitialValues_2,
};
