import * as Yup from 'yup';
import { vehicleValidationProps } from '@vehicles/sections/VehicleForm/schema';
import { purchaseValidationProps } from '@vehicles/sections/PurchaseForm/schema';
import { adaptationValidationProps } from '@vehicles/sections/AdaptationForm/schema';
import { conversionValidationProps } from '@vehicles/sections/ConversionForm/schema';
import { permissionValidationProps } from '@vehicles/sections/PermissionForm/schema';

export const VehicleFullSchema = Yup.object().shape({
  ...vehicleValidationProps,
  ...purchaseValidationProps,
  ...adaptationValidationProps,
  ...conversionValidationProps,
  ...permissionValidationProps,
});

export const initialTouched = {
  brand: true,
  line: true,
  version: true,
  model: true,
  serialNumber: true,
  engineNumber: true,
  vehicularKey: true,
  color: true,
  origin: true,
  cylinder: true,
  fuelType: true,
  category: true,
  passengers: true,
  loadCapacity: true,
  vehicleType: true,
  mileage: true,
  keys: true,
  gpsNumber: true,
  hasAdaptation: true,
  owner: true,
  provider: true,
  businessArea: true,
  buyDate: true,
  paymentType: true,
  invoicePrice: true,
  invoiceNumber: true,
  invoicePDF: true,
  invoice: true,
  invoiceCopy: true,
  purchaseOrder: true,
  personalUse: true,
  personalUseRequester: true,
  personalUseRemarks: true,
  description: true,

  originAdaptation: true,
  adaptationType: true,
  conversionType: true,
  conversionCost: true,
  conversionInvoiceNumber: true,
  conversionInvoiceXML: true,
  conversionInvoicePDF: true,
  originConversion: true,
  transmissionType: true,
  seatType: true,
  doors: true,
  adaptationCost: true,
  adaptationInvoiceNumber: true,
  adaptationInvoicePDF: true,
  adaptationInvoiceXML: true,
  processingDate: true,
  permissionType: true,
  permissionPDF: true,
};
