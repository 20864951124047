import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getSuburbsByZipCode } from 'store/commons/actions';
import _get from 'lodash/get';
import Address from './Address';

export default compose(
  connect(
    (state) => ({
      zipCode: _get(state, 'commons.zipCodeSelected', {}),
      innCodeInfo: _get(
        state,
        'customers.identityValidation.data.innCodeInfo',
        {}
      ),
    }),
    {
      getSuburbsByZipCode,
    }
  )
)(Address);
