import * as yup from 'yup';
import 'moment/locale/es';
import {
  REQUIRED_MESSAGE,
  FORMAT_TYPES_ALLOWED_MESSAGE,
  ALLOWED_FORMATS,
} from 'utils/constants';
import { fileTypeValidation } from 'utils/validations/common';
import i18n from '@i18n';

const cardIndex = [0, 1, 2];

const cardFormSchema = () => {
  let schema = {};
  for (let value in cardIndex) {
    schema = {
      ...schema,
      [`cardType_${value}`]: yup
        .string()
        .when(['selectedMethods', 'movementType'], {
          is: (selectedMethods, movementType) =>
            selectedMethods.some((method) => method === `card_${value}`) &&
            movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
          then: yup.string().required(REQUIRED_MESSAGE),
          otherwise: yup.string(),
        }),
      [`bank_${value}`]: yup
        .string()
        .when(['selectedMethods', 'movementType'], {
          is: (selectedMethods, movementType) =>
            selectedMethods.some((method) => method === `card_${value}`) &&
            movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
          then: yup.string().required(REQUIRED_MESSAGE),
          otherwise: yup.string(),
        }),
      [`cardNumber_${value}`]: yup
        .string()
        .when(['selectedMethods', 'movementType'], {
          is: (selectedMethods, movementType) =>
            selectedMethods.some((method) => method === `card_${value}`) &&
            movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
          then: yup
            .string()
            .required(REQUIRED_MESSAGE)
            .min(15, i18n('ERROR__COMMONS__MIN_LENGTH', [15])),
          otherwise: yup.string(),
        }),
      [`reference_${value}`]: yup
        .string()
        .when(['selectedMethods', 'movementType'], {
          is: (selectedMethods, movementType) =>
            selectedMethods.some((method) => method === `card_${value}`) &&
            movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
          then: yup
            .string()
            .required(REQUIRED_MESSAGE)
            .min(6, i18n('ERROR__COMMONS__MIN_LENGTH', [6])),
          otherwise: yup.string(),
        }),
      [`authorization_${value}`]: yup
        .string()
        .when(['selectedMethods', 'movementType'], {
          is: (selectedMethods, movementType) =>
            selectedMethods.some((method) => method === `card_${value}`) &&
            movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
          then: yup
            .string()
            .required(REQUIRED_MESSAGE)
            .min(6, i18n('ERROR__COMMONS__MIN_LENGTH', [6])),
          otherwise: yup.string(),
        }),
      [`voucher_${value}`]: yup
        .mixed()
        .when(['selectedMethods', 'movementType'], {
          is: (selectedMethods, movementType) =>
            selectedMethods.some((method) => method === `card_${value}`) &&
            movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
          then: yup
            .mixed()
            .required(REQUIRED_MESSAGE)
            .test('fileFormat', FORMAT_TYPES_ALLOWED_MESSAGE, (file) =>
              fileTypeValidation(file, ALLOWED_FORMATS)
            ),
          otherwise: yup.mixed(),
        }),
      [`accountStatus_${value}`]: yup
        .mixed()
        .when(['selectedMethods', 'movementType', `hasHolderName_${value}`], {
          is: (selectedMethods, movementType, hasHolderName) =>
            selectedMethods.some((method) => method === `card_${value}`) &&
            movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2' &&
            !hasHolderName,
          then: yup
            .mixed()
            .required(REQUIRED_MESSAGE)
            .test('fileFormat', FORMAT_TYPES_ALLOWED_MESSAGE, (file) =>
              fileTypeValidation(file, ALLOWED_FORMATS)
            ),
          otherwise: yup.mixed(),
        }),
      [`amountCard_${value}`]: yup
        .mixed()
        .when(['selectedMethods', 'movementType'], {
          is: (selectedMethods, movementType) =>
            selectedMethods.some((method) => method === `card_${value}`) &&
            movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
          then: yup.mixed().when('diffMethods', {
            is: (diffMethods) => diffMethods === true,
            then: yup
              .mixed()
              .required(REQUIRED_MESSAGE)
              .test(
                'invalidPrice',
                'La suma de los montos no es igual al total del abono',
                function (value) {
                  if (!value) {
                    return true;
                  }
                  const validPrice = this.parent.priceIsInvalid;
                  return validPrice === true;
                }
              ),
            otherwise: yup.mixed(),
          }),
          otherwise: yup.mixed(),
        }),
    };
  }
  return schema;
};

export default cardFormSchema;
