import { transformIdOfList } from '@casanova/casanova-common/lib/utils/transformHelpers';
import types from './action-types';
import _get from 'lodash/get';

const initialState = {
  responseDialog: {
    open: false,
    success: false,
    errorCode: '',
    errorMessage: '',
  },
  list: {
    params: {
      page: 0,
      size: 10,
      enable: true,
      property: 'folio',
      direction: 'desc',
    },
    searchPattern: '',
    filters: {
      minStartDate: '',
      maxStartDate: '',
      minEndDate: '',
      maxEndDate: '',
      businessAreas: [],
    },
    totalPages: 1,
    totalElements: 10,
    results: [],
  },
  saveContract: {
    success: false,
    error: false,
    errorMessage: '',
    data: {},
  },
  contractDetails: {
    success: false,
    error: false,
    errorMessage: '',
    data: {},
  },
  changeOfVehicle: {
    success: false,
    error: false,
    errorMessage: '',
    data: {},
  },
  createLoan: {
    success: false,
    error: false,
    errorMessage: '',
    data: {},
  },
  vehicleChangeIncidents: {
    error: false,
    errorMessage: '',
    list: [],
  },
  vehicleChangePeriodicities: {
    error: false,
    errorMessage: '',
    list: [],
  },
  responseDialogUpdateContract: {
    open: false,
    success: false,
    errorCode: '',
    errorMessage: '',
    modalType: '',
  },
};

export function contractsReducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.SET_PARAMS:
      return {
        ...state,
        list: {
          ...state.list,
          params: {
            ...state.list.params,
            ...payload,
            page: 0,
          },
        },
      };
    case types.RESET_PARAMS:
      return {
        ...state,
        list: {
          ...state.list,
          params: initialState.list.params,
        },
      };
    case types.SET_LIST_PAGE:
      return {
        ...state,
        list: {
          ...state.list,
          params: {
            ...state.list.params,
            page: payload,
          },
        },
      };
    case types.GET_LIST_FAILED:
    case types.GET_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          results: [],
        },
      };
    case types.GET_LIST_SUCCEED:
      return {
        ...state,
        list: {
          ...state.list,
          results: transformIdOfList(payload.content, 'uuid'),
          totalPages: payload.totalPages,
          totalElements: payload.totalElements,
        },
      };
    case types.GET_FILTERS_FAILED:
    case types.GET_FILTERS:
      return {
        ...state,
        list: {
          ...state.list,
          filters: initialState.list.filters,
        },
      };
    case types.GET_FILTERS_SUCCEED:
      return {
        ...state,
        list: {
          ...state.list,
          filters: payload,
        },
      };
    case types.CLOSE_RESPONSE_DIALOG:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: false,
        },
      };
    case types.SAVE_CONTRACT:
      return {
        ...state,
        saveContract: {
          success: false,
          error: false,
          errorMessage: '',
          data: {},
        },
        responseDialog: {
          ...state.responseDialog,
          open: false,
          success: false,
          errorCode: '',
        },
      };
    case types.SAVE_CONTRACT_SUCCEED:
      return {
        ...state,
        saveContract: {
          success: true,
          error: false,
          errorMessage: '',
          data: payload,
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: true,
          errorCode: '',
        },
      };
    case types.SAVE_CONTRACT_FAILED:
      return {
        ...state,
        saveContract: {
          success: false,
          error: true,
          errorMessage: payload,
          data: {},
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.GET_CONTRACT_DETAILS:
    case types.CLEAN_CONTRACT_DETAILS:
      return {
        ...state,
        contractDetails: {
          success: false,
          error: false,
          errorMessage: '',
          data: {},
        },
        responseDialog: initialState.responseDialog,
      };
    case types.GET_CONTRACT_DETAILS_SUCCEED:
      return {
        ...state,
        contractDetails: {
          success: true,
          error: false,
          errorMessage: '',
          data: payload,
        },
      };
    case types.GET_CONTRACT_DETAILS_FAILED:
      return {
        ...state,
        contractDetails: {
          success: false,
          error: true,
          errorMessage: payload,
          data: {},
        },
      };
    case types.CHANGE_OF_VEHICLE:
      return {
        ...state,
        changeOfVehicle: {
          success: false,
          error: false,
          errorMessage: '',
          data: {},
        },
        responseDialog: {
          ...state.responseDialog,
          open: false,
          success: false,
          errorCode: '',
        },
      };
    case types.CHANGE_OF_VEHICLE_SUCCEED:
      return {
        ...state,
        changeOfVehicle: {
          success: true,
          error: false,
          errorMessage: '',
          data: payload,
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: true,
          errorCode: '',
        },
      };
    case types.CHANGE_OF_VEHICLE_FAILED:
      return {
        ...state,
        changeOfVehicle: {
          success: false,
          error: true,
          errorMessage: payload,
          data: {},
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.CREATE_LOAN:
      return {
        ...state,
        createLoan: {
          success: false,
          error: false,
          errorMessage: '',
          data: {},
        },
        responseDialog: {
          ...state.responseDialog,
          open: false,
          success: false,
          errorCode: '',
        },
      };
    case types.CREATE_LOAN_SUCCEED:
      return {
        ...state,
        createLoan: {
          success: true,
          error: false,
          errorMessage: '',
          data: payload,
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: true,
          errorCode: '',
        },
      };
    case types.CREATE_LOAN_FAILED:
      return {
        ...state,
        createLoan: {
          success: false,
          error: true,
          errorMessage: payload,
          data: {},
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.RESET:
      return initialState;
    case types.UPLOAD_CONTRACT:
      return {
        ...state,
        saveContract: {
          success: false,
          error: false,
          errorMessage: '',
          data: {},
        },
        responseDialogUpdateContract: {
          open: false,
          success: false,
          errorCode: '',
          errorMessage: '',
          modalType: '',
        },
      };
    case types.UPDATE_CONTRACT_SUCCEED:
      return {
        ...state,
        saveContract: {
          success: true,
          error: false,
          errorMessage: '',
          data: payload,
        },
        responseDialogUpdateContract: {
          open: true,
          success: true,
          errorCode: '',
          errorMessage: '',
          modalType: '',
        },
      };
    case types.UPDATE_CONTRACT_FAILED:
      return {
        ...state,
        saveContract: {
          success: false,
          error: true,
          errorMessage: payload,
          data: {},
        },
        responseDialogUpdateContract: {
          open: true,
          success: false,
          errorCode: payload.errorCode,
          errorMessage: payload.errorMessage,
          modalType: '',
        },
      };
    case types.CLOSE_RESPONSE_DIALOG_UPDATE_CONTRACT:
      return {
        ...state,
        responseDialogUpdateContract: {
          open: false,
          success: false,
          errorCode: '',
          errorMessage: '',
          modalType: '',
        },
      };
    default:
      return state;
  }
}
