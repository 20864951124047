import compose from 'recompose/compose';
import { connect } from 'react-redux';
import {
  fetchCatalog,
  selectBrand,
  selectLine,
  getSpecs,
  restartSpecs,
} from 'store/catalogs/actions';
import _get from 'lodash/get';
import VehicleForm from './VehicleForm';

export default compose(
  connect(
    (state) => {
      const linesBySelectedBrand = state.catalogs?.brandSelected?.lines;

      return {
        listVehiclePurposes: state.catalogs.vehiclePurpose.list,
        listBrands: state.catalogs.brand.list,
        listModels: state.catalogs.model.list,
        listColors: state.catalogs.color.list,
        listVehicleTypes: state.catalogs.vehicleType.list,
        listOrigins: state.catalogs.origin.list,
        listBusinessArea: _get(state, 'commons.catalogsBusinessAreas.list', []),
        listFuelType: state.catalogs.fuelType.list,
        listLines: state.catalogs.line.list,
        listVersions: state.catalogs.version.list,
        brandSelected: state.catalogs.brandSelected,
        lineSelected: state.catalogs.lineSelected,
        listSeatType: state.catalogs.seatType.list,
        listTransmission: state.catalogs.transmissionType.list,
        specs: state.catalogs.specs,
        linesBySelectedBrand,
      };
    },
    {
      fetchCatalog,
      selectBrand,
      selectLine,
      getSpecs,
      restartSpecs,
    }
  )
)(VehicleForm);
