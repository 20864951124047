import { setVehiclesSearch } from 'store/vehicles/actions';
import * as yup from 'yup';

const schema = yup.object().shape({
  brand: yup.string(),
  line: yup.string(),
  version: yup.string(),
  model: yup.string(),
  serialNumber: yup.string(),
  plateNumber: yup.string(),
  provider: yup.string(),
  businessArea: yup.string(),
  branch: yup.string(),
  categories: yup.string(),
  owner: yup.string(),
  status: yup.string(),
  businessArea: yup.string(),
});

const initialValues = {
  brand: '',
  line: '',
  version: '',
  model: '',
  serialNumber: '',
  plateNumber: '',
  provider: '',
  businessArea: '',
  branch: '',
  categories: '',
  owner: '',
  status: '',
  businessArea: '',
};

const initialTouched = {
  brand: true,
  line: true,
  version: true,
  model: true,
  serialNumber: true,
  plateNumber: true,
  provider: true,
  businessArea: true,
  branch: true,
  categories: true,
  owner: true,
  status: true,
  businessArea: true,
};

export const config = {
  schema,
  initialValues,
  initialTouched,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  withButtons: false,
  submitForm: setVehiclesSearch,
  dialogs: {
    confirmation: {},
    response: {},
  },
};
