import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  SAVE_REQUESTED,
  EDIT_REQUESTED,
  GET_DETAIL_REQUESTED,
  GET_SUMMARY_INFO_REQUESTED,
  GET_LIST,
  BAN,
  DOWNLOAD_ZIP,
  DOWNLOAD_XLSX,
  SEARCH,
  VALIDATE_CURP,
  VALIDATE_ENDORSEMENT_CURP,
  VALIDATE_CURP_DRIVER,
  DELETE_BILLING_INFO,
  ADD_BILLING_INFO,
  SAVE_CUSTOMER_FOREGINER,
  SEARCH_EMPLOYEES,
  SEARCH_JURIDICAL_CUSTOMER,
  SEARCH_TENDER_CUSTOMER,
  SAVE_AVAL_CUSTOMER,
  HYDRATE_CUSTOMER_EDITION,
  GET_STATEMENT,
  SEARCH_JURIDICAL_TENDER_CUSTOMER,
  GET_CUSTOMERS_LIST_FILTERS,
  SAVE_CUSTOMER_BASE,
  SAVE_CUSTOMER_FOREGINER_BASE,
  UPDATE_CUSTOMER_STATUS_REQUESTED,
  GET_LINK_TO_VALIDATE_IDENTITY,
  VALIDATE_IDENTITY,
} from './action-types';
import {
  getDetailSaga,
  getSummaryInfoSaga,
  saveCustomerSaga,
  getCustomersListSaga,
  banCustomerSaga,
  downloadFilesSaga,
  downloadXlsxSaga,
  editCustomerSaga,
  searchCustomersSaga,
  searchEmployeesSaga,
  searchJuridicalCustomersSaga,
  searchTenderCustomersSaga,
  validateCurpSaga,
  validateCurpEndorsementSaga,
  validateCurpDriversSaga,
  deleteBillingInfoSagas,
  addBillingInfoSagas,
  saveForeignerCustomerSaga,
  saveAvalCustomerSaga,
  hydrateCustomerEditionSaga,
  getStatementSaga,
  searchJuridicalAndTenderCustomersSaga,
  getCustomersListFiltersSagas,
  saveCustomerBaseSaga,
  saveForeignerCustomerBaseSaga,
  updateCustomerStatusSaga,
  getLinkToValidateIdentitySaga,
  validateIdentitySaga,
} from './sagas';

export default function* watchers() {
  yield takeEvery(SAVE_REQUESTED, saveCustomerSaga);
  yield takeEvery(UPDATE_CUSTOMER_STATUS_REQUESTED, updateCustomerStatusSaga);
  yield takeEvery(EDIT_REQUESTED, editCustomerSaga);
  yield takeEvery(GET_DETAIL_REQUESTED, getDetailSaga);
  yield takeEvery(GET_SUMMARY_INFO_REQUESTED, getSummaryInfoSaga);
  yield takeEvery(GET_LIST, getCustomersListSaga);
  yield takeEvery(BAN, banCustomerSaga);
  yield takeLatest(DOWNLOAD_ZIP, downloadFilesSaga);
  yield takeLatest(DOWNLOAD_XLSX, downloadXlsxSaga);
  yield takeLatest(SEARCH, searchCustomersSaga);
  yield takeLatest(SEARCH_EMPLOYEES, searchEmployeesSaga);
  yield takeLatest(SEARCH_JURIDICAL_CUSTOMER, searchJuridicalCustomersSaga);
  yield takeLatest(SEARCH_TENDER_CUSTOMER, searchTenderCustomersSaga);
  yield takeLatest(
    SEARCH_JURIDICAL_TENDER_CUSTOMER,
    searchJuridicalAndTenderCustomersSaga
  );
  yield takeLatest(VALIDATE_CURP, validateCurpSaga);
  yield takeLatest(VALIDATE_ENDORSEMENT_CURP, validateCurpEndorsementSaga);
  yield takeLatest(VALIDATE_CURP_DRIVER, validateCurpDriversSaga);
  yield takeLatest(ADD_BILLING_INFO, addBillingInfoSagas);
  yield takeLatest(DELETE_BILLING_INFO, deleteBillingInfoSagas);
  yield takeLatest(SAVE_CUSTOMER_FOREGINER, saveForeignerCustomerSaga);
  yield takeLatest(SAVE_CUSTOMER_FOREGINER_BASE, saveForeignerCustomerBaseSaga);
  yield takeLatest(SAVE_AVAL_CUSTOMER, saveAvalCustomerSaga);
  yield takeLatest(HYDRATE_CUSTOMER_EDITION, hydrateCustomerEditionSaga);
  yield takeLatest(GET_STATEMENT, getStatementSaga);
  yield takeLatest(GET_CUSTOMERS_LIST_FILTERS, getCustomersListFiltersSagas);
  yield takeLatest(SAVE_CUSTOMER_BASE, saveCustomerBaseSaga);
  yield takeLatest(
    GET_LINK_TO_VALIDATE_IDENTITY,
    getLinkToValidateIdentitySaga
  );
  yield takeLatest(VALIDATE_IDENTITY, validateIdentitySaga);
}
