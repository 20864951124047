export const ADDRESS_PROFF_UUIDS = {
  '00e0ccec-7987-4249-891a-b8fd9c2adb49': ['cfe', 'lf', 'regio luz'],
  '7b16a8e6-9f20-4d57-88e2-e92fc867c084': [
    'telcel',
    'izzi',
    'axtel',
    'telmex',
    'totalplay',
    'att',
    'megacable',
  ],
  'abe18893-5296-44b5-ae7f-0f2497ee069f': ['naturgy', 'amicsa'],
  'fdbef742-0974-4677-864e-8d1f7e36163c': [
    'oapas',
    'sacmex',
    'opdm',
    'drenaje',
    'dhc',
    'cea',
    'smapa',
    'jmas',
    'caev',
  ],
  'e2fd5121-2b40-459e-acad-e8f72f177907': [],
};

// Función que recibe una cadena y busca el UUID asociado
export const getUUIDOfAddressProofByIssuer = (issuer) =>
  Object.keys(ADDRESS_PROFF_UUIDS).find((uuid) =>
    ADDRESS_PROFF_UUIDS[uuid].some(
      (keyword) => keyword.toLowerCase() === issuer.toLowerCase()
    )
  ) || null;
