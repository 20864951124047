const formatInvoice = ({
  invoiceSocialReason,
  invoiceRfc,
  invoiceCfdiUse,
  invoicePayForm,
  invoicePayMethod,
  emailInvoice,
  observationsInvoice,
  invoiceSelected,
  invoiceZipCode,
  invoiceTaxRegime,
  invoiceDeleted,
}) => ({
  invoiceForm: {
    invoiceSocialReason,
    invoiceRfc,
    invoiceCfdiUse,
    emailInvoice,
    invoicePayForm,
    invoicePayMethod,
    observationsInvoice,
    invoiceSelected,
    invoiceZipCode,
    invoiceTaxRegime,
  },
  invoiceDeleted,
});

export default formatInvoice;
