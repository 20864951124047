import { useCallback, useEffect, useState, useMemo } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import _isEmpty from 'lodash/isEmpty';
import {
  Section,
  MaskedInput as Input,
  withForm,
  Table,
  Button,
  ActionModal,
  Icons,
} from '@casanova/casanova-common';
import { BackButton } from 'components';
import { PaymentDetail } from '@payments';
import { transformDropdownElements } from '@casanova/casanova-common/lib/utils/transformHelpers';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import i18n from '@i18n';
import { MODES } from '@payments/commons/constants';
import { config } from './config';
import columns, { contextualMenu } from './columns';
import './ContractDetail.scss';
import { InputNumber } from 'primereact/inputnumber';
import RoleVerifier from 'components/RoleVerifier';
import {
  CREATE_RESERVATION,
  VIEW_REPORTS_ACCOUNTING,
} from 'utils/roles/permissions/contractsPermissions';
import { validateRolePermissions } from 'utils/roles';

function ContractDetail({
  action = FORM_ACTIONS.VIEW,
  contractMovementsList,
  getContractDetails,
  cleanContractDetails,
  match,
  history,
  onUpdateForm,
  contractDetails,
  setCancelMovement,
  getDetail,
}) {
  const [showModal, setShowModal] = useState(false);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [mode, setMode] = useStateWithCallbackLazy('');
  const [movementUuid, setMovementUuid] = useState('');
  const [movementType, setMovementType] = useState('');

  const { results } = contractMovementsList;

  const totalBalance = results
    .reduce((total, movement) => {
      if (movement.movementType === 'Cargo') return total - movement.amount;
      return total + movement.amount;
    }, 0)
    .toFixed(2);

  const contractNumber = useMemo(() => match.params.id, [match.params.id]);

  const handleDropdownSelect = useCallback((abono) => {
    const { name, uuid } = abono;
    switch (name) {
      case contextualMenu.CANCEL.name:
        setMovementUuid(uuid);
        setShowModalCancel(true);
        const selectedMovement = results.find(
          (movement) => movement.uuid === uuid
        );
        if (selectedMovement) {
          setMovementType(selectedMovement.movementType);
        }
        break;
      default:
        break;
    }
  }, []);

  const handleClickPayment = () => {
    if (mode === MODES.PAYMENT) return setShowModal(true);
    setMode(MODES.PAYMENT, () => {
      setShowModal(true);
    });
  };

  const handlePaymentClose = () => {
    setShowModal(false);
  };

  const handleClickCharge = () => {
    if (mode === MODES.CHARGE) return setShowModal(true);
    setMode(MODES.CHARGE, () => {
      setShowModal(true);
    });
  };

  const handleBack = () => {
    cleanContractDetails();
    setTimeout(() => {
      history.push('/payments-and-charges/');
    }, 1);
  };

  useEffect(() => {
    if (_isEmpty(contractDetails) && !hasFetched) {
      getContractDetails(contractNumber);
    } else {
      setHasFetched(true);
    }
  }, [getContractDetails, contractNumber, hasFetched]);

  useEffect(() => {
    if (!_isEmpty(contractDetails)) {
      onUpdateForm(contractDetails);
    }
  }, [onUpdateForm, contractDetails]);

  const isView = action === FORM_ACTIONS.VIEW;

  const handleModalClose = () => {
    setMovementUuid('');
    setShowModalCancel(false);
  };
  const handleOnActionCancel = () => {
    setShowModalCancel(false);
    // setShowModal(false);
    setCancelMovement(movementUuid);
  };

  return (
    <>
      <PaymentDetail
        mode={mode}
        setMode={setMode}
        show={showModal}
        onClose={handlePaymentClose}
        getDetail={getDetail}
      />

      <ActionModal
        open={showModalCancel}
        icon={<Icons.StatusWarning />}
        onClose={handleModalClose}
        onAction={handleOnActionCancel}
        title={`Cancelar ${movementType}`}
        actionLabel={i18n('COMMONS__YES_CONTINUE__TEXT')}
        message={`¿Está seguro que desea cancelar ${movementType}?`}
      />

      <Section
        title={i18n('PAYMENTS__CONTRACT_DETAIL__TITLE')}
        className="ContractDetail"
      >
        <div className="row">
          <div className="col-md-4">
            <Input
              label={i18n(
                'PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__CONTRACT_NUMBER'
              )}
              placeholder={i18n(
                'PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__CONTRACT_NUMBER'
              )}
              maskPlaceholder=""
              name="contractNumber"
              disabled={isView}
            />
          </div>
          <div className="col-md-4">
            <Input
              label={i18n('COMMONS__CUSTOMER')}
              placeholder={i18n('COMMONS__CUSTOMER')}
              maskPlaceholder=""
              name="customer"
              disabled={isView}
            />
          </div>
          <div className="col-md-4">
            <Input
              label={i18n('PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__VEHICLE')}
              placeholder={i18n(
                'PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__VEHICLE'
              )}
              maskPlaceholder=""
              name="vehicle"
              disabled={isView}
            />
          </div>
          <div className="col-md-4">
            <Input
              label={i18n(
                'PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__SERIAL_NUMBER'
              )}
              placeholder={i18n(
                'PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__SERIAL_NUMBER'
              )}
              maskPlaceholder=""
              name="serialNumber"
              disabled={isView}
            />
          </div>
          <div className="col-md-4">
            <Input
              label={i18n('PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__DEPARTURE')}
              placeholder={i18n(
                'PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__DEPARTURE'
              )}
              maskPlaceholder=""
              name="userOpened"
              disabled={isView}
            />
          </div>
          <div className="col-md-4">
            <Input
              label={i18n('PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__CLOSE')}
              placeholder={i18n('PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__CLOSE')}
              maskPlaceholder=""
              name="userClosed"
              disabled={isView}
            />
          </div>
          <div className="col-md-4">
            <Input
              label={i18n('PAYMENTS_AND_CHARGES__PAYMENT_DETAIL__STATUS')}
              placeholder={i18n('PAYMENTS_AND_CHARGES__PAYMENT_DETAIL__STATUS')}
              maskPlaceholder=""
              name="status"
              disabled={isView}
            />
          </div>
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="flex-auto">
              <label>SALDO TOTAL </label>
            </div>
            <div className="flex-auto d-flex flex-column">
              <InputNumber
                value={totalBalance}
                disabled={isView}
                mode="currency"
                currency="USD"
                locale="en-US"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Table
              rowClassName="row-clickable"
              emptyMessage="No se encontraron resultados"
              dataList={results}
              columns={columns}
              dropdownCell
              withEllipsis
              dropdownOptions={transformDropdownElements({
                elements: !validateRolePermissions(VIEW_REPORTS_ACCOUNTING)
                  ? Object.values(contextualMenu)
                  : [],
              })}
              onDropdownSelect={handleDropdownSelect}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <BackButton onClick={handleBack} />
          </div>
          <div className="col" />
          <div className="col-md-3 col-lg-2 d-flex justify-content-between flex-wrap p-0 pr-1">
            <RoleVerifier identifier={CREATE_RESERVATION}>
              <Button
                block
                className="PaymentButton mt-0 mr-1"
                onClick={handleClickCharge}
              >
                Cargos
              </Button>
            </RoleVerifier>
          </div>
          <div className="col-md-3 col-lg-2 d-flex justify-content-between flex-wrap p-0 px-1">
            <RoleVerifier identifier={CREATE_RESERVATION}>
              <Button
                block
                className="PaymentButton mt-0"
                onClick={handleClickPayment}
                disabled={totalBalance >= 0}
              >
                Abono
              </Button>
            </RoleVerifier>
          </div>
        </div>
      </Section>
    </>
  );
}

export default withForm({ config })(ContractDetail);
