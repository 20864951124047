import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import {
  onlyLettersAndNumbers,
  alphaMaskWithSpaces,
  alphaMaskWithPuntuation,
} from 'utils/masks';

import {
  MaskedInput as Input,
  Select,
  Button,
  Icons,
  DatePickerV2,
  FileInput,
  RadioGroupInput,
  TextAreaInput,
  NumberInput,
} from '@casanova/casanova-common';
import { getPrevYear } from '@casanova/casanova-common/lib/utils/date';
import { MAXIMUM_DATE } from '@vehicles/common/constants';
import {
  VIEW_VEHICLE_PURCHASE_CFDI_ORIGINAL,
  VIEW_VEHICLE_PURCHASE_CFDI_COPY,
  VIEW_VEHICLE_PURCHASE_INFORMATION,
  CREATE_VEHICLE_PURCHASE_CFDI_ORIGINAL,
  CREATE_VEHICLE_PURCHASE_CFDI_COPY,
  CREATE_VEHICLE_PURCHASE_INFORMATION,
} from 'utils/roles/permissions';

import { TEXT_AREA_NOT_ALLOWED_CHARACTERS } from 'utils/validations';
import RoleVerifier from 'components/RoleVerifier';
import * as attachIcon from '@images/attach.png';

const listValues = [
  { value: false, label: 'Renta' },
  { value: true, label: 'Particular' },
];

const PurchaseForm = ({
  listOwner,
  listProviders,

  action,
  onSaveEdit,
  onCancelEdit,
}) => {
  const { values = {} } = useFormikContext();
  const actionData =
    action === 'add'
      ? CREATE_VEHICLE_PURCHASE_INFORMATION
      : VIEW_VEHICLE_PURCHASE_INFORMATION;
  return (
    <>
      <div className="section">
        <legend className="section__title">INFORMACIÓN DE LA COMPRA</legend>
        {action === 'edit' && (
          <span className="section__icon">
            <Icons.Edit height="1.3rem" /> Editar
          </span>
        )}
      </div>
      <hr style={{ marginTop: '0' }} width="100%" />
      <div className="row">
        <RoleVerifier identifier={actionData}>
          <div className="col-12 switch-inverted">
            <RadioGroupInput
              labelClass="d-flex align-items-center"
              groupClass="col-12 d-flex justify-content-between"
              parentClass="vehicle-radio-input"
              label=""
              name="personalUse"
              options={listValues}
              disabled={action === 'view'}
            />
          </div>
          {values.personalUse && (
            <div className="col-md-4">
              <Input
                mask={alphaMaskWithSpaces(50)}
                maskPlaceholder=""
                label="Persona solicitantejsjsjs*"
                placeholder="Persona solicitante*"
                name="personalUseRequester"
                disabled={action === 'view'}
              />
            </div>
          )}
          <div className="col-md-4">
            <Select
              disabled={action === 'view'}
              label="Propietario*"
              name="owner"
              options={listOwner}
            />
          </div>
          <div className="col-md-4">
            <Select
              label="Proveedor*"
              name="provider"
              options={listProviders}
              disabled={action === 'view'}
            />
          </div>
          <div className="col-md-4">
            <DatePickerV2
              initialValue={
                action !== 'add' ? values.initialBuyDate : undefined
              }
              placeholder="Fecha de compra*"
              name="buyDate"
              label="Fecha de compra"
              disabled={action === 'view'}
              minimumDate={getPrevYear({ years: 10 })}
              maximumDate={MAXIMUM_DATE}
            />
          </div>
          <div className="col-md-8">
            <Input
              mask={alphaMaskWithPuntuation(200)}
              maskPlaceholder=""
              label="Descripción de la factura*"
              placeholder="Descripción de la factura*"
              name="description"
              disabled={action === 'view'}
            />
          </div>
          <div className="col-md-4">
            <NumberInput
              maskSuffix=" mxn"
              decimals={2}
              max={9_999_999}
              label="Precio de compra*"
              placeholder="Precio de compra*"
              name="invoicePrice"
              disabled={action === 'view'}
            />
          </div>
          <div className="col-md-4">
            <Input
              mask={onlyLettersAndNumbers(30)}
              maskPlaceholder=""
              label="Número de orden de compra*"
              placeholder="Número de orden de compra*"
              name="purchaseOrder"
              disabled={action === 'view'}
            />
          </div>
          <div className="col-md-4">
            <Input
              mask={alphaMaskWithSpaces(30)}
              maskPlaceholder=""
              label="Número de factura*"
              placeholder="Número de factura*"
              name="invoiceNumber"
              disabled={action === 'view'}
            />
          </div>
        </RoleVerifier>
        <RoleVerifier
          identifier={
            action === 'add'
              ? CREATE_VEHICLE_PURCHASE_CFDI_ORIGINAL
              : VIEW_VEHICLE_PURCHASE_CFDI_ORIGINAL
          }
        >
          <div className="col-md-4">
            <FileInput
              url={
                action !== 'add' &&
                values.invoiceNumber === values.initialInvoiceNumber
                  ? values.pdfUrl
                  : undefined
              }
              right={
                action !== 'add' &&
                values.invoiceNumber === values.initialInvoiceNumber ? (
                  <Icons.Eye width="85%" height="85%" />
                ) : (
                  <img src={attachIcon} alt="attach icon" />
                )
              }
              displayText={values.invoicePDF}
              type="file"
              label="Factura PDF"
              placeholder="Factura PDF"
              name="invoicePDF"
              accept="application/pdf"
              disabled={action === 'view'}
            />
          </div>
          <div className="col-md-4">
            <FileInput
              url={
                action !== 'add' &&
                values.invoiceNumber === values.initialInvoiceNumber
                  ? values.xmlUrl
                  : undefined
              }
              download
              displayText={values.invoice}
              right={
                action !== 'add' &&
                values.invoiceNumber === values.initialInvoiceNumber ? (
                  <Icons.Download fill="blue" width="85%" height="75%" />
                ) : (
                  <img src={attachIcon} alt="attach icon" />
                )
              }
              type="file"
              label="Factura XML"
              placeholder="Factura XML"
              name="invoice"
              accept="application/xml"
              disabled={action === 'view'}
            />
          </div>
        </RoleVerifier>
        <RoleVerifier
          identifier={
            action === 'add'
              ? CREATE_VEHICLE_PURCHASE_CFDI_COPY
              : VIEW_VEHICLE_PURCHASE_CFDI_COPY
          }
        >
          <div className="col-md-4">
            <FileInput
              url={action !== 'add' ? values.invoiceCopyUrl : undefined}
              displayText={values.invoiceCopy}
              type="file"
              label="Copia de factura PDF"
              placeholder="Copia de factura PDF"
              name="invoiceCopy"
              accept="application/pdf"
              disabled={action === 'view'}
            />
          </div>
        </RoleVerifier>
        {values.personalUse && (
          <RoleVerifier identifier={actionData}>
            <div className="col-12">
              <TextAreaInput
                label="Observaciones"
                placeholder="Observaciones"
                name="personalUseRemarks"
                disabled={action === 'view'}
                regex={TEXT_AREA_NOT_ALLOWED_CHARACTERS}
                maxLength={200}
                rows="3"
              />
            </div>
          </RoleVerifier>
        )}
      </div>
      <br />

      {action === 'edit' && (
        <div className="row">
          <div className="offset-md-8 col-md-4 text-right">
            <div className="row">
              <div className="col-md-6">
                <Button onClick={onCancelEdit} outline block>
                  Cancelar
                </Button>
              </div>
              <div className="col-md-6">
                <Button type="submit" block onClick={onSaveEdit}>
                  Guardar
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

PurchaseForm.propTypes = {
  action: PropTypes.string,
  onSaveEdit: PropTypes.func,
  onCancelEdit: PropTypes.func,
};

PurchaseForm.defaultProps = {
  action: 'add',
};

export default PurchaseForm;
