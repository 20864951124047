import {
  transformIdOfList,
  transformForSelect,
} from '@casanova/casanova-common/lib/utils/transformHelpers';
import { getCustomerDocumentsValidations } from '@customers/common/validations';
import { getInvalidDocumentKey } from 'utils/customers';
import { CUSTOMER_TYPES, TENDER_TYPES } from '@customers/common/constants';
import {
  SET_PARAMS_LIST_OF_CUSTOMERS,
  GET_CUSTOMERS_LIST_FILTERS,
  GET_CUSTOMERS_LIST_FILTERS_SUCCEED,
  SAVE_REQUESTED,
  SAVE_SUCCEEDED,
  SAVE_FAILED,
  EDIT_REQUESTED,
  EDIT_SUCCEEDED,
  EDIT_FAILED,
  CLOSE_RESPONSE_DIALOG,
  GET_DETAIL_REQUESTED,
  GET_DETAIL_SUCCEEDED,
  GET_DETAIL_FAILED,
  GET_SUMMARY_INFO_REQUESTED,
  GET_SUMMARY_INFO_SUCCEEDED,
  GET_SUMMARY_INFO_FAILED,
  RESET_DETAIL,
  GET_LIST,
  GET_LIST_SUCCEEDED,
  GET_LIST_FAILED,
  SET_PAGE_TABLE,
  MODIFY_FILTERS,
  SET_SEARCH,
  RESET,
  BAN,
  BAN_FAILED,
  BAN_SUCCEEDED,
  DOWNLOAD_ZIP_FAILED,
  DOWNLOAD_XLSX_FAILED,
  UPLOAD_DOCUMENT_ADDRESS,
  UPLOAD_DOCUMENT_LICENSE,
  UPLOAD_DOCUMENT_IDENTITY,
  UPLOAD_DOCUMENT_FAILED,
  SEARCH,
  SEARCH_SUCCEEDED,
  SEARCH_FAILED,
  VALIDATE_CURP,
  VALIDATE_CURP_FAILED,
  VALIDATE_CURP_SUCCEEDED,
  CLEAN_VALIDATE_CURP,
  VALIDATE_ENDORSEMENT_CURP,
  VALIDATE_ENDORSEMENT_CURP_FAILED,
  VALIDATE_ENDORSEMENT_CURP_SUCCEEDED,
  CLEAN_VALIDATE_ENDORSEMENT_CURP,
  VALIDATE_CURP_DRIVER,
  VALIDATE_CURP_DRIVER_FAILED,
  VALIDATE_CURP_DRIVER_SUCCEEDED,
  CLEAN_VALIDATE_CURP_DRIVER,
  ADD_BILLING_INFO,
  ADD_BILLING_INFO_FAILED,
  DELETE_BILLING_INFO,
  DELETE_BILLING_INFO_FAILED,
  SET_CUSTOMER_TYPE,
  SET_CUSTOMER_PERSON_TYPE,
  SET_TENDER_TYPE,
  SAVE_CUSTOMER_FOREGINER,
  SAVE_CUSTOMER_FOREGINER_SUCCEEDED,
  SAVE_CUSTOMER_FOREGINER_FAILED,
  SEARCH_EMPLOYEES,
  SEARCH_EMPLOYEES_SUCCEEDED,
  SEARCH_EMPLOYEES_FAILED,
  SEARCH_JURIDICAL_CUSTOMER,
  SEARCH_JURIDICAL_CUSTOMER_SUCCEEDED,
  SEARCH_JURIDICAL_CUSTOMER_FAILED,
  SEARCH_TENDER_CUSTOMER,
  SEARCH_TENDER_CUSTOMER_SUCCEEDED,
  SEARCH_TENDER_CUSTOMER_FAILED,
  SAVE_AVAL_CUSTOMER,
  SAVE_AVAL_CUSTOMER_SUCCEEDED,
  SAVE_AVAL_CUSTOMER_FAILED,
  RESET_AVAL_CUSTOMER,
  SET_PREVENT_CHANGE,
  SET_FORM_VALUES,
  CLEAN_FORM_VALUES,
  SET_AVAL_PREVENT_CHANGE,
  SET_SELECTION_OF_AVAL_CUSTOMER_BY_DETAIL,
  SET_AVAL_CUSTOMER_BY_DETAIL,
  HYDRATE_CUSTOMER_EDITION,
  HYDRATE_CUSTOMER_EDITION_SUCCEEDED,
  HYDRATE_CUSTOMER_EDITION_FAILED,
  GET_STATEMENT,
  GET_STATEMENT_SUCCEEDED,
  GET_STATEMENT_FAILED,
  SEARCH_JURIDICAL_TENDER_CUSTOMER,
  SEARCH_JURIDICAL_TENDER_CUSTOMER_SUCCEEDED,
  SEARCH_JURIDICAL_TENDER_CUSTOMER_FAILED,
  SAVE_CUSTOMER_BASE,
  SAVE_CUSTOMER_BASE_SUCCEEDED,
  SAVE_CUSTOMER_BASE_FAILED,
  SAVE_CUSTOMER_FOREGINER_BASE,
  SAVE_CUSTOMER_FOREGINER_BASE_SUCCEEDED,
  SAVE_CUSTOMER_FOREGINER_BASE_FAILED,
  UPDATE_CUSTOMER_STATUS_REQUESTED,
  UPDATE_CUSTOMER_STATUS_SUCCEEDED,
  UPDATE_CUSTOMER_STATUS_FAILED,
  GET_LINK_TO_VALIDATE_IDENTITY,
  GET_LINK_TO_VALIDATE_IDENTITY_SUCCEEDED,
  GET_LINK_TO_VALIDATE_IDENTITY_FAILED,
  CLOSE_RESPONSE_GET_LINK_TO_VALIDATE_IDENTITY,
  VALIDATE_IDENTITY,
  VALIDATE_IDENTITY_SUCCEEDED,
  VALIDATE_IDENTITY_FAILED,
  CLOSE_RESPONSE_VALIDATE_IDENTITY,
} from './action-types';

const initialState = {
  curpValidation: {
    data: {},
    curp: '',
    isValid: false,
    isValidating: false,
    error: '',
    errorCode: '',
  },
  endorsementCurpValidation: {
    data: {},
    curp: '',
    isValid: false,
    isValidating: false,
    error: '',
    errorCode: '',
  },
  curpDriverValidation: {
    data: {},
    curp: '',
    isValid: false,
    isValidating: false,
    error: '',
    errorCode: '',
  },
  responseDialog: {
    open: false,
    success: false,
    errorCode: 'MD-100',
    errorLabel: '',
    message: '',
    closeInConfirmation: false,
  },
  banResponseDialog: {
    open: false,
    success: false,
    errorCode: 'MD-100',
  },
  customerDetail: {
    success: false,
    error: null,
  },
  summaryInfo: {
    data: {},
    success: false,
    error: null,
  },
  customerType: CUSTOMER_TYPES.NATIONAL,
  customerPersonType: CUSTOMER_TYPES.FISIC,
  tenderType: TENDER_TYPES.FISIC,
  basicInfo: {
    error: false,
    errorMessage: '',
    list: [],
  },
  employees: {
    error: false,
    errorMessage: '',
    list: [],
  },
  juridicalAndTenderCustomers: {
    error: false,
    errorMessage: '',
    list: [],
  },
  juridicalCustomers: {
    error: false,
    errorMessage: '',
    list: [],
  },
  list: {
    params: {
      page: 0,
      size: 10,
      property: 'id',
      direction: 'asc',
    },
    searchPattern: '',
    filters: [],
    totalPages: 1,
    totalElements: 10,
    results: [
      {
        page: 0,
        list: [],
        loading: false,
        loaded: false,
      },
    ],
  },
  billingInfo: {
    lastOperation: '',
    success: true,
    errorCode: '',
    errorMessage: '',
  },
  foreginerCustomer: {
    data: {},
    success: false,
    errors: false,
  },
  filters: {
    categories: [],
    brands: [],
    selectedCategory: {},
    discounts: [],
    previousSelectedCategory: null,
    reservationList: {
      steps: [],
      deliveryBranches: [],
      returnBranches: [],
    },
  },
  avalCustomer: {
    data: {},
    success: false,
    errors: false,
  },
  preventChange: true,
  editJuridicalCustomer: {
    data: {},
    success: false,
    errors: false,
  },
  formValues: {},
  avalPreventChange: true,
  selectionOfAvalCustomerByDetail: false,
  avalCustomerByDetail: {},
  hydrateCustomerEdition: {
    data: {},
    success: false,
    error: false,
    errorMessage: '',
  },
  statement: {
    list: [],
    movementTypes: [],
    success: false,
    error: false,
    errorMessage: '',
  },
  identityValidation: {
    data: {
      urlInfo: {},
      innCodeInfo: {},
    },
    open: false,
    success: false,
    error: false,
    openValidationResponse: false,
    successValidationResponse: false,
    errorValidationResponse: false,
    errorMessage: '',
    errorCode: '',
  },
};

export function customerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PARAMS_LIST_OF_CUSTOMERS:
      return {
        ...state,
        list: {
          ...state.list,
          params: {
            ...state.list.params,
            ...action.payload,
            page: 0,
          },
        },
      };
    case GET_CUSTOMERS_LIST_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          list: {
            steps: [],
            deliveryBranches: [],
            returnBranches: [],
          },
        },
      };
    case GET_CUSTOMERS_LIST_FILTERS_SUCCEED:
      return {
        ...state,
        filters: {
          ...state.filters,
          list: action.payload,
        },
      };
    case CLEAN_VALIDATE_CURP:
    case VALIDATE_CURP:
      return {
        ...state,
        curpValidation: {
          ...state.curpValidation,
          data: {},
          isValidating: true,
          isValid: false,
          curp: action.payload,
          error: '',
          errorCode: '',
        },
      };
    case VALIDATE_CURP_SUCCEEDED:
      return {
        ...state,
        curpValidation: {
          ...state.curpValidation,
          isValidating: false,
          isValid: true,
          data: action.payload.data,
          error: '',
          errorCode: '',
        },
      };
    case VALIDATE_CURP_FAILED:
      return {
        ...state,
        curpValidation: {
          ...state.curpValidation,
          isValidating: false,
          isValid: false,
          curp: action.payload,
          error: action.payload.message,
          errorCode: action.payload.code,
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          error: true,
          message: action.payload.message,
          errorCode: action.payload.code,
          errorLabel: 'Cerrar',
          closeInConfirmation: true,
        },
      };
    case CLEAN_VALIDATE_ENDORSEMENT_CURP:
    case VALIDATE_ENDORSEMENT_CURP:
      return {
        ...state,
        endorsementCurpValidation: {
          ...state.endorsementCurpValidation,
          data: {},
          isValidating: true,
          isValid: false,
          curp: action.payload,
          error: '',
          errorCode: '',
        },
      };
    case VALIDATE_ENDORSEMENT_CURP_SUCCEEDED:
      return {
        ...state,
        endorsementCurpValidation: {
          ...state.endorsementCurpValidation,
          isValidating: false,
          isValid: true,
          data: action.payload.data,
          error: '',
          errorCode: '',
        },
      };
    case VALIDATE_ENDORSEMENT_CURP_FAILED:
      return {
        ...state,
        endorsementCurpValidation: {
          ...state.endorsementCurpValidation,
          isValidating: false,
          isValid: false,
          curp: action.payload,
          error: action.payload.message,
          errorCode: action.payload.code,
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          error: true,
          message: action.payload.message,
          errorCode: action.payload.code,
          errorLabel: 'Cerrar',
          closeInConfirmation: true,
        },
      };
    case CLEAN_VALIDATE_CURP_DRIVER:
    case VALIDATE_CURP_DRIVER:
      return {
        ...state,
        curpDriverValidation: {
          ...state.curpDriverValidation,
          data: {},
          isValidating: true,
          isValid: false,
          curp: action.payload,
          error: '',
          errorCode: '',
        },
      };
    case VALIDATE_CURP_DRIVER_SUCCEEDED:
      return {
        ...state,
        curpDriverValidation: {
          ...state.curpDriverValidation,
          isValidating: false,
          isValid: true,
          data: action.payload.data,
          error: '',
          errorCode: '',
        },
      };
    case VALIDATE_CURP_DRIVER_FAILED:
      return {
        ...state,
        curpDriverValidation: {
          ...state.curpDriverValidation,
          isValidating: false,
          isValid: false,
          curp: action.payload,
          error: action.payload.message,
          errorCode: action.payload.code,
        },
      };
    case SAVE_REQUESTED:
    case EDIT_REQUESTED:
    case BAN:
    case CLOSE_RESPONSE_DIALOG:
    case UPLOAD_DOCUMENT_ADDRESS:
    case UPLOAD_DOCUMENT_LICENSE:
    case UPLOAD_DOCUMENT_IDENTITY:
    case SAVE_CUSTOMER_BASE:
    case UPDATE_CUSTOMER_STATUS_REQUESTED:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: false,
          success: false,
          errorCode: '',
          message: '',
          errorLabel: '',
          closeInConfirmation: false,
        },
      };
    case RESET_DETAIL:
    case GET_DETAIL_REQUESTED:
      const customerDetail =
        action.payload && action.payload.avalCustomer
          ? {
              avalCustomer: {
                data: {},
                success: false,
                errors: false,
              },
            }
          : {
              customerDetail: {
                success: false,
                error: false,
              },
            };
      return {
        ...state,
        ...customerDetail,
        responseDialog: initialState.responseDialog,
      };
    case GET_DETAIL_SUCCEEDED: {
      const customerDetailData = action.payload.data.data.data;

      const reduceCustomerDetail = (acc, [key, sectionData]) => {
        if (key === 'identityDocuments') {
          sectionData.forEach((section) => {
            const { identityDocumentType = {} } = section;

            acc[identityDocumentType.identifier] = section;
          });
        }

        return acc;
      };

      const customerData = Object.entries(customerDetailData).reduce(
        reduceCustomerDetail,
        customerDetailData
      );

      const listOfInvalidsDocuments = Object.entries(customerData)
        .map(([key, data]) => ({ ...data, key }))
        .filter((data) => data.documentStatus)
        .map((document) => ({
          ...getCustomerDocumentsValidations(document),
          documentValidationKey: getInvalidDocumentKey(document.key),
          documentKey: document.key,
        }))
        .filter((doc) => doc.invalidDocument);

      const dataWithValidationDocs = {
        ...customerDetailData,
        listOfInvalidsDocuments,
      };

      return {
        ...state,
        customerDetail: {
          data: dataWithValidationDocs,
          success: true,
          error: null,
        },
      };
    }
    case GET_DETAIL_FAILED:
      return {
        ...state,
        customerDetail: {
          data: {},
          success: false,
          error: true,
        },
      };
    case GET_SUMMARY_INFO_REQUESTED:
      return {
        ...state,
        summaryInfo: initialState.summaryInfo,
      };
    case GET_SUMMARY_INFO_SUCCEEDED: {
      return {
        ...state,
        summaryInfo: {
          data: action.payload,
          success: true,
          error: false,
        },
      };
    }
    case GET_SUMMARY_INFO_FAILED: {
      return {
        ...state,
        summaryInfo: {
          data: {},
          success: false,
          error: true,
        },
      };
    }
    case BAN_SUCCEEDED:
      return {
        ...state,
        banResponseDialog: {
          ...state.responseDialog,
          open: true,
          success: true,
          errorCode: '',
          message: '',
          errorLabel: '',
          closeInConfirmation: false,
        },
      };
    case SAVE_SUCCEEDED:
    case EDIT_SUCCEEDED:
    case SAVE_CUSTOMER_BASE_SUCCEEDED:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: true,
          errorCode: '',
          message: '',
          errorLabel: '',
          closeInConfirmation: false,
        },
      };
    case UPDATE_CUSTOMER_STATUS_SUCCEEDED:
      return {
        ...state,
        customerDetail: {
          ...state.customerDetail,
          data: {
            ...state.customerDetail.data,
            customerStatus: {
              ...state.customerDetail.data.customerStatus,
              uuid: action.payload.customerStatusState,
            },
            banReason: action.payload.reasonNewsletter,
          },
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: true,
          errorCode: '',
          message: '',
          errorLabel: '',
          closeInConfirmation: false,
        },
      };
    case SET_PAGE_TABLE:
      return {
        ...state,
        list: {
          ...state.list,
          params: {
            ...state.list.params,
            page: action.payload,
          },
        },
      };
    case GET_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          results: {
            page: state.list.params.page,
            list: [],
            loading: true,
            loaded: false,
          },
        },
      };
    case GET_LIST_SUCCEEDED:
      const {
        content,
        totalElements,
        totalPages,
        first,
      } = action.payload.data.data.data;
      return {
        ...state,
        list: {
          ...state.list,
          results: {
            list: transformIdOfList(content, 'naturalPersonUuid'),
            loading: false,
            loaded: true,
            page: first ? 0 : state.list.params.page,
          },
          totalPages,
          totalElements,
        },
      };
    case GET_LIST_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          results: {
            list: [],
            loading: false,
            loaded: false,
          },
        },
      };
    case MODIFY_FILTERS:
      return {
        ...state,
        list: {
          ...state.list,
          params: {
            ...state.list.params,
            page: 0,
          },
          filters: action.payload,
        },
      };
    case SET_SEARCH:
      return {
        ...state,
        list: {
          ...state.list,
          params: {
            ...state.list.params,
            page: 0,
          },
          searchPattern: action.payload.searchText,
        },
      };
    case SAVE_FAILED:
    case DOWNLOAD_XLSX_FAILED:
    case DOWNLOAD_ZIP_FAILED:
    case EDIT_FAILED:
    case BAN_FAILED:
    case UPLOAD_DOCUMENT_FAILED:
    case SAVE_CUSTOMER_BASE_FAILED:
    case UPDATE_CUSTOMER_STATUS_FAILED:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          errorCode: action.payload.code,
          message: action.payload.message,
          errorLabel: '',
          closeInConfirmation: false,
        },
      };
    case SEARCH:
      return {
        ...state,
        basicInfo: {
          error: false,
          errorMessage: '',
          list: [],
        },
      };
    case SEARCH_SUCCEEDED:
      const list = transformForSelect(action.payload.data, 'uuid', 'fullName');
      return {
        ...state,
        basicInfo: {
          error: false,
          errorMessage: undefined,
          list,
        },
      };
    case SEARCH_FAILED:
      return {
        ...state,
        basicInfo: {
          error: true,
          errorMessage: action.payload.error.message,
          list: [],
        },
      };

    case SEARCH_EMPLOYEES:
      return {
        ...state,
        employees: {
          error: false,
          errorMessage: '',
          list: [],
        },
      };
    case SEARCH_EMPLOYEES_SUCCEEDED:
      const employees = transformForSelect(action.payload, 'uuid', 'fullName');
      return {
        ...state,
        employees: {
          error: false,
          errorMessage: undefined,
          list: employees,
        },
      };
    case SEARCH_EMPLOYEES_FAILED:
      return {
        ...state,
        employees: {
          error: true,
          errorMessage: action.payload.message,
          list: [],
        },
      };

    case SEARCH_JURIDICAL_CUSTOMER:
      return {
        ...state,
        juridicalCustomers: {
          error: false,
          errorMessage: '',
          list: [],
        },
      };
    case SEARCH_JURIDICAL_CUSTOMER_SUCCEEDED:
      const juridicalCustomers = transformForSelect(
        action.payload.data,
        action.payload.typeValue,
        'businessName'
      );
      return {
        ...state,
        juridicalCustomers: {
          error: false,
          errorMessage: undefined,
          list: juridicalCustomers,
        },
      };
    case SEARCH_JURIDICAL_CUSTOMER_FAILED:
      return {
        ...state,
        juridicalCustomers: {
          error: true,
          errorMessage: action.payload.message,
          list: [],
        },
      };
    case SEARCH_TENDER_CUSTOMER:
      return {
        ...state,
        tenderCustomers: {
          error: false,
          errorMessage: '',
          list: [],
        },
      };
    case SEARCH_TENDER_CUSTOMER_SUCCEEDED:
      const tenderCustomer = transformForSelect(
        action.payload.data,
        action.payload.typeValue,
        'businessName'
      );
      return {
        ...state,
        tenderCustomers: {
          error: false,
          errorMessage: undefined,
          list: tenderCustomer,
        },
      };
    case SEARCH_TENDER_CUSTOMER_FAILED:
      return {
        ...state,
        tenderCustomers: {
          error: true,
          errorMessage: action.payload.message,
          list: [],
        },
      };
    case SEARCH_JURIDICAL_TENDER_CUSTOMER:
      return {
        ...state,
        juridicalaAndTenderCustomers: {
          error: false,
          errorMessage: '',
          list: [],
        },
      };
    case SEARCH_JURIDICAL_TENDER_CUSTOMER_SUCCEEDED:
      const juridicalAndTenderCustomers = transformForSelect(
        action.payload.dataCustomers,
        action.payload.typeValue,
        'businessName'
      );

      return {
        ...state,
        juridicalAndTenderCustomers: {
          error: false,
          errorMessage: undefined,
          list: juridicalAndTenderCustomers,
        },
      };
    case SEARCH_JURIDICAL_TENDER_CUSTOMER_FAILED:
      return {
        ...state,
        juridicalAndTenderCustomers: {
          error: true,
          errorMessage: action.payload.message,
          list: [],
        },
      };
    case ADD_BILLING_INFO:
    case DELETE_BILLING_INFO:
      return {
        ...state,
        billingInfo: {
          success: true,
          errorCode: '',
          errorMessage: '',
        },
      };
    case ADD_BILLING_INFO_FAILED:
    case DELETE_BILLING_INFO_FAILED:
      return {
        ...state,
        billingInfo: {
          success: false,
          errorCode: action.payload.code,
          errorMessage: action.payload.message,
        },
      };
    case SET_CUSTOMER_TYPE:
      return {
        ...state,
        customerType: action.payload,
      };
    case SET_CUSTOMER_PERSON_TYPE:
      return {
        ...state,
        customerPersonType: action.payload,
      };
    case SET_TENDER_TYPE:
      return {
        ...state,
        tenderType: action.payload,
      };
    case SAVE_CUSTOMER_FOREGINER:
    case SAVE_CUSTOMER_FOREGINER_BASE:
      return {
        ...state,
        foreginerCustomer: {
          data: {},
          success: false,
          errors: false,
        },
      };
    case SAVE_CUSTOMER_FOREGINER_SUCCEEDED:
    case SAVE_CUSTOMER_FOREGINER_BASE_SUCCEEDED:
      return {
        ...state,
        foreginerCustomer: {
          data: action.payload,
          success: true,
          errors: false,
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: true,
          message: action.payload.message,
          error: false,
          errorCode: '',
          errorLabel: '',
          closeInConfirmation: false,
        },
      };
    case SAVE_CUSTOMER_FOREGINER_FAILED:
    case SAVE_CUSTOMER_FOREGINER_BASE_FAILED:
      return {
        ...state,
        foreginerCustomer: {
          data: {},
          success: false,
          errors: true,
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          errorCode: action.payload.code,
          message: action.payload.message,
          errorLabel: '',
          closeInConfirmation: false,
        },
      };
    case RESET_AVAL_CUSTOMER:
    case SAVE_AVAL_CUSTOMER:
      return {
        ...state,
        avalCustomer: {
          data: {},
          success: false,
          errors: false,
        },
      };
    case SAVE_AVAL_CUSTOMER_SUCCEEDED:
      return {
        ...state,
        avalCustomer: {
          data: action.payload.data.data.data,
          success: true,
          errors: false,
        },
        responseDialog: action.payload.responseDialog
          ? {
              ...state.responseDialog,
              open: true,
              success: true,
              message: action.payload.data.message,
              error: false,
              errorCode: '',
              errorLabel: '',
              closeInConfirmation: false,
            }
          : state.responseDialog,
      };
    case SAVE_AVAL_CUSTOMER_FAILED:
      return {
        ...state,
        avalCustomer: {
          data: {},
          success: false,
          errors: true,
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          errorCode: action.payload.code,
          message: action.payload.message,
          errorLabel: '',
          closeInConfirmation: false,
        },
      };
    case SET_PREVENT_CHANGE:
      return {
        ...state,
        preventChange: action.payload,
      };
    case SET_FORM_VALUES:
      return {
        ...state,
        formValues: action.payload,
      };
    case CLEAN_FORM_VALUES:
      return {
        ...state,
        formValues: {},
      };
    case SET_AVAL_PREVENT_CHANGE:
      return {
        ...state,
        avalPreventChange: action.payload,
      };
    case SET_SELECTION_OF_AVAL_CUSTOMER_BY_DETAIL:
      return {
        ...state,
        selectionOfAvalCustomerByDetail: action.payload,
      };
    case SET_AVAL_CUSTOMER_BY_DETAIL:
      return {
        ...state,
        avalCustomerByDetail: action.payload,
      };
    case HYDRATE_CUSTOMER_EDITION:
      return {
        ...state,
        hydrateCustomerEdition: initialState.hydrateCustomerEdition,
      };
    case HYDRATE_CUSTOMER_EDITION_SUCCEEDED:
      return {
        ...state,
        hydrateCustomerEdition: {
          data: action.payload,
          success: true,
          error: false,
          errorMessage: '',
        },
      };
    case HYDRATE_CUSTOMER_EDITION_FAILED:
      return {
        ...state,
        hydrateCustomerEdition: {
          data: {},
          success: false,
          error: true,
          errorMessage: action.payload,
        },
      };
    case GET_STATEMENT: {
      return {
        ...state,
        statement: {
          ...initialState.statement,
          movementTypes: state.statement.movementTypes,
        },
      };
    }
    case GET_STATEMENT_SUCCEEDED: {
      return {
        ...state,
        statement: {
          ...state.statement,
          list: action.payload.statementData,
          movementTypes: action.payload.movementTypes,
          success: true,
          error: false,
          errorMessage: '',
        },
      };
    }
    case GET_STATEMENT_FAILED: {
      return {
        statement: {
          ...state.statement,
          list: [],
          success: false,
          error: true,
          errorMessage: action.payload,
        },
      };
    }
    case GET_LINK_TO_VALIDATE_IDENTITY: {
      return {
        ...state,
        identityValidation: {
          ...initialState.identityValidation,
        },
      };
    }
    case GET_LINK_TO_VALIDATE_IDENTITY_SUCCEEDED: {
      return {
        ...state,
        identityValidation: {
          data: {
            urlInfo: action.payload,
            innCodeInfo: {},
          },
          open: true,
          success: true,
          error: false,
          errorCode: '',
          errorMessage: '',
        },
      };
    }
    case GET_LINK_TO_VALIDATE_IDENTITY_FAILED: {
      return {
        ...state,
        identityValidation: {
          data: {
            urlInfo: {},
            innCodeInfo: {},
          },
          open: true,
          success: false,
          error: true,
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
        },
      };
    }
    case CLOSE_RESPONSE_GET_LINK_TO_VALIDATE_IDENTITY:
      return {
        ...state,
        identityValidation: {
          ...state.identityValidation,
          open: false,
        },
      };
    case CLOSE_RESPONSE_VALIDATE_IDENTITY:
      return {
        ...state,
        identityValidation: {
          ...state.identityValidation,
          openValidationResponse: false,
        },
      };
    case VALIDATE_IDENTITY:
      return {
        ...state,
        identityValidation: {
          ...state.identityValidation,
          data: {
            ...state.identityValidation.data,
            innCodeInfo: { flowCompleted: false },
          },
          open: false,
        },
      };
    case VALIDATE_IDENTITY_SUCCEEDED:
      return {
        ...state,
        identityValidation: {
          ...state.identityValidation,
          data: {
            ...state.identityValidation.data,
            innCodeInfo: action.payload,
          },
          openValidationResponse: true,
          successValidationResponse: true,
          errorValidationResponse: false,
          errorCode: '',
          errorMessage: '',
        },
      };
    case VALIDATE_IDENTITY_FAILED:
      return {
        ...state,
        identityValidation: {
          ...state.identityValidation,
          data: {
            ...state.identityValidation.data,
            innCodeInfo: { flowCompleted: true },
          },
          openValidationResponse: true,
          successValidationResponse: false,
          errorValidationResponse: true,
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
        },
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}
