import moment from 'moment';
import { YYYY_MM_DD, timeZone } from '@casanova/casanova-common/lib/utils/date';

export const formatter = (values) => {
  const formatterValues = {
    incident: values.incident,
    // periodicity: values.periodicity,
    finalKm: values.finalKm,
    changeDate: moment(values.changeDate, YYYY_MM_DD)
      .tz(timeZone)
      .format(YYYY_MM_DD),
    reason: values.reason,
    vehicleA: values.vehicleA.vehicleUuid || values.currentVehicle,
    vehicleB: values.vehicleB.uuid,
    uuid: values.uuid,
  };
  return formatterValues;
};
