import formatMultiPayments from '@payments/sectionsMethodsPayment/MultiPayments/formatter';
import formatInvoice from '@payments/sectionsMethodsPayment/InvoicePayment/formatter';
import formatHoldback from '@payments/sectionsMethodsPayment/Holdback/formatter';

const formatCommons = (values) => ({
  reservation: values.reservationId,
  creditCardHold: formatHoldback(values),
  paymentType: 'CONTRACT',
});

const formatter = (values) => {
  const request = formatMultiPayments(values);
  if (request.length == 1) request[0].amount = undefined;

  return {
    ...(values.wantInvoice ? formatInvoice(values) : {}),
    multiRequest: request,
    commons: formatCommons(values),
  };
};

export default formatter;
